import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox } from '@material-ui/core';
import { updateHardware } from '../../services/fetchServices/HardwareApi/updateHardware';
import { CheckBoxHandlerProps } from './types';

export const checkboxStyle = {
  fontSize: '1.5rem',
  color: 'rgb(255, 210, 66)',
};

const CheckBoxHandler = ({ hardware, disabled }: CheckBoxHandlerProps) => {
  const [checked, setChecked] = useState(hardware.atHome);

  const updateChecked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    const changedValues = {
      ...hardware,
      type: hardware.type.id,
      category: hardware.category.id,
      supplier: hardware.supplier.id,
      brand: hardware.brand.id,
      organization: hardware.organization.id,
      status: hardware.status,
      model: hardware.model,
      serialNumber: hardware.serialNumber,
      jitTag: hardware.jitTag,
      purchaseDate: hardware.purchaseDate,
      atHome: e.target.checked,
    };

    const res = await updateHardware(hardware.id.toString(), changedValues);
    if (res.type === 'success') {
      toast.success('Successfully updated hardware');
    } else {
      toast.error(`Error: ${res.error.message}`);
    }
  };

  return (
    <Checkbox
      checked={checked}
      style={checkboxStyle}
      onChange={updateChecked}
      disabled={disabled}
    />
  );
};

export default CheckBoxHandler;
