import React from 'react';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ToastProvider from '../components/ToastProvider';
import FiltersAndSortDataProvider from './FiltersAndSortDataProvider';

const theme = createTheme({
  palette: {
    primary: { main: '#ffd242' },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['ModeratJIT', 'sans-serif'].join(','),
  },
});

type Props = { children: React.ReactNode };
const AppProviders = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <ToastProvider />
    <FiltersAndSortDataProvider>{children}</FiltersAndSortDataProvider>
  </ThemeProvider>
);

export default AppProviders;
