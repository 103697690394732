import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  createDataResponse,
  createErrorResponse,
} from '../../../services/fetchServices/fetchWrapper';
import {
  getOfficeHardware,
  HardwareArrayType,
} from '../../../services/fetchServices/HardwareApi/getOfficeHardware';
import { assignHardwareToOffice } from '../../../services/fetchServices/OfficesApi/assignHardware';
import { unassignHardwareFromOffice } from '../../../services/fetchServices/OfficesApi/unassignHardware';

export const useOfficeHardwareData = (id: string) => {
  const [data, setData] = useState<HardwareArrayType>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refetchAssignedHardware = useCallback(async () => {
    setIsLoading(true);
    const officeHardware = await getOfficeHardware(id);
    if (officeHardware.type === 'success') {
      setData(officeHardware.data);
    } else {
      toast.error(officeHardware.error.message);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    refetchAssignedHardware();
  }, [refetchAssignedHardware]);

  async function submitUnassignHardware(hardwareIds: number[]) {
    const res = await unassignHardwareFromOffice(id, hardwareIds);
    if (res.type === 'success') {
      await refetchAssignedHardware();
      toast.success('Successfully unassigned hardware');
      return createDataResponse({});
    }
    toast.error(`Failed to unassign office: ${res.error.message}`);
    return createErrorResponse("Couldn't unassign hardware");
  }

  async function submitAssignHardware(hardwareIds: number[]) {
    const res = await assignHardwareToOffice(id, hardwareIds);
    if (res.type === 'success') {
      await refetchAssignedHardware();
      toast.success('Successfully assigned hardware');
      return createDataResponse({});
    }
    toast.error(`Failed to assign hardware: ${res.error.message}`);
    return createErrorResponse("Couldn't assign hardware");
  }

  return {
    data,
    isLoading,
    setIsLoading,
    submitAssignHardware,
    submitUnassignHardware,
    refetchAssignedHardware,
  };
};
