import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import UserDataProvider from './context/UserDataProvider';
import App from './App';
import { msalInstance } from './authConfig';
import AppProviders from './context';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <UserDataProvider>
      <AppProviders>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProviders>
    </UserDataProvider>
  </MsalProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
