import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { ActiveSort } from '../components/LazyList/LazyListHelpers';

export type ProviderProps = { children: ReactNode };
export type FiltersState = {
  activeFilters: any;
  activeSort: ActiveSort;
};
export type ListKey =
  | 'hardwareMainList'
  | 'Licenses'
  | 'Notification'
  | 'User'
  | 'Inventory'
  | 'Offices'
  | 'hardwareDialogList'
  | 'NewNotification'
  | 'Accessories'
  | 'NewJiters'
  | 'Protocols';

export type FiltersDict = Partial<Record<ListKey, FiltersState>>;

const FiltersAndSortStateContext = createContext<FiltersDict | undefined>(undefined);
const FiltersAndSortDispatchContext = createContext<
  ((key: ListKey, filtersState: FiltersState) => void) | undefined
>(undefined);

const FiltersAndSortDataProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState<FiltersDict>({});
  const saveFiltersState = useCallback((key: ListKey, filtersState: FiltersState) => {
    setState((prevState) => ({ ...prevState, [key]: filtersState }));
  }, []);

  return (
    <FiltersAndSortStateContext.Provider value={state}>
      <FiltersAndSortDispatchContext.Provider value={saveFiltersState}>
        {children}
      </FiltersAndSortDispatchContext.Provider>
    </FiltersAndSortStateContext.Provider>
  );
};

function useFiltersAndSortState(listKey: ListKey) {
  const context = useContext(FiltersAndSortStateContext);
  if (context === undefined) {
    throw new Error(
      'useFiltersAndSortState must be used within a FiltersAndSortDataProvider',
    );
  }
  return context[listKey];
}

function useFiltersAndSortDispatch() {
  const context = useContext(FiltersAndSortDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useFiltersAndSortDispatch must be used within a FiltersAndSorDataProvider',
    );
  }
  return context;
}

export default FiltersAndSortDataProvider;
export { useFiltersAndSortState, useFiltersAndSortDispatch };
