import React, { useCallback } from 'react';
import { DetailsLink } from '../../components/LazyList/DetailsLink';
import LazyList from '../../components/LazyList/LazyList';
import { defaultOnSearchChange } from '../../components/LazyList/LazyListHelpers';
import {
  ColumnName,
  composeColumnsDefinitionForNotifications,
  mapNotifications,
  NUMBER_OF_NOTIFICATIONS_FETCHED,
} from './notificationListHelpers';
import NotificationsListHeader from './NotificationsListHeader';
import useNotificationsListData, {
  NotificationsListState,
} from './useNotificationsListData';

const NotificationsList = () => {
  const {
    state: {
      loadedNotifications,
      totalNotifications,
      filteredNotifications,
      activeFilters,
      availableFilters,
      displayedColumns,
      activeSort,
      searchText,
      isFetching,
    },
    setState,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onColumnsChangeCallback,
  } = useNotificationsListData();
  const columns = composeColumnsDefinitionForNotifications(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const onInfiniteScroll = useCallback(() => {
    setState((prevState) => {
      const newState: NotificationsListState = {
        ...prevState,
        numberOfRequestedNotifications:
          prevState.numberOfRequestedNotifications + NUMBER_OF_NOTIFICATIONS_FETCHED,
      };
      return newState;
    });
  }, [setState]);

  const allActionComponents = (rowData: any[]) => [
    <DetailsLink link={`/notification/${rowData[ColumnName.id]}`} />,
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    responsive: 'standard',
    download: false,
    serverSide: true,
    serverSideFilterList: Object.values(activeFilters),
    pagination: false,
    rowsPerPage: 5000,
    rowHover: false,
    print: false,
    selectableRows: 'none',
    searchText,
    searchOpen: true,
  };

  const data = loadedNotifications.map(mapNotifications);
  return (
    <div>
      <NotificationsListHeader />
      <LazyList
        data={data}
        columns={columns}
        options={options}
        onFilterChange={contextEnchancedOnFiltersChange}
        onSortChange={contextEnchancedOnSortChange}
        onSearchChange={defaultOnSearchChange(setState)}
        onColumnsChange={onColumnsChangeCallback}
        totalItems={totalNotifications}
        filteredItems={filteredNotifications}
        onInfiniteScrollCallback={onInfiniteScroll}
        isFetching={isFetching}
        actionComponents={allActionComponents}
      />
    </div>
  );
};

export default NotificationsList;
