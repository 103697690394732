import React from 'react';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  Container,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import SaveDetailsIcons from '../../components/SaveDetailsIcons';
import {
  LicensesKind,
  licensesKindLabels,
  LicensesType,
  licensesTypeLabels,
} from '../../services/domainServices/LicensesService';
import { UpdateLicenseFormValues } from '../../services/fetchServices/LicensesApi/updateLicense';
import LicenseHistoryTable from './LicenseHistory/LicenseHistoryTable';
import LicenseDetailsHeader from './LicenseDetailsHeader';
import { useLicenseData } from './useLicenseData';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: theme.spacing(10),
      width: '90%',
    },
    historyContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: theme.spacing(2),
      width: '90%',
    },
    paper: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minWidth: 1000,
      width: '100%',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
    },
    narrowField: {
      width: '28rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    mediumField: {
      width: '28rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    longField: {
      width: '57rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    divider: {
      marginTop: '2%',
      marginBottom: '1%',
    },
    typography: {
      textAlign: 'center',
      marginTop: '2%',
    },
  }),
);

const LicenseDetails = () => {
  const { id = '' } = useParams();
  const classes = useStyles();
  const { state, setIsEdit, submitUpdateLicense, historyData } = useLicenseData(id);

  return (
    <div>
      <div>
        <LicenseDetailsHeader />
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Formik
              enableReinitialize
              initialValues={state.formData}
              validate={validateLicenseData}
              onSubmit={submitUpdateLicense}
              render={(formikProps) => {
                const { submitForm, values, isSubmitting, setFieldValue, resetForm } =
                  formikProps;
                return (
                  <Form>
                    <SaveDetailsIcons
                      isEditMode={state.isEditMode}
                      isSubmitting={isSubmitting}
                      submitForm={submitForm}
                      setIsEdit={setIsEdit}
                      resetForm={resetForm}
                    />
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="name"
                          label="name"
                          component={TextField}
                          id="input-name"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="licenseType"
                          label="type of license"
                          select
                          component={TextField}
                          id="input-licenseType"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.licenseType),
                          }}
                          disabled={!state.isEditMode}
                        >
                          {Object.keys(licensesTypeLabels).map((type) => (
                            <MenuItem value={type} key={type}>
                              {licensesTypeLabels[type as LicensesType]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="licenseKind"
                          label="kind of license"
                          select
                          component={TextField}
                          id="input-licenseType"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.licenseKind),
                          }}
                          disabled={!state.isEditMode}
                        >
                          {Object.keys(licensesKindLabels).map((kind) => (
                            <MenuItem value={kind} key={kind}>
                              {licensesKindLabels[kind as LicensesKind]}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="capacity"
                          label="number"
                          type="number"
                          component={TextField}
                          id="input-number"
                          className={classes.mediumField}
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="userLicenses"
                          label="assigned to"
                          component={TextField}
                          id="input-userLicenses"
                          className={classes.mediumField}
                          margin="normal"
                          multiple
                          disabled
                        />
                        <Field
                          name="organization"
                          label="organization"
                          select
                          component={TextField}
                          id="input-organization"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.organization),
                          }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.organizations.map((org) => (
                            <MenuItem value={org.id} key={org.id}>
                              {org.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="firstKey"
                          label="first key"
                          className={classes.mediumField}
                          component={TextField}
                          id="input-firstKey"
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="secondKey"
                          label="second key"
                          className={classes.mediumField}
                          component={TextField}
                          id="input-secondKey"
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="login"
                          label="login"
                          className={classes.mediumField}
                          component={TextField}
                          id="input-login"
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="password"
                          label="password"
                          className={classes.mediumField}
                          component={TextField}
                          id="input-password"
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="purchasePrice"
                          label="purchase price"
                          component={TextField}
                          id="input-purchasePrice"
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.purchasePrice),
                          }}
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="purchaseDocument"
                          label="purchase document"
                          component={TextField}
                          id="input-purchasePrice"
                          className={classes.mediumField}
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="expirationDate"
                            label="expiration date"
                            className={classes.longField}
                            value={values.expirationDate}
                            onChange={(value) => {
                              setFieldValue('expirationDate', value);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            disabled={!state.isEditMode}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Field
                        name="comment"
                        label="comment"
                        component={TextField}
                        id="input-comment"
                        margin="normal"
                        disabled={!state.isEditMode}
                        className={classes.longField}
                        multiline
                      />
                    </Grid>
                  </Form>
                );
              }}
            />
          </Paper>
        </Container>
      </div>
      <Divider className={classes.divider} />
      <Typography paragraph className={classes.typography}>
        HISTORY
      </Typography>
      <Container className={classes.historyContainer}>
        <LicenseHistoryTable historyData={historyData} />
      </Container>
    </div>
  );
};

export default LicenseDetails;

export function validateLicenseData(values: UpdateLicenseFormValues) {
  const errors: Partial<Record<keyof UpdateLicenseFormValues, string>> = {};
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.licenseType) {
    errors.licenseType = 'required';
  }
  if (!values.licenseKind) {
    errors.licenseKind = 'required';
  }
  if (!values.organization) {
    errors.organization = 'required';
  }

  return errors;
}
