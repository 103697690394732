import React from 'react';
import PageHeader from '../../components/PageHeader';

const AddHardwareHeader: React.FC = () => (
  <PageHeader
    title="Add New Hardware"
    linkText="Return to hardware list"
    linkAddress="/hardware"
  />
);

export default AddHardwareHeader;
