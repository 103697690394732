import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AssignHardwareDialogContent } from './AssignHardwareDialogContent';
import UserHardwareDialog from './HardwareDialog';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
  }),
);

type AssignHardwareProps = {
  assigning: boolean;
  assignHardware: (
    ids: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => Promise<void>;
};

export const AssignHardware = ({ assigning, assignHardware }: AssignHardwareProps) => {
  const classes = useToolbarStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={assigning}
        size="small"
      >
        Assign Hardware
      </Button>
      <UserHardwareDialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <AssignHardwareDialogContent assignHardware={assignHardware} setOpen={setOpen} />
      </UserHardwareDialog>
    </>
  );
};
