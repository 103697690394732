import { fetchWrapper } from '../fetchWrapper';

export async function assignLicenseToUser(ldapLogin: string, licenseId: number) {
  return fetchWrapper({
    url: `api/user/${ldapLogin}/license/${licenseId}`,
    fetchArg: {
      method: 'POST',
      headers: {
        accept: 'text/plain',
        'Content-Type': 'application/json-patch+json',
      },
      body: licenseId,
    },
  });
}
