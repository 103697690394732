import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PageHeader, { useStyles } from '../../components/PageHeader';

const LicensesListHeader = () => {
  const classes = useStyles();

  return (
    <PageHeader
      title="Licenses"
      actionButtons={
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          to="/add-license"
        >
          Add New License
        </Button>
      }
    />
  );
};

export default LicensesListHeader;
