/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import SaveIcon from '@material-ui/icons/Save';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import {
  getCountries,
  getCountryByName,
  reformatCountryPhoneMask,
  updateCompanyPhoneFieldBasedOnCountry,
  updateField,
} from '../../components/AddUserForm/utils';
import DialogWithClose from '../../components/DialogWithClose';
import { useDialogTimeOut } from '../../hooks/useDialogTimeOut';
import { UpdateUserDto } from '../../services/domainServices/UserService';
import { calendarTheme } from '../../themes/CalendarTheme';
import UserDetailsHeader from './UserDetailsHeader';
import UserDetailsTabs from './UserDetailsTabs';
import { useUserData } from './useUserData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '90%',
    },
    textField2: {
      width: '64%',
    },
    textField3: {
      width: '97%',
    },
    textField4: {
      width: '95%',
    },
    switch: {
      marginTop: '5%',
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: '80%',
    },
    paper: {
      width: '100%',
      paddingBottom: '4%',
      textAlign: 'center',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    icon: {
      margin: theme.spacing(1),
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'grey',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
    grid: {
      marginTop: '1.2em',
      marginBottom: '1.2em',
      height: 'auto',
      minWidth: '90%',
    },
    returnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
    pad: {
      width: 270,
    },
    divider: {
      marginTop: '1%',
      marginBottom: '1%',
    },
    dataContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    button: {
      margin: theme.spacing(1),
      fontSize: '16px',
      textTransform: 'none',
    },
    disabled: {
      color: '#b1b1b1',
    },
    privatePhone: {
      fontSize: 'inherit',
      WebkitTextStroke: 'thin',
      marginTop: '6%',
      marginRight: '5%',
      borderRadius: '30px',
    },
    countryField: {
      width: '90%',
      textAlign: 'left',
    },
  }),
);

export function validateAddUserData(values: UpdateUserDto) {
  const errors: Partial<UpdateUserDto> = {};
  if (!values.firstName) {
    errors.firstName = 'required';
  }
  if (!values.lastName) {
    errors.lastName = 'required';
  }
  return errors;
}

const UserDetails = () => {
  const { ldapLogin = '' } = useParams();
  const classes = useStyles();
  const closeDialogTimeout = useDialogTimeOut();
  const countries = getCountries();

  const {
    data: initialValues,
    readOnly,
    setReadOnly,
    submitEditUser,
    historyData,
  } = useUserData(ldapLogin);

  return (
    <>
      <UserDetailsHeader />
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={validateAddUserData}
              onSubmit={submitEditUser}
              render={({ submitForm, values, setFieldValue, resetForm }) => (
                <Form>
                  {readOnly ? (
                    <div className={classes.iconContainer}>
                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          setReadOnly(!readOnly);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div className={classes.iconContainer}>
                      <IconButton className={classes.icon} onClick={submitForm}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          resetForm();
                          setReadOnly(!readOnly);
                        }}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    </div>
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        value={values.firstName}
                        inputProps={{
                          name: 'firstName',
                          onChange: updateField(setFieldValue),
                        }}
                        label="first name"
                        component={TextField}
                        className={classes.textField}
                        disabled={readOnly}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        value={values.lastName}
                        inputProps={{
                          name: 'lastName',
                          onChange: updateField(setFieldValue),
                        }}
                        label="last name"
                        component={TextField}
                        className={classes.textField}
                        disabled={readOnly}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        name="ldapLogin"
                        value={values.ldapLogin}
                        label="ldap login"
                        component={TextField}
                        className={classes.textField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        value={values.privateEmail}
                        inputProps={{
                          name: 'privateEmail',
                          onChange: updateField(setFieldValue),
                        }}
                        label="private email"
                        component={TextField}
                        className={classes.textField}
                        disabled={readOnly}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        name="country"
                        label="country"
                        value={values.country}
                        select
                        component={TextField}
                        className={classes.countryField}
                        disabled={readOnly}
                        inputProps={{
                          onChange: updateCompanyPhoneFieldBasedOnCountry(
                            setFieldValue,
                            'country',
                          ),
                        }}
                      >
                        {countries.map((country) => (
                          <MenuItem value={country.name} key={country.name}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        value={values.email}
                        inputProps={{
                          name: 'email',
                          onChange: updateField(setFieldValue),
                        }}
                        label="company email"
                        component={TextField}
                        className={classes.textField}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        name="phone"
                        value={values.phone}
                        render={({ field }: FieldProps<any>) => {
                          const country = getCountryByName(values.country);
                          const maskFormat = reformatCountryPhoneMask(country.format);
                          return (
                            <InputMask {...field} mask={maskFormat} disabled={readOnly}>
                              {() => (
                                <TextField
                                  {...field}
                                  disabled={readOnly}
                                  label="company phone"
                                  className={classes.textField}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            </InputMask>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        value={values.oid}
                        inputProps={{
                          name: 'oid',
                          onChange: updateField(setFieldValue),
                        }}
                        label="oid number"
                        component={TextField}
                        className={classes.textField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Tooltip title="Show private phone number">
                        <IconButton
                          className={classes.privatePhone}
                          onClick={() =>
                            closeDialogTimeout.setPrivatePhoneDialogOpen(true)
                          }
                        >
                          <PhoneAndroidIcon />
                          private phone
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <DialogWithClose
                      onClose={() => closeDialogTimeout.setPrivatePhoneDialogOpen(false)}
                      open={closeDialogTimeout.privatePhoneDialogOpen}
                      titleText="Private phone number"
                      maxWidth="xs"
                      fullWidth
                    >
                      {values.privatePhone !== '' ? (
                        <Field
                          name="privatePhone"
                          value={values.privatePhone}
                          disabled
                          render={({ field }: FieldProps<any>) => {
                            const country = getCountryByName(values.country);
                            const maskFormat = reformatCountryPhoneMask(country.format);
                            return (
                              <InputMask {...field} mask={maskFormat} disabled>
                                {() => (
                                  <TextField
                                    disabled
                                    {...field}
                                    label="private phone"
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: Boolean(values.privatePhone),
                                    }}
                                  />
                                )}
                              </InputMask>
                            );
                          }}
                        />
                      ) : (
                        'There is no private phone number assigned to this user'
                      )}
                    </DialogWithClose>
                    <Grid item xs={4} sm={4}>
                      <Field
                        value={values.totalAssignedHardwareValue}
                        inputProps={{
                          name: 'totalAssignedHardwareValue',
                          onChange: updateField(setFieldValue),
                        }}
                        label="assigned hardware value"
                        component={TextField}
                        className={classes.textField}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">PLN</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Field
                        value={values.totalCurrentHardwareValue}
                        inputProps={{
                          name: 'totalCurrentHardwareValue',
                          onChange: updateField(setFieldValue),
                        }}
                        label="current hardware value"
                        component={TextField}
                        className={classes.textField}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">PLN</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <FormControlLabel
                        control={
                          <Field
                            name="active"
                            checked={values.active}
                            component={Switch}
                            color="primary"
                            marginTop="20"
                            disabled={readOnly}
                          />
                        }
                        label="active user"
                        className={classes.switch}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <FormControlLabel
                        control={
                          <Field
                            name="hasHardwareWithLinux"
                            checked={values.hasHardwareWithLinux}
                            component={Switch}
                            color="primary"
                            marginTop="20"
                            disabled
                          />
                        }
                        label="has hardware with linux"
                        className={classes.switch}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <FormControlLabel
                        control={
                          <Field
                            name="hasAdmin"
                            checked={values.hasAdmin}
                            component={Switch}
                            color="primary"
                            marginTop="20"
                            disabled={readOnly}
                          />
                        }
                        label="has admin"
                        className={classes.switch}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={calendarTheme}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="hasAdminChangeDate"
                            label="has admin change date"
                            value={values.hasAdminChangeDate || null}
                            minDate={initialValues.hasAdminChangeDate}
                            minDateMessage="The date can not be a date before the admin right was granted"
                            onChange={(value) => {
                              setFieldValue('hasAdminChangeDate', value);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            disabled={readOnly}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        value={values.comment}
                        inputProps={{
                          name: 'comment',
                          onChange: updateField(setFieldValue),
                        }}
                        label="comment"
                        component={TextField}
                        className={classes.textField3}
                        disabled={readOnly}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Paper>
        </div>
      </Container>
      <Divider className={classes.divider} />
      <Container>
        <UserDetailsTabs
          ldapLogin={ldapLogin}
          id={initialValues.id}
          historyData={historyData}
        />
      </Container>
    </>
  );
};

export default UserDetails;
