import { toast } from 'react-toastify';
import { deleteType } from './deleteType';

export const useDeleteTypeData = () => {
  async function submitDeleteType(id: number) {
    const res = await deleteType(id);
    if (res.type === 'success') {
      toast.success('Successfully deleted type');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitDeleteType };
};
