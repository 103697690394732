import { attributeFormValue } from '../../../components/AttributesInput/AttributesInput';
import {
  createHardwareResponseV,
  Hardware,
  HardwareStatus,
} from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

export type AddHardwareFormValues = {
  type?: number;
  category?: number;
  attributes: attributeFormValue[];
  attributeName: number | '';
  attributeValue: string;
  supplier?: number;
  brand?: number;
  organization?: number;
  assigneeType: 'user' | 'office';
  assignee?: number;
  parentHardware: string;
  parentHardwareId: string;
  status: HardwareStatus;
  model: string;
  serialNumber: string;
  jitTag: string;
  warrantyPeriod: Date | null;
  inventoryDate: Date | null;
  purchasePrice?: string | null;
  vatRate?: string | null;
  usableDepreciation?: string | null;
  salesDate: Date | null;
  purchaseDate: Date | null;
  expansibility: boolean;
  comment: string;
  parentDialogOpen: boolean;
};

export const mapHardwareToFormValues: (hardware: Hardware) => AddHardwareFormValues = (
  hardware,
) => ({
  type: hardware.type.id,
  category: hardware.category.id,
  attributeName: '',
  attributeValue: '',
  attributes: hardware.attributes,
  supplier: hardware.supplier.id,
  brand: hardware.brand.id,
  organization: hardware.organization.id,
  assigneeType: 'user',
  assignee: hardware.user ? hardware.user.id : 0,
  parentHardware: '',
  parentHardwareId:
    hardware.parentHardwareId !== 0 ? hardware.parentHardwareId.toString() : '',
  status: hardware.status,
  model: hardware.model,
  serialNumber: '',
  jitTag: 'JIT-',
  warrantyPeriod: hardware.warrantyPeriod,
  inventoryDate: hardware.inventoryDate,
  purchasePrice: hardware.purchasePrice ? hardware.purchasePrice.toString() : null,
  vatRate: hardware.vatRate ? hardware.vatRate.toString() : null,
  usableDepreciation: hardware.usableDepreciation
    ? hardware.usableDepreciation.toString()
    : null,
  salesDate: hardware.salesDate,
  purchaseDate: hardware.purchaseDate,
  expansibility: hardware.expansibility,
  comment: hardware.comment,
  parentDialogOpen: false,
});

export interface AddHardwareDto {
  typeId: number;
  categoryId: number;
  attributes: attributeFormValue[];
  supplierId: number;
  brandId: number;
  organizationId: number;
  userId?: number;
  officeId?: number;
  parentHardwareId?: number;
  status?: HardwareStatus;
  model: string;
  serialNumber: string;
  jitTag?: string;
  warrantyPeriod?: string;
  inventoryDate?: string;
  purchasePrice?: number;
  vatRate?: number;
  usableDepreciation?: string;
  salesDate?: string;
  purchaseDate?: string;
  expansibility?: boolean;
  comment?: string;
}

export const mapFormValuesToDto: (values: AddHardwareFormValues) => AddHardwareDto = (
  values,
) => ({
  typeId: values.type || 0,
  categoryId: values.category || 0,
  attributes: values.attributes,
  supplierId: values.supplier || 0,
  brandId: values.brand || 0,
  organizationId: values.organization || 0,
  officeId: undefined,
  parentHardwareId: Number(values.parentHardwareId) || undefined,
  status: values.status,
  model: values.model,
  serialNumber: values.serialNumber,
  jitTag: values.jitTag,
  warrantyPeriod: values.warrantyPeriod ? values.warrantyPeriod.toISOString() : undefined,
  inventoryDate: values.inventoryDate ? values.inventoryDate.toISOString() : undefined,
  purchasePrice: values.purchasePrice ? Number(values.purchasePrice) : undefined,
  vatRate: values.vatRate ? Number(values.vatRate) : undefined,
  usableDepreciation: values.usableDepreciation ? values.usableDepreciation : undefined,
  salesDate: values.salesDate ? values.salesDate.toISOString() : undefined,
  purchaseDate: values.purchaseDate ? values.purchaseDate.toISOString() : undefined,
  expansibility: values.expansibility,
  comment: values.comment,
});

export function addHardware(hardware: AddHardwareFormValues) {
  const hardwareDto = mapFormValuesToDto(hardware);
  return fetchWrapper({
    url: '/api/hardware',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: hardwareDto,
    },
    dataType: createHardwareResponseV,
  });
}
