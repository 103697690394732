import { createTheme, Theme } from '@material-ui/core';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export const calendarTheme: Theme = createTheme({
  palette: {
    primary: { main: '#ffd242' },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['ModeratJIT', 'sans-serif'].join(','),
  },
  overrides: {
    MuiPickersCalendarHeader: {
      transitionContainer: {
        overflow: 'visible',
        marginBottom: '5px',
      },
    },
  },
});
