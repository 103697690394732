import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useStyles } from './LazyListHelpers';

export const DetailsLink = ({ link }: { link: string }) => {
  const lazyListClasses = useStyles();

  return (
    <Tooltip title="show details">
      <Link to={link}>
        <IconButton className={lazyListClasses.showDetailsIconButton}>
          <MoreHorizIcon fontSize="inherit" className={lazyListClasses.showDetailsIcon} />
        </IconButton>
      </Link>
    </Tooltip>
  );
};
