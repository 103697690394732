import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export type UpdateLocation = {
  name: string;
};

export const emptyLocation: UpdateLocation = {
  name: '',
};

export function updateLocation(id: number, location: UpdateLocation) {
  return fetchWrapper({
    url: `/api/location/${id}`,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: location,
    },
  });
}
