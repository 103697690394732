/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../components/LazyList/LazyListHelpers';
import { ColumnName, EmptyFilters } from '../HardwareList/HardwareListHelpers';
import HardwareListTable from '../HardwareList/HardwareListTable';

type AssignHardwareProps = {
  assignParent: (parentHardware: ParentHardware) => void;
  currentId?: number;
};

export type ParentHardware = {
  id: number;
  jitTag: string;
  category: string;
  brand: string;
  model: string;
};

const parentValues: (rowData: string[]) => ParentHardware = (rowData) => ({
  id: Number(rowData[ColumnName.id]),
  jitTag: rowData[ColumnName.JitTag],
  category: rowData[ColumnName.Category],
  brand: rowData[ColumnName.Brand],
  model: rowData[ColumnName.Model],
});

export const AssignParentDialogContent = ({
  assignParent,
  currentId,
}: AssignHardwareProps) => {
  const lazyListClasses = useStyles();

  return (
    <HardwareListTable
      parents
      currentHardwareId={currentId}
      initialFilters={EmptyFilters}
      actionComponents={(rowData: string[]) => [
        <Tooltip title="assign parent">
          <IconButton
            onClick={() => {
              assignParent(parentValues(rowData));
            }}
            className={lazyListClasses.showDetailsIconButton}
          >
            <AddIcon fontSize="inherit" className={lazyListClasses.showDetailsIcon} />
          </IconButton>
        </Tooltip>,
      ]}
    />
  );
};
