import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  Container,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import AttributesInput from '../../components/AttributesInput/AttributesInput';
import DialogWithClose from '../../components/DialogWithClose';
import HardwareDocuments from '../../components/HardwareDocuments/HardwareDocuments';
import SaveDetailsIcons from '../../components/SaveDetailsIcons';
import {
  HardwareStatus,
  hardwareStatusLabels,
} from '../../services/domainServices/HardwareService';
import { UpdateHardwareFormValues } from '../../services/fetchServices/HardwareApi/updateHardware';
import { calendarTheme } from '../../themes/CalendarTheme';
import { isPositiveInteger, isPriceNumber } from '../AddHardware/AddHardwareHelpers';
import { AssignParentDialogContent, ParentHardware } from './AssignParentDialogContent';
import HardwareDetailsHeader from './HardwareDetailsHeader';
import HardwareDetailsTabs from './HardwareDetailsTabs';
import { useHardwareData } from './useHardwareData';
import { useHardwareDataHeader } from './useHardwareDataHeader';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: theme.spacing(10),
    },
    paper: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minWidth: 1000,
      width: '100%',
      justifyContent: 'center',
    },
    attributesInputs: {
      borderRadius: '4px',
      paddingTop: '1%',
      paddingRight: '1%',
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
    },
    narrowField: {
      width: '19rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    mediumField: {
      width: '19rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    purchaseTitleBox: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '450px',
      maxWidth: '450px',
      border: '1px solid rgb(186, 186, 186)',
      borderRadius: '4px',
      marginTop: '4%',
      marginBottom: '1%',
    },
    attributeTitle: {
      fontSize: '1.2em',
    },
    attributeField: {
      marginTop: '16px',
      marginBottom: '8px',
    },
    switch: {
      width: '19rem',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    datesContainer: {
      width: '19rem',
      borderRadius: '4px',
      paddingRight: '1%',
      paddingBottom: '1%',
    },
    flex: {
      display: 'flex',
    },
    divider: {
      marginTop: '1%',
      marginBottom: '1%',
    },
    hiddenField: {
      visibility: 'hidden',
    },
  }),
);

const HardwareDetails = () => {
  const { id = '' } = useParams();
  const classes = useStyles();
  const { state, setIsEdit, submitUpdateHardware, historyData } = useHardwareData(id);
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);

  const handleSwitchOnChange = (
    e: any,
    fieldName: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    if (e.target.checked) {
      setFieldValue('parentHardware', '');
      setFieldValue('parentHardwareId', '');
    }
    setFieldValue(fieldName, e.target.checked);
  };

  const { generateReport } = useHardwareDataHeader(id);
  return (
    <>
      <div>
        <HardwareDetailsHeader
          id={id}
          generateReport={generateReport}
          setDocumentsDialogOpen={() => setDocumentsDialogOpen(true)}
        />
        <DialogWithClose
          onClose={() => setDocumentsDialogOpen(false)}
          open={documentsDialogOpen}
          titleText="Manage documents"
          maxWidth="md"
        >
          <HardwareDocuments id={id} />
        </DialogWithClose>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Formik
              enableReinitialize
              initialValues={state.formData}
              validate={validateHardwareData}
              onSubmit={submitUpdateHardware}
              render={(formikProps) => {
                const { submitForm, values, isSubmitting, setFieldValue, resetForm } =
                  formikProps;
                return (
                  <Form>
                    <SaveDetailsIcons
                      isEditMode={state.isEditMode}
                      isSubmitting={isSubmitting}
                      submitForm={submitForm}
                      setIsEdit={setIsEdit}
                      resetForm={resetForm}
                    />
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item container alignItems="center" justifyContent="center">
                        <Field
                          name="type"
                          label="type"
                          select
                          component={TextField}
                          id="input-type"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{ shrink: Boolean(values.type) }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.types.map((type) => (
                            <MenuItem value={type.id} key={type.id}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="category"
                          label="category"
                          select
                          component={TextField}
                          id="input-category"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{ shrink: Boolean(values.category) }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.categories.map((category) => (
                            <MenuItem value={category.id} key={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="organization"
                          label="organization"
                          select
                          component={TextField}
                          id="input-organization"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.organization),
                          }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.organizations.map((org) => (
                            <MenuItem value={org.id} key={org.id}>
                              {org.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid container item alignItems="center" justifyContent="center">
                        <Field
                          name="supplier"
                          label="supplier"
                          select
                          component={TextField}
                          id="input-supplierId"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{ shrink: Boolean(values.supplier) }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.suppliers.map((sup) => (
                            <MenuItem value={sup.id} key={sup.id}>
                              {sup.name}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="brand"
                          label="brand"
                          select
                          component={TextField}
                          id="input-brand"
                          required
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{ shrink: Boolean(values.brand) }}
                          disabled={!state.isEditMode}
                        >
                          {state.metaData.brands.map((brand) => (
                            <MenuItem value={brand.id} key={brand.id}>
                              {brand.name}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="model"
                          label="model"
                          component={TextField}
                          id="input-model"
                          className={classes.mediumField}
                          margin="normal"
                          required
                          disabled={!state.isEditMode}
                        />
                        <Grid container item alignItems="center" justifyContent="center">
                          <div className={classes.switch}>
                            <FormControlLabel
                              control={
                                <Field
                                  name="expansibility"
                                  component={Switch}
                                  color="primary"
                                  id="input-expansibility"
                                  margin="normal"
                                  disabled={!state.isEditMode}
                                  checked={values.expansibility}
                                  onChange={(e: any) => {
                                    handleSwitchOnChange(
                                      e,
                                      'expansibility',
                                      setFieldValue,
                                    );
                                  }}
                                />
                              }
                              label="expansibility"
                            />
                            <FormControlLabel
                              name="atHome"
                              color="primary"
                              id="input-atHome"
                              disabled={!state.isEditMode}
                              control={
                                <Field
                                  name="atHome"
                                  component={Switch}
                                  color="primary"
                                  id="input-atHome"
                                  margin="normal"
                                  checked={values.atHome}
                                  disabled={!state.isEditMode}
                                  onChange={(e: any) => {
                                    handleSwitchOnChange(e, 'atHome', setFieldValue);
                                  }}
                                />
                              }
                              label="at home"
                            />
                          </div>
                          <Field
                            name="parentHardware"
                            label="parent hardware"
                            component={TextField}
                            id="input-parentHardware"
                            className={classes.mediumField}
                            margin="normal"
                            disabled={!state.isEditMode || values.expansibility}
                            InputProps={{
                              endAdornment: (
                                <>
                                  <Tooltip title="assign parent">
                                    <IconButton
                                      size="small"
                                      disabled={!state.isEditMode || values.expansibility}
                                      onClick={() => {
                                        setFieldValue('parentDialogOpen', true);
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <DialogWithClose
                                    onClose={() =>
                                      setFieldValue('parentDialogOpen', false)
                                    }
                                    open={values.parentDialogOpen}
                                    titleText="Select hardware parent"
                                    fullWidth
                                  >
                                    <AssignParentDialogContent
                                      currentId={Number(id)}
                                      assignParent={(parentHardware: ParentHardware) => {
                                        setFieldValue(
                                          'parentHardware',
                                          `${parentHardware.jitTag}, ${parentHardware.brand}, ${parentHardware.model}`,
                                        );
                                        setFieldValue(
                                          'parentHardwareId',
                                          parentHardware.id,
                                        );
                                        setFieldValue('parentDialogOpen', false);
                                      }}
                                    />
                                  </DialogWithClose>
                                </>
                              ),
                            }}
                          />
                          <Field
                            name="status"
                            label="hardware status"
                            select
                            component={TextField}
                            className={classes.mediumField}
                            id="input-hardwareStatus"
                            margin="normal"
                            disabled={values.parentHardware ? true : !state.isEditMode}
                          >
                            {Object.keys(hardwareStatusLabels).map((status) => (
                              <MenuItem value={status} key={status}>
                                {hardwareStatusLabels[status as HardwareStatus]}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid container item alignItems="center" justifyContent="center">
                          <div className={classes.switch}>
                            <FormControlLabel
                              name="hardening"
                              color="primary"
                              id="input-hardening"
                              disabled={!state.isEditMode}
                              control={
                                <Field
                                  name="hardening"
                                  component={Switch}
                                  color="primary"
                                  id="input-hardening"
                                  margin="normal"
                                  checked={values.hardening}
                                  disabled={!state.isEditMode}
                                  onChange={(e: any) => {
                                    handleSwitchOnChange(e, 'hardening', setFieldValue);
                                  }}
                                />
                              }
                              label="hardening"
                            />
                          </div>
                          <Field
                            name="version"
                            label="version"
                            component={TextField}
                            id="input-version"
                            className={classes.mediumField}
                            margin="normal"
                            required={values.hardening}
                            disabled={!state.isEditMode || !values.hardening}
                          />
                          <div className={classes.switch}>
                            <FormControlLabel
                              name="hasInstalledLinux"
                              color="primary"
                              id="input-hasInstalledLinux"
                              disabled={!state.isEditMode}
                              control={
                                <Field
                                  name="hasInstalledLinux"
                                  component={Switch}
                                  color="primary"
                                  id="input-hasInstalledLinux"
                                  margin="normal"
                                  checked={values.hasInstalledLinux}
                                  disabled={!state.isEditMode}
                                  onChange={(e: any) => {
                                    handleSwitchOnChange(
                                      e,
                                      'hasInstalledLinux',
                                      setFieldValue,
                                    );
                                  }}
                                />
                              }
                              label="Linux installed"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        className={classes.attributesInputs}
                      >
                        <AttributesInput
                          formikProps={formikProps}
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid container item alignItems="center" justifyContent="center">
                        <Field
                          name="serialNumber"
                          label="serial number"
                          component={TextField}
                          id="input-serialNumber"
                          className={classes.mediumField}
                          margin="normal"
                          required
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="jitTag"
                          label="JIT tag"
                          component={TextField}
                          id="input-jitTag"
                          className={classes.mediumField}
                          margin="normal"
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="assignee"
                          label="assignee"
                          component={TextField}
                          id="input-user"
                          className={classes.mediumField}
                          margin="normal"
                          disabled
                        />
                      </Grid>

                      <Grid container item alignItems="center" justifyContent="center">
                        <Field
                          name="purchasePrice"
                          label="purchase price"
                          component={TextField}
                          id="input-purchasePrice"
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.purchasePrice),
                          }}
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="vatRate"
                          label="VAT rate"
                          component={TextField}
                          id="input-vatRate"
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{ shrink: Boolean(values.vatRate) }}
                          disabled={!state.isEditMode}
                        />
                        <Field
                          name="usableDepreciation"
                          label="usable depreciation"
                          component={TextField}
                          id="input-vatRate"
                          className={classes.mediumField}
                          margin="normal"
                          InputLabelProps={{
                            shrink: Boolean(values.usableDepreciation),
                          }}
                          disabled={!state.isEditMode}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        className={classes.attributesInputs}
                      >
                        <Grid
                          container
                          item
                          alignItems="center"
                          direction="column"
                          className={classes.datesContainer}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={calendarTheme}>
                              <KeyboardDatePicker
                                disableToolbar
                                className={classes.mediumField}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="purchaseDate"
                                label="purchase date"
                                value={values.purchaseDate}
                                onChange={(value) => {
                                  setFieldValue('purchaseDate', value);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                disabled={!state.isEditMode}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={calendarTheme}>
                              <KeyboardDatePicker
                                disableToolbar
                                className={classes.mediumField}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="salesDate"
                                label="sales date"
                                value={values.salesDate}
                                minDate={values.purchaseDate}
                                minDateMessage="the date of sale cannot be earlier than the date of purchase"
                                onChange={(value) => {
                                  setFieldValue('salesDate', value);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                disabled={!state.isEditMode}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          container
                          item
                          alignItems="center"
                          direction="column"
                          className={classes.datesContainer}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={calendarTheme}>
                              <KeyboardDatePicker
                                disableToolbar
                                className={classes.mediumField}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="warrantyPeriod"
                                label="warranty period"
                                value={values.warrantyPeriod}
                                onChange={(value) => {
                                  setFieldValue('warrantyPeriod', value);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                disabled={!state.isEditMode}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={calendarTheme}>
                              <KeyboardDatePicker
                                disableToolbar
                                className={classes.mediumField}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="inventoryDate"
                                label="inventory date"
                                value={values.inventoryDate}
                                onChange={(value) => {
                                  setFieldValue('inventoryDate', value);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                disabled={!state.isEditMode}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      <Field
                        name="comment"
                        label="comment"
                        component={TextField}
                        id="input-comment"
                        margin="normal"
                        disabled={!state.isEditMode}
                        multiline
                        fullWidth
                      />
                      <Field
                        name="parentHardwareId"
                        id="input-parentHardwareId"
                        className={classes.hiddenField}
                        margin="none"
                      />
                    </Grid>
                  </Form>
                );
              }}
            />
          </Paper>
        </Container>
      </div>
      <Divider className={classes.divider} />
      <Container>
        <HardwareDetailsTabs
          id={Number(id)}
          data={state.data}
          historyData={historyData}
        />
      </Container>
    </>
  );
};

export default HardwareDetails;

export function validateHardwareData(values: UpdateHardwareFormValues) {
  let errors: Partial<Record<keyof UpdateHardwareFormValues, string>> = {};
  const requiredFields = [
    'type',
    'category',
    'brand',
    'supplier',
    'organization',
    'model',
    'serialNumber',
  ];
  Object.entries(values).forEach((value) => {
    if (requiredFields.includes(value[0])) {
      if (!value[1]) {
        errors = {
          ...errors,
          [value[0]]: 'This field is required',
        };
      }
    }
  });
  if (values.hardening && !values.version) {
    errors.version = 'This field is required';
  }

  if (
    values.assigneeType === 'none' &&
    values.parentHardwareId === 'none' &&
    values.status === 'InUse'
  ) {
    errors.status = 'unassigned hardware can\'t have "in use" status';
  }
  if (values.assigneeType !== 'none' && values.status !== 'InUse') {
    errors.status = 'assigned hardware must have "in use" status';
  }
  if (values.vatRate && !isPriceNumber(values.vatRate)) {
    errors.vatRate = 'incorrect numeric value';
  }
  if (values.purchasePrice && !isPriceNumber(values.purchasePrice)) {
    errors.purchasePrice = 'incorrect numeric value';
  }
  if (values.usableDepreciation && !isPositiveInteger(values.usableDepreciation)) {
    errors.usableDepreciation = 'value must be a positive integer';
  }

  return errors;
}
