import React from 'react';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { HardwareWithHardwareChildren } from '../../services/domainServices/HardwareService';
import { HardwareHistory } from '../../services/fetchServices/HardwareApi/getHardwareHistory';
import HarwdareHistoryTable from './HardwareHistory/HardwareHistoryTable';
import RelatedHardwareList from './RelatedHardware/RelatedHardwareList';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  appBar: {
    backgroundColor: 'inherit',
    border: 'none',
    boxShadow: 'none',
  },
  grid: {
    height: 'auto',
    width: '100%',
    minWidth: 1000,
  },
  bar: {
    width: '100%',
  },
  tabs: {
    width: '100%',
  },
}));

type Props = {
  id: number;
  data: HardwareWithHardwareChildren;
  historyData: HardwareHistory;
};

const HardwareDetailsTabs = ({ id, data, historyData }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.grid}
    >
      <div className={classes.bar}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label="History" {...a11yProps(0)} />
            <Tab label="Related Hardware" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div>
      <div className={classes.tabs}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <HarwdareHistoryTable id={Number(id)} historyData={historyData} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <RelatedHardwareList data={data} />
        </TabPanel>
      </div>
    </Grid>
  );
};

export default HardwareDetailsTabs;
