import * as t from 'io-ts';
import { licenseV } from '../../domainServices/LicensesService';
import { fetchWrapper } from '../fetchWrapper';

export const licensesArrayV = t.array(licenseV);
export type LicensesArrayType = t.TypeOf<typeof licensesArrayV>;

export function getUserLicenses(ldapLogin: string) {
  return fetchWrapper({
    url: `/api/user/${ldapLogin}/license`,
    dataType: licensesArrayV,
  });
}
