import { Office, officeV } from '../../domainServices/OfficesService';
import { fetchWrapper } from '../fetchWrapper';

export type UpdateOfficeFormValues = {
  name: string;
  location: number | '';
  organization: number | '';
  comment: string;
};

export const emptyUpdateOfficeFormValues: UpdateOfficeFormValues = {
  name: '',
  location: '',
  organization: '',
  comment: '',
};

export const mapOfficeToFormValues: (office: Office) => UpdateOfficeFormValues = (
  office,
) => ({
  name: office.name,
  location: office.location.id,
  organization: office.organization.id,
  comment: office.comment,
});

export interface UpdateOfficeDto {
  name: string;
  locationId: number;
  organizationId: number;
  comment?: string;
}

export const mapOfficeFormValuesToDto: (
  values: UpdateOfficeFormValues,
) => UpdateOfficeDto = (values) => ({
  name: values.name,
  locationId: values.location || 0,
  organizationId: values.organization || 0,
  comment: values.comment,
});

export function updateOffice(id: string, office: UpdateOfficeFormValues) {
  const officeDto = mapOfficeFormValuesToDto(office);
  const url = `/api/office/${id}`;
  return fetchWrapper({
    url,
    dataType: officeV,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: officeDto,
    },
  });
}
