import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { officeV } from '../../domainServices/OfficesService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetOfficesDtoV = t.type({
  offices: t.array(officeV),
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
});

export type GetOfficesDto = t.TypeOf<typeof GetOfficesDtoV>;

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

type OfficesListQueryObject = {
  filters: {
    organizationFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
};

export function fetchOffices(
  startingIndex: number,
  officesAmount: number,
  queryObject: OfficesListQueryObject,
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: officesAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.organizationFilters.forEach((org) =>
    searchParams.append('Organizations', org),
  );

  return fetchWrapper({
    url: `/api/office?${searchParams.toString()}`,
    dataType: GetOfficesDtoV,
  });
}
