import { toast } from 'react-toastify';
import { addAttribute, AddAttributeFormValue } from './addAttribute';

export const addAttributeDtoInitialValues: AddAttributeFormValue = {
  id: 0,
  name: '',
  hardwareId: [],
  values: [],
};

export const useAddAttributeData = () => {
  const initialValues = addAttributeDtoInitialValues;

  async function submitAddAttribute(values: AddAttributeFormValue) {
    const res = await addAttribute(values);
    if (res.type === 'success') {
      toast.success('Successfully added attribute');
      return res.data;
    }
    toast.error(res.error.message);

    return null;
  }

  return { submitAddAttribute, initialValues };
};
