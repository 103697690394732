import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import { Office } from '../../services/domainServices/OfficesService';

export interface Filters extends Record<ColumnName, string[]> {}

export enum ColumnName {
  'Name',
  'Location',
  'Organization',
  'Comment',
  'Id',
}

export const NUMBER_OF_OFFICES_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.Name]: [],
  [ColumnName.Location]: [],
  [ColumnName.Organization]: [],
  [ColumnName.Comment]: [],
  [ColumnName.Id]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: true,
  [ColumnName[2]]: true,
  [ColumnName[3]]: false,
  [ColumnName[4]]: false,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    // add invisible column to have access to data required for redirection to office data
    {
      name: ColumnName[0],
      label: 'name',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[0]),
      },
    },
    {
      name: ColumnName[1],
      label: 'location',
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
      },
    },
    {
      name: ColumnName[2],
      label: 'organization',
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        filter: true,
        filterList: activeFilters[ColumnName.Organization],
        filterOptions: { names: availableFilters[ColumnName.Organization] },
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[2]),
      },
    },
    {
      name: ColumnName[3],
      label: 'comment',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[4],
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];
}

export function mapOffices(offices: Office) {
  return [
    offices.name,
    offices.location.name,
    offices.organization.name,
    offices.comment,
    offices.id.toString(),
  ];
}
