import React, { useCallback } from 'react';
import { DetailsLink } from '../../components/LazyList/DetailsLink';
import LazyList from '../../components/LazyList/LazyList';
import { defaultOnSearchChange } from '../../components/LazyList/LazyListHelpers';
import {
  ColumnName,
  composeColumnsDefinition,
  Filters,
  mapLicenses,
  NUMBER_OF_LICENSES_FETCHED,
} from './LicensesListHelpers';
import useLicensesListData from './useLicensesListData';

type Props = {
  initialFilters?: Filters;
  actionComponents?: (rowData: string[]) => JSX.Element[];
};

const LicensesListBody = ({ initialFilters, actionComponents }: Props) => {
  const {
    state: {
      totalLicenses,
      filteredLicenses,
      loadedLicenses,
      activeFilters,
      availableFilters,
      displayedColumns,
      activeSort,
      searchText,
      isFetching,
    },
    setState,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onColumnsChangeCallback,
    onDownload,
  } = useLicensesListData(initialFilters);

  const onInfiniteScroll = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        numberOfRequestedLicenses:
          prevState.numberOfRequestedLicenses + NUMBER_OF_LICENSES_FETCHED,
      })),
    [setState],
  );

  const allActionComponents = (rowData: any[]) =>
    actionComponents
      ? [
          ...actionComponents(rowData),
          <DetailsLink link={`/license/${rowData[ColumnName.Id]}`} />,
        ]
      : [<DetailsLink link={`/license/${rowData[ColumnName.Id]}`} />];

  const columns = composeColumnsDefinition(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const options = {
    serverSide: true,
    responsive: 'standard',
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    filter: true,
    filterType: 'multiselect',
    serverSideFilterList: Object.values(activeFilters),
    searchText,
    onDownload,
    selectableRows: 'none',
    rowHover: false,
    searchOpen: true,
    textLabels: {
      toolbar: {
        downloadCsv: 'Download Report',
      },
    },
  };

  const data = loadedLicenses.map(mapLicenses);

  return (
    <LazyList
      data={data}
      columns={columns}
      options={options}
      onFilterChange={contextEnchancedOnFiltersChange}
      onSortChange={contextEnchancedOnSortChange}
      onSearchChange={defaultOnSearchChange(setState)}
      onColumnsChange={onColumnsChangeCallback}
      totalItems={totalLicenses}
      filteredItems={filteredLicenses}
      onInfiniteScrollCallback={onInfiniteScroll}
      isFetching={isFetching}
      actionComponents={allActionComponents}
    />
  );
};

export default LicensesListBody;
