import React from 'react';
import { Checkbox } from '@material-ui/core';
import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import {
  Hardware,
  hardwareStatusLabels,
} from '../../services/domainServices/HardwareService';
import CheckBoxHandler, { checkboxStyle } from './CheckBoxHandler';

export interface Filters extends Record<ColumnName, string[]> {}

export enum ColumnName {
  'JitTag',
  'Category',
  'Brand',
  'Model',
  'SerialNumber',
  'PurchasePrice',
  'Status',
  'Organization',
  'AssignedTo',
  'AtHome',
  'PriceFrom',
  'PriceTo',
  'id',
  'Hardening',
  'Version',
  'HasInstalledLinux',
}

export const NUMBER_OF_HARDWARE_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.JitTag]: [],
  [ColumnName.Category]: [],
  [ColumnName.Brand]: [],
  [ColumnName.Model]: [],
  [ColumnName.SerialNumber]: [],
  [ColumnName.PurchasePrice]: [],
  [ColumnName.Status]: [
    hardwareStatusLabels.Free,
    hardwareStatusLabels.FreeWarsaw,
    hardwareStatusLabels.InUse,
  ],
  [ColumnName.Organization]: [],
  [ColumnName.AssignedTo]: [],
  [ColumnName.AtHome]: [],
  [ColumnName.PriceFrom]: [],
  [ColumnName.PriceTo]: [],
  [ColumnName.id]: [],
  [ColumnName.Hardening]: [],
  [ColumnName.Version]: [],
  [ColumnName.HasInstalledLinux]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: false,
  [ColumnName[2]]: true,
  [ColumnName[3]]: true,
  [ColumnName[4]]: true,
  [ColumnName[5]]: true,
  [ColumnName[6]]: true,
  [ColumnName[7]]: false,
  [ColumnName[8]]: true,
  [ColumnName[9]]: true,
  [ColumnName[10]]: false,
  [ColumnName[11]]: false,
  [ColumnName[12]]: false,
  [ColumnName[13]]: true,
  [ColumnName[14]]: false,
  [ColumnName[15]]: true,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    {
      name: ColumnName[0],
      label: 'JIT tag',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[0]),
      },
    },
    {
      name: ColumnName[1],
      label: 'category',
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
        filter: true,
        filterList: activeFilters[ColumnName.Category],
        filterOptions: { names: availableFilters[ColumnName.Category] },
      },
    },
    {
      name: ColumnName[2],
      label: 'brand',
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[2]),
        filter: true,
        filterList: activeFilters[ColumnName.Brand],
        filterOptions: { names: availableFilters[ColumnName.Brand] },
      },
    },
    {
      name: ColumnName[3],
      label: 'model',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[3]),
      },
      widthUnits: 1.25,
    },
    {
      name: ColumnName[4],
      label: 'serial number',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        filter: false,
        sort: false,
      },
      widthUnits: 1.25,
    },
    {
      name: ColumnName[5],
      label: 'purchase price',
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[5]),
        filter: false,
      },
    },
    {
      name: ColumnName[6],
      label: 'status',
      options: {
        display: displayedColumns[ColumnName[6]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[6]),
        filter: true,
        filterList: activeFilters[ColumnName.Status],
        filterOptions: { names: availableFilters[ColumnName.Status] },
      },
    },
    {
      name: ColumnName[7],
      label: 'organization',
      options: {
        display: displayedColumns[ColumnName[7]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[7]),
        filter: true,
        filterList: activeFilters[ColumnName.Organization],
        filterOptions: { names: availableFilters[ColumnName.Organization] },
      },
      widthUnits: 1.25,
    },
    {
      name: ColumnName[8],
      label: 'assigned to',
      options: {
        display: displayedColumns[ColumnName[8]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[8]),
        filter: false,
      },
      widthUnits: 1.75,
    },
    {
      name: ColumnName[9],
      label: 'at home',
      options: {
        display: displayedColumns[ColumnName[9]].toString(),
        sort: false,
        sortDirection: getSortDirection(activeSort, ColumnName[9]),
        filter: false,
      },
      widthUnits: 1.75,
    },
    // add invisible columns as a workaround to get filters priceFrom and priceTo
    {
      name: ColumnName[10],
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.PriceFrom],
        customFilterListRender: (v: string) => `price from: ${v}`,
      },
    },
    {
      name: ColumnName[11],
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.PriceTo],
        customFilterListRender: (v: string) => `price to: ${v}`,
      },
    },
    // add invisible column to have access to data required for redirection to user data
    {
      name: ColumnName[12],
      options: {
        display: 'excluded',
        filter: false,
        // important for extracting value from display data in mui-datatables custom toolbar. Don't remove.
        customBodyRender: (value: string) => value,
      },
    },
    {
      name: ColumnName[13],
      label: 'hardening',
      options: {
        display: displayedColumns[ColumnName[13]],
        sort: false,
        sortDirection: getSortDirection(activeSort, ColumnName[13]),
        filter: false,
      },
      widthUnits: 1.75,
    },
    {
      name: ColumnName[14],
      label: 'version',
      options: {
        display: displayedColumns[ColumnName[14]],
        sort: false,
        sortDirection: getSortDirection(activeSort, ColumnName[14]),
        filter: false,
      },
      widthUnits: 1.75,
    },
    {
      name: ColumnName[15],
      label: 'linux installed',
      options: {
        display: displayedColumns[ColumnName[15]],
        sort: false,
        sortDirection: getSortDirection(activeSort, ColumnName[15]),
        filter: false,
      },
      widthUnits: 1.75,
    },
  ];
}

function getAssignedTo(hardware: Hardware) {
  if (hardware.user != null)
    return `${hardware.user.lastName} ${hardware.user.firstName}`;

  if (hardware.office != null) return hardware.office.name;

  return '';
}

export const mapHardware = (hardware: Hardware) => [
  hardware.jitTag,
  hardware.category.name,
  hardware.brand.name,
  hardware.model,
  hardware.serialNumber,
  hardware.purchasePrice ? `${hardware.purchasePrice.toLocaleString()} PLN` : '',
  hardwareStatusLabels[hardware.status],
  hardware.organization.name,
  getAssignedTo(hardware),
  <CheckBoxHandler hardware={hardware} disabled={false} />,
  '',
  '',
  hardware.id.toString(),
  <Checkbox checked={hardware.hardening} style={checkboxStyle} disabled />,
  hardware.version,
  <Checkbox checked={hardware.hasInstalledLinux} style={checkboxStyle} disabled />,
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
