import { useEffect, useState } from 'react';

export function useDialogTimeOut() {
  const [privatePhoneDialogOpen, setPrivatePhoneDialogOpen] = useState(false);

  useEffect(() => {
    if (privatePhoneDialogOpen) {
      const handler = setTimeout(() => {
        setPrivatePhoneDialogOpen(false);
      }, 10000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [privatePhoneDialogOpen]);
  return {
    privatePhoneDialogOpen,
    setPrivatePhoneDialogOpen,
  };
}
