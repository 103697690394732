import { toast } from 'react-toastify';
import { UpdateOrganization, updateOrganization } from './updateOrganization';

export const useUpdateOrganizationData = () => {
  async function submitUpdateOrganization(id: number, values: UpdateOrganization) {
    const res = await updateOrganization(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated organization');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitUpdateOrganization };
};
