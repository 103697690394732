import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const userHistoryDtoV = t.array(
  t.type({
    ldapLogin: t.string,
    userDifferences: t.array(
      t.type({
        propertyName: nullableString,
        valueBefore: nullableString,
        valueAfter: nullableString,
      }),
    ),
    actionType: nullableString,
    date: DateFromISOString,
  }),
);

export type UserHistoryDto = t.TypeOf<typeof userHistoryDtoV>;
export type UserHistory = {
  ldapLogin: string;
  userDifferences: {
    propertyName: string;
    valueBefore: string | string[];
    valueAfter: string | string[];
  }[];
  actionType: string;
  date: Date;
}[];

export function getUserHistory(id: number) {
  return fetchWrapper({
    url: `/api/user/${id}/history`,
    dataType: userHistoryDtoV,
  });
}

export function parseHistoryData(input: UserHistoryDto) {
  const parsed: UserHistory = input.map((history) => ({
    ...history,
    userDifferences: history.userDifferences.map((diff) => {
      const valueBefore = ['MailingList'].includes(diff.propertyName)
        ? JSON.parse(diff.valueBefore)
        : diff.valueBefore;
      const valueAfter = ['MailingList'].includes(diff.propertyName)
        ? JSON.parse(diff.valueAfter)
        : diff.valueAfter;
      return { ...diff, valueBefore, valueAfter };
    }),
  }));
  return parsed;
}
