import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const AddOrganizationV = t.type({
  name: nullableString,
});

export type AddOrganizationFormValue = t.TypeOf<typeof AddOrganizationV>;

export function addOrganization(organization: AddOrganizationFormValue) {
  return fetchWrapper({
    url: '/api/organization',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: organization,
    },
  });
}
