import React from 'react';
import SettingsListHeader from './SettingsListHeader';
import SettingsTabs from './SettingsTabs';

const SettingsList = () => (
  <div>
    <SettingsListHeader />
    <SettingsTabs />
  </div>
);

export default SettingsList;
