import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import PageHeader, { useStyles } from '../../components/PageHeader';
import { useToggle } from '../../hooks/useToggle';
import { getInventoryEmailEnabled } from '../../services/fetchServices/InventoryApi/getInventoryEmailEnabled';
import { sendStartInventoryEmail } from '../../services/fetchServices/InventoryApi/sendStartInventoryEmail';

const InventoryListHeader = () => {
  const classes = useStyles();
  const { toggle, value: isDialogOpen } = useToggle(false);
  const { TEST_ENV } = window._env_;

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getInventoryEmailEnabled();
      if (res.type === 'success') {
        setButtonEnabled(TEST_ENV === 'true' || res.data);
      } else {
        toast.error('Cannot fetch information if email sending is enabled');
      }
    };
    fetchData();
  }, [TEST_ENV]);

  const sendEmail = async () => {
    try {
      setSubmitting(true);
      await sendStartInventoryEmail();
      setButtonEnabled(false);
      window.location.reload();
      toggle();
    } catch (e) {
      toast.error('Sending email failed');
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <PageHeader
        title="Inventory"
        actionButtons={
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={toggle}
            disabled={!buttonEnabled}
          >
            SEND INVENTORY EMAIL
          </Button>
        }
      />
      {isDialogOpen && (
        <ConfirmDialog
          content="You're trying to send inventory email to everyone"
          title="INVENTORY EMAIL"
          onClose={toggle}
          handleClick={sendEmail}
          open={isDialogOpen}
          submitting={submitting}
        />
      )}
    </>
  );
};

export default InventoryListHeader;
