import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import {
  LicensesType,
  licensesTypeLabels,
  licenseV,
} from '../../domainServices/LicensesService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetLicensesDtoV = t.type({
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
  licenses: t.array(licenseV),
});

export type GetLicensesDto = t.TypeOf<typeof GetLicensesDtoV>;

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

export const licensesTypeLabelsMap = (label: string) =>
  Object.keys(licensesTypeLabels).find(
    (licensesType) => licensesTypeLabels[licensesType as LicensesType] === label,
  ) || '';

type LicensesListQueryObject = {
  filters: {
    organizationFilters: string[];
    typeFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
};

export function fetchLicenses(
  startingIndex: number,
  licensesAmount: number,
  queryObject: LicensesListQueryObject,
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: licensesAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.organizationFilters.forEach((org) =>
    searchParams.append('Organizations', org),
  );
  queryObject.filters.typeFilters.forEach((type) =>
    searchParams.append('LicenseTypes', licensesTypeLabelsMap(type)),
  );

  return fetchWrapper({
    url: `/api/license?${searchParams.toString()}`,
    dataType: GetLicensesDtoV,
  });
}
