import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export function deleteType(id: number) {
  return fetchWrapper({
    url: `/api/hardware/type/${id}`,
    fetchArg: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}
