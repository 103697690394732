import { toast } from 'react-toastify';
import { UpdateCategory, updateCategory } from './updateCategory';

export const useUpdateCategoryData = () => {
  async function submitUpdateCategory(id: number, values: UpdateCategory) {
    const res = await updateCategory(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated category');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitUpdateCategory };
};
