import React, { useCallback } from 'react';
import { DetailsLink } from '../../components/LazyList/DetailsLink';
import LazyList from '../../components/LazyList/LazyList';
import { defaultOnSearchChange } from '../../components/LazyList/LazyListHelpers';
import {
  ColumnName,
  composeColumnsDefinition,
  mapOffices,
  NUMBER_OF_OFFICES_FETCHED,
} from './OfficesListHelpers';
import useOfficesListData from './useOfficesListData';

const OfficesListBody = () => {
  const {
    state: {
      totalOffices,
      filteredOffices,
      loadedOffices,
      activeFilters,
      availableFilters,
      displayedColumns,
      activeSort,
      searchText,
      isFetching,
    },
    setState,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onColumnsChangeCallback,
  } = useOfficesListData();

  const onInfiniteScroll = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        numberOfRequestedOffices:
          prevState.numberOfRequestedOffices + NUMBER_OF_OFFICES_FETCHED,
      })),
    [setState],
  );

  const allActionComponents = (rowData: any[]) => [
    <DetailsLink link={`/office/${rowData[ColumnName.Id]}`} />,
  ];

  const columns = composeColumnsDefinition(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const options = {
    serverSide: true,
    responsive: 'standard',
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    filter: true,
    filterType: 'multiselect',
    serverSideFilterList: Object.values(activeFilters),
    searchText,
    selectableRows: 'none',
    rowHover: false,
    searchOpen: true,
    textLabels: {
      toolbar: {
        downloadCsv: 'Download Report',
      },
    },
  };

  const data = loadedOffices.map(mapOffices);

  return (
    <LazyList
      data={data}
      columns={columns}
      options={options}
      onFilterChange={contextEnchancedOnFiltersChange}
      onSortChange={contextEnchancedOnSortChange}
      onSearchChange={defaultOnSearchChange(setState)}
      onColumnsChange={onColumnsChangeCallback}
      totalItems={totalOffices}
      filteredItems={filteredOffices}
      onInfiniteScrollCallback={onInfiniteScroll}
      isFetching={isFetching}
      actionComponents={allActionComponents}
    />
  );
};

export default OfficesListBody;
