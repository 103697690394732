import React from 'react';
import OfficesListBody from './OfficesListBody';
import OfficesListHeader from './OfficesListHeader';

const OfficesList = () => (
  <div>
    <OfficesListHeader />
    <OfficesListBody />
  </div>
);

export default OfficesList;
