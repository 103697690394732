import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getUserHistory,
  parseHistoryData,
  UserHistory,
} from '../../../services/fetchServices/UserApi/getUserHistory';

export const useUserHistoryData = (id: number) => {
  const [historyData, setHistoryData] = useState<UserHistory>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const userHistory = await getUserHistory(id);
      if (userHistory.type === 'success') {
        const parsedHistory = parseHistoryData(userHistory.data);
        setHistoryData(parsedHistory);
      } else {
        toast.error(userHistory.error.message);
      }
      setIsLoading(false);
    };
    if (id !== 0) {
      fetchData();
    }
  }, [id]);

  return {
    historyData,
    setHistoryData,
    isLoading,
    setIsLoading,
  };
};
