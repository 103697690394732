import { useCallback, useEffect, useState } from 'react';

interface Args {
  callback: () => void;
  disableFetching: boolean;
}

/**
 * This hook fires a callback if it detects that ref elements bottom is visible - either at the element mount, or after scroll
 *
 * @param {callback: () => void, disableFetching: boolean} args - options parameter with two properties:
 *     callback is the function you want to invoke when bottom is detected
 *     disableFetching is a flag that prevents invoking callback
 * @return {(n: HTMLElement) => void} ref variable meant to attach to node you want to observe
 *
 * @example
 *
 *     const ref = useElementBottomDetection({callback: () => {fetchMoreData()}, disableFetching: false})
 */
const useElementBottomDetection = ({ callback, disableFetching }: Args) => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const ref = useCallback((n: HTMLElement | null) => {
    setNode(n);
  }, []);

  // fire callback if we see bottom of the list
  useEffect(() => {
    if (disableFetching) {
      return;
    }

    const isBottomVisible = node
      ? node.getBoundingClientRect().bottom <= window.innerHeight
      : false;

    if (isBottomVisible) {
      callback();
    }
  }, [node, callback, disableFetching]);

  // fire callback if we scroll to the bottom of the list.
  useEffect(() => {
    if (disableFetching) {
      return;
    }

    function handleScroll() {
      const bottomScrollDetected =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight;

      if (bottomScrollDetected) {
        callback();
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [callback, disableFetching]);

  return ref;
};

export default useElementBottomDetection;
