import React from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FetchResponse } from '../../../services/fetchServices/fetchWrapper';
import { LicensesArrayType } from '../../../services/fetchServices/UserApi/getUserLicenses';
import { UserLicenses } from './UserLicenses';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: '#fafafa',
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    button: {
      borderRadius: 0,
      textTransform: 'none',
      backgroundColor: '#f2f2f2',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

interface UserLicensesTableToolbarProps {
  assignLicense: (licenseId: number) => Promise<FetchResponse<{}>>;
  data: LicensesArrayType;
}

export const UserLicensesTableToolbar = ({
  assignLicense,
  data,
}: UserLicensesTableToolbarProps) => {
  const classes = useToolbarStyles();
  const [assigning, setAssigning] = React.useState(false);

  const assignLicenseCallback = async (id: number) => {
    setAssigning(true);
    const res = await assignLicense(id);
    if (res.type === 'success') {
      toast.success('Successfully assigned license');
    }
    setAssigning(false);
  };
  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle" />
      <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item>{assigning ? <CircularProgress size={24} /> : null}</Grid>
        <Grid item>
          <UserLicenses assigning={assigning} assignLicense={assignLicenseCallback} />
        </Grid>
        <Grid item />
      </Grid>
    </Toolbar>
  );
};
