/* eslint-disable no-restricted-syntax */
import React, { useMemo, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LicenseHistory } from '../../../services/fetchServices/LicensesApi/getLicenseHistory';
import { LicenseHistoryTableHeader } from './LicenseHistoryHeader';
import { LicenseMetaData, useLicenseMetaData } from './useLicenseMetaData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    differencesCell: {
      minWidth: 200,
      maxWidth: 200,
      border: 'none',
      overflowWrap: 'break-word',
    },
  }),
);

type LicenseHistoryItem = {
  ldapLogin: string;
  licenseDifferences: {
    propertyName: string;
    valueBefore: string | string[];
    valueAfter: string | string[];
  }[];
  actionType: string;
  date: Date;
};

type Props = {
  historyData: LicenseHistory;
};

function formatStringOrArray(value: string | string[]) {
  let returnValue = value;
  if (value === '[]') returnValue = '';
  return Array.isArray(returnValue)
    ? returnValue.map((v, idx) => (idx !== 0 ? `, ${v}` : v))
    : returnValue;
}

function addSpaceBeforeUpperCase(propertyName: string) {
  const newValue = propertyName.replace(/([A-Z])/g, ' $1').trim();
  return newValue.toLocaleLowerCase().replace('id', '');
}

function mapOrganizations(valueOf: string | string[], metaData: LicenseMetaData) {
  for (const value in metaData.organizations) {
    // eslint-disable-next-line no-prototype-builtins
    if (metaData.organizations.hasOwnProperty(value)) {
      if (Number(valueOf) === metaData.organizations.map((org) => org.id)[value])
        return formatStringOrArray(metaData.organizations.map((org) => org.name)[value]);
    }
  }
}

function mapAvailability(valueOf: string | string[]) {
  if (valueOf === 'True') return 'Yes';

  return 'No';
}

function convertValueToDisplayValue(
  propertyName: string,
  valueOf: string | string[],
  metaData: LicenseMetaData,
  moreHistory: number | undefined,
  id: number,
) {
  let returnValueOf = valueOf;
  if (propertyName === 'OrganizationId') {
    return mapOrganizations(valueOf, metaData);
  }
  if (propertyName === 'Available') {
    return mapAvailability(valueOf);
  }
  if (propertyName === 'AssignTo') {
    returnValueOf = JSON.parse(returnValueOf as string) as string[];
  }
  if (propertyName === 'ExpirationDate') {
    returnValueOf = new Date(returnValueOf as string).toLocaleDateString();
  }

  return moreHistory !== id
    ? formatStringOrArray(returnValueOf).slice(0, 30)
    : formatStringOrArray(returnValueOf);
}

const LicenseHistoryTable = ({ historyData }: Props) => {
  const [displayMoreHistory, setDisplayMoreHistory] = useState(false);
  const [moreHistory, setMoreHistory] = useState<number | undefined>(undefined);
  const metaData = useLicenseMetaData();
  const classes = useStyles();

  const displayedHistory = useMemo(
    () => (displayMoreHistory ? historyData : historyData.slice(0, 5)),
    [displayMoreHistory, historyData],
  );

  const createHistoryTable = (d: LicenseHistoryItem, labelId: string, id: number) => (
    <TableRow tabIndex={-1}>
      <TableCell id={labelId} align="left">
        {d.ldapLogin}
      </TableCell>
      <TableCell id={labelId} align="left">
        {d.actionType}
      </TableCell>
      <TableCell align="left">
        {d.licenseDifferences.map((diff) => (
          <TableRow>
            <TableCell className={classes.differencesCell}>
              {addSpaceBeforeUpperCase(diff.propertyName)}
            </TableCell>
            <TableCell className={classes.differencesCell}>
              {convertValueToDisplayValue(
                diff.propertyName,
                diff.valueBefore,
                metaData,
                moreHistory,
                id,
              )}
            </TableCell>
            <TableCell
              key={diff.propertyName + diff.valueAfter}
              className={classes.differencesCell}
            >
              {convertValueToDisplayValue(
                diff.propertyName,
                diff.valueAfter,
                metaData,
                moreHistory,
                id,
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableCell>
      <TableCell align="left">{d.date.toLocaleString()}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            moreHistory === id ? setMoreHistory(undefined) : setMoreHistory(id)
          }
          size="small"
        >
          {moreHistory === id ? 'Less' : 'More'}
        </Button>
      </TableCell>
    </TableRow>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {historyData.length === 0 ? (
            <Typography paragraph>No history for this license</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <LicenseHistoryTableHeader />
              <TableBody>
                {displayedHistory.map((history, id) => {
                  const labelId = `enhanced-table-checkbox-${history.actionType}`;
                  return createHistoryTable(history, labelId, id);
                })}
              </TableBody>
            </Table>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisplayMoreHistory(!displayMoreHistory)}
            size="small"
          >
            {displayMoreHistory ? 'Show Less History' : 'Show More History'}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default LicenseHistoryTable;
