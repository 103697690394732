import React from 'react';
import { AppBar, Grid, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AttributesList from './Attributes/AttributesList';
import BrandsList from './Brands/BrandsList';
import CategoriesList from './Categories/CategoriesList';
import LocationsList from './Locations/LocationsList';
import OrganizationsList from './Organizations/OrganizationsList';
import SuppliersList from './Suppliers/SuppliersList';
import TypesList from './Types/TypesList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper
          style={{
            width: '50vw',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
            backgroundColor: 'inherit',
          }}
        >
          <Box p={3}>{children}</Box>
        </Paper>
      )}
    </Typography>
  );
};

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: 'auto',
    marginTop: '5%',
  },
  appBar: {
    backgroundColor: 'inherit',
    border: 'none',
    boxShadow: 'none',
  },
  tabPanels: {
    marginTop: '3%',
  },
}));

const SettingsTabs = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            centered
            aria-label="Vertical tabs example"
          >
            <Tab label="Attributes" {...a11yProps(0)} />
            <Tab label="Hardware Organizations" {...a11yProps(1)} />
            <Tab label="Brands" {...a11yProps(2)} />
            <Tab label="Types" {...a11yProps(3)} />
            <Tab label="Suppliers" {...a11yProps(4)} />
            <Tab label="Categories" {...a11yProps(5)} />
            <Tab label="Locations" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
      </div>
      <div className={classes.tabPanels}>
        <TabPanel value={value} index={0}>
          <AttributesList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrganizationsList />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BrandsList />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TypesList />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SuppliersList />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <CategoriesList />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <LocationsList />
        </TabPanel>
      </div>
    </Grid>
  );
};

export default SettingsTabs;
