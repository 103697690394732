import { createDataResponse, createErrorResponse } from '../fetchWrapper';
import { statusLabelsMap } from './fetchHardware';

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

type HardwareListQueryObject = {
  filters: {
    statusFilters: string[];
    brandFilters: string[];
    categoryFilters: string[];
    organizationFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
  priceFrom: string | undefined;
  priceTo: string | undefined;
  columns: string[];
};

export async function getHardwareReport(queryObject: HardwareListQueryObject) {
  const params: Record<string, string> = {
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }

  if (queryObject.priceFrom) {
    searchParams.append('PriceFrom', queryObject.priceFrom);
  }

  if (queryObject.priceTo) {
    searchParams.append('PriceTo', queryObject.priceTo);
  }

  queryObject.filters.statusFilters.forEach((status) =>
    searchParams.append('Statuses', statusLabelsMap(status)),
  );
  queryObject.filters.brandFilters.forEach((brand) =>
    searchParams.append('Brands', brand),
  );
  queryObject.filters.categoryFilters.forEach((category) =>
    searchParams.append('Categories', category),
  );
  queryObject.filters.organizationFilters.forEach((organization) =>
    searchParams.append('Organizations', organization),
  );
  queryObject.columns.forEach((column) => searchParams.append('fields', column));

  try {
    const res = await fetch(`/api/report/hardware?${searchParams.toString()}`, {
      method: 'POST',
      headers: {
        accept: 'text/plain',
      },
    });
    if (!res.ok) {
      throw new Error("couldn't download report");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
