import React, { useState } from 'react';
import { IconButton, List, ListItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { License } from '../../services/domainServices/LicensesService';

const useStyles = makeStyles(() =>
  createStyles({
    flexWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    moreLessBtn: {
      marginRight: '25%',
    },
  }),
);

interface AssignedUsersListProps {
  licenses: License;
}

const AssignedUsersList = ({ licenses }: AssignedUsersListProps) => {
  const classes = useStyles();
  const firstUserLicense = licenses.userLicenses.slice(0, 1);
  const [showMore, setShowMore] = useState(false);
  const visibleLicenses = showMore ? licenses.userLicenses : firstUserLicense;
  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };
  return (
    <div className={classes.flexWrapper}>
      <List>
        {visibleLicenses.map((ul) => (
          <ListItem key={ul.userId} style={{ padding: 0 }}>
            {ul.firstName} {ul.lastName}
          </ListItem>
        ))}
      </List>
      <IconButton onClick={toggleShowMore}>
        {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </div>
  );
};

export default AssignedUsersList;
