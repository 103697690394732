import {
  License,
  LicensesKind,
  LicensesType,
  licenseV,
} from '../../domainServices/LicensesService';
import { fetchWrapper } from '../fetchWrapper';

export type UserLicenses = {
  licenseId: number | '';
  userId: number | '';
  firstName: string;
  lastName: string;
  ldapLogin: string;
};

export type UpdateLicenseFormValues = {
  name: string;
  licenseType: LicensesType;
  licenseKind: LicensesKind;
  organization: number | '';
  firstKey: string;
  secondKey: string;
  login: string;
  password: string;
  expirationDate: Date | null;
  purchasePrice?: string;
  capacity: number | '';
  available: boolean;
  comment: string;
  userLicenses: string;
};

export const emptyUpdateLicenseFormValues: UpdateLicenseFormValues = {
  name: '',
  licenseType: 'Box',
  licenseKind: 'Single',
  organization: '',
  firstKey: '',
  secondKey: '',
  login: '',
  password: '',
  expirationDate: null,
  purchasePrice: '',
  capacity: '',
  available: true,
  comment: '',
  userLicenses: '',
};

export const mapLicenseToFormValues: (license: License) => UpdateLicenseFormValues = (
  license,
) => ({
  name: license.name,
  licenseType: license.licenseType,
  licenseKind: license.licenseKind,
  organization: license.organization.id,
  firstKey: license.firstKey,
  secondKey: license.secondKey,
  login: license.login,
  password: license.password,
  expirationDate: license.expirationDate,
  purchasePrice: license.purchasePrice ? license.purchasePrice.toString() : '',
  capacity: license.capacity,
  available: license.available,
  comment: license.comment,
  userLicenses: license.userLicenses
    ? license.userLicenses.map((u) => u.ldapLogin).join(', ')
    : '',
});

export interface UpdateLicenseDto {
  name: string;
  licenseType: LicensesType;
  licenseKind: LicensesKind;
  organizationId: number;
  firstKey: string;
  secondKey: string;
  login: string;
  password: string;
  expirationDate?: string;
  purchasePrice?: number;
  capacity: number;
  available: boolean;
  comment: string;
}
export const mapFormValuesToDto: (values: UpdateLicenseFormValues) => UpdateLicenseDto = (
  values,
) => ({
  name: values.name,
  licenseType: values.licenseType,
  licenseKind: values.licenseKind,
  organizationId: values.organization || 0,
  firstKey: values.firstKey,
  secondKey: values.secondKey,
  login: values.login,
  password: values.password,
  expirationDate: values.expirationDate ? values.expirationDate.toISOString() : undefined,
  purchasePrice: values.purchasePrice ? Number(values.purchasePrice) : undefined,
  capacity: values.capacity || 0,
  available: values.available,
  comment: values.comment,
});

export function updateLicense(id: string, license: UpdateLicenseFormValues) {
  const licenseDto = mapFormValuesToDto(license);
  const url = `/api/license/${id}`;
  return fetchWrapper({
    url,
    dataType: licenseV,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: licenseDto,
    },
  });
}
