import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Divider, Grid, IconButton, MenuItem, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import AssignHardwareList from '../../components/AssignHardwareComponents/AssignHardwareList';
import { UpdateOfficeFormValues } from '../../services/fetchServices/OfficesApi/updateOffice';
import { useOfficeHardwareData } from './OfficeHardware/useOfficeHardwareData';
import OfficeDetailsHeader from './OfficeDetailsHeader';
import { useOfficeData } from './useOfficeData';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textField: {
      width: '90%',
    },
    textField3: {
      width: '95%',
    },
    paper: {
      width: '100%',
      paddingBottom: '4%',
      textAlign: 'center',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    icon: {
      margin: theme.spacing(1),
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'grey',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
    divider: {
      marginTop: '1%',
      marginBottom: '1%',
    },
  }),
);

const OfficeDetails = () => {
  const classes = useStyles();
  const { id = '' } = useParams();
  const { state, setIsEdit, submitUpdateOffice } = useOfficeData(id);
  const { data, submitUnassignHardware, submitAssignHardware } =
    useOfficeHardwareData(id);

  return (
    <>
      <OfficeDetailsHeader />
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              enableReinitialize
              initialValues={state.formData}
              validate={validateOfficeData}
              onSubmit={submitUpdateOffice}
              render={({ submitForm, resetForm }) => (
                <Form>
                  {!state.isEditMode ? (
                    <div className={classes.iconContainer}>
                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          setIsEdit(!state.isEditMode);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div className={classes.iconContainer}>
                      <IconButton className={classes.icon} onClick={submitForm}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          resetForm();
                          setIsEdit(!state.isEditMode);
                        }}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    </div>
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="name"
                        id="standard-read-only-input-name"
                        label="name"
                        component={TextField}
                        className={classes.textField}
                        disabled={!state.isEditMode}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        name="location"
                        label="location"
                        select
                        component={TextField}
                        id="standard-read-only-input-location"
                        required
                        className={classes.textField}
                        disabled={!state.isEditMode}
                      >
                        {state.metaData.locations.map((loc) => (
                          <MenuItem value={loc.id} key={loc.id}>
                            {loc.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Field
                        name="organization"
                        label="organization"
                        select
                        component={TextField}
                        id="standard-read-only-input-organization"
                        required
                        className={classes.textField}
                        disabled={!state.isEditMode}
                      >
                        {state.metaData.organizations.map((org) => (
                          <MenuItem value={org.id} key={org.id}>
                            {org.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name="comment"
                        label="comment"
                        component={TextField}
                        id="input-comment"
                        className={classes.textField3}
                        disabled={!state.isEditMode}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Paper>
        </div>
      </Container>
      <Divider className={classes.divider} />
      <Container>
        <AssignHardwareList
          data={data}
          submitUnassignHardware={submitUnassignHardware}
          submitAssignHardware={submitAssignHardware}
        />
      </Container>
    </>
  );
};

export default OfficeDetails;

export function validateOfficeData(values: UpdateOfficeFormValues) {
  const errors: Partial<Record<keyof UpdateOfficeFormValues, string>> = {};
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.location) {
    errors.location = 'required';
  }
  if (!values.organization) {
    errors.organization = 'required';
  }
  return errors;
}
