import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PageHeader from '../../components/PageHeader';
import { guest, useUserDispatch, useUserState } from '../../context/UserDataProvider';
import { HardwareMatching } from '../../services/domainServices/HardwareService';
import {
  getUserHardware,
  HardwareArrayType,
} from '../../services/fetchServices/HardwareApi/getUserHardware';
import { getInventoryUserData } from '../../services/fetchServices/InventoryApi/getInventoryUserData';
import { getInventoryUserHardware } from '../../services/fetchServices/InventoryApi/getInventoryUserHardware';
import {
  InventoryFormValues,
  updateInventory,
} from '../../services/fetchServices/InventoryApi/updateInventory';
import { updateInventoryReview } from '../../services/fetchServices/InventoryApi/updateInventoryReview';
import { logout } from '../../services/fetchServices/logout';
import { canReadApplication } from '../../utils/isAdmin';
import { InventoryDetailsHeader } from './InventoryDetailsHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    commentPaper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    commentTextField: {
      width: '98%',
    },
    table: {
      minWidth: '90%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    hideIcon: {
      opacity: '0%',
    },
    showIcon: {
      opacity: '100%',
    },
    circularProgress: {
      padding: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

const InventoryDetails = () => {
  const { ldapLogin = '' } = useParams();
  const { instance, accounts } = useMsal();

  const classes = useStyles();
  const user = useUserState();
  const setUser = useUserDispatch();
  const isUserAdmin = canReadApplication(user.roles);
  const [data, setData] = useState<HardwareArrayType>([]);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (isUserAdmin) {
        const userHardware = await getUserHardware(ldapLogin);
        if (userHardware.type === 'success') {
          setData(userHardware.data);
        } else {
          toast.error(userHardware.error.message);
        }
      } else {
        const userHardware = await getInventoryUserHardware(user.ldapLogin);
        if (userHardware.type === 'success') {
          setData(userHardware.data);
        } else {
          toast.error(userHardware.error.message);
        }
      }
      const inventoryData = await getInventoryUserData(
        isUserAdmin ? ldapLogin : user.ldapLogin,
      );
      if (inventoryData.type === 'error') {
        toast.error(inventoryData.error.message);
      } else {
        setComment(inventoryData.data.inventoryComment);
        setInventoryReviewed(inventoryData.data.inventoryReviewed);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [isUserAdmin, ldapLogin, user.ldapLogin]);

  const [userForm, setUserForm] = useState<Array<InventoryFormValues>>([]);
  const [inventoryComment, setComment] = useState('');
  const [inventoryReviewed, setInventoryReviewed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (data) {
      setUserForm([
        ...data.map((item) => {
          let jitTagForm = '';
          if (item.inventoryJitTag !== '') {
            jitTagForm = item.inventoryJitTag;
          } else if (item.category.name === 'Karta dostępowa') {
            jitTagForm = 'JIT-K';
          } else {
            jitTagForm = 'JIT-';
          }
          return {
            id: item.id,
            hardwareMatched: item.hardwareMatched,
            jitTagForm,
            comment: item.inventoryComment,
          };
        }),
      ]);
    }
  }, [data]);

  const hardwareMatchingClick = (value: HardwareMatching, id: number) => {
    setUserForm((prev) => {
      if (prev !== undefined) {
        const newState = [...prev];

        return newState.map((el) => {
          if (el.id === id) {
            return { ...el, hardwareMatched: value };
          }
          return el;
        });
      }
      return prev;
    });
  };

  const jitTagChange = (value: string, id: number, categoryName: string) => {
    setUserForm((prev) => {
      if (prev !== undefined) {
        const newState = [...prev];

        return newState.map((el) => {
          if (el.id === id) {
            const prefix = categoryName !== 'Karta dostępowa' ? 'JIT-' : 'JIT-K';
            return {
              ...el,
              jitTagForm:
                prefix + value.replace(' ', '').replace('JIT-K', '').replace('JIT-', ''),
            };
          }
          return el;
        });
      }
      return prev;
    });
  };

  const commentChange = (value: string, id: number) => {
    setUserForm((prev) => {
      if (prev !== undefined) {
        const newState = [...prev];

        return newState.map((el) => {
          if (el.id === id) {
            return {
              ...el,
              comment: value,
            };
          }
          return el;
        });
      }
      return prev;
    });
  };

  const saveDisabled = useMemo(() => {
    if (userForm !== undefined) {
      return (
        userForm.some(
          (el) =>
            el.hardwareMatched === 'Unchecked' ||
            (el.hardwareMatched === 'Unmatched' && el.comment === ''),
        ) ||
        userForm.some(
          (el) =>
            (el.jitTagForm === 'JIT-' || el.jitTagForm === 'JIT-K') && el.comment === '',
        )
      );
    }
    return true;
  }, [userForm]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const formattedUserForm = userForm.map((el) => ({
        ...el,
        jitTagForm:
          el.jitTagForm === 'JIT-' || el.jitTagForm === 'JIT-K' ? '' : el.jitTagForm,
      }));
      const res = await updateInventory(
        isUserAdmin ? ldapLogin : user.ldapLogin,
        formattedUserForm,
        inventoryComment,
      );
      if (res.type === 'success') {
        setData(res.data);
        toast.success('Inventory form uploaded successfully');
      } else {
        toast.error(`Error: ${res.error.message}`);
      }
    } catch (e) {
      toast.error('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('atlasToken');
      setUser(guest);
      const logoutRequest = {
        account: accounts[0],
      };
      instance.logoutRedirect(logoutRequest).catch((e) => {
        console.error(e);
      });
    } catch (e) {
      toast.error('Error logging out');
    }
  };

  const handleReview = async () => {
    try {
      await updateInventoryReview(
        isUserAdmin ? ldapLogin : user.ldapLogin,
        !inventoryReviewed,
      );
      toast.success(!inventoryReviewed ? 'Inventory reviewed' : 'Inventory un-reviewed');

      setInventoryReviewed((prev) => !prev);
    } catch (e) {
      toast.error('Something went wrong while updating inventory review');
    }
  };

  const formDisabled = !isUserAdmin && inventoryReviewed;

  return (
    <div className={classes.root}>
      <PageHeader
        title="Inventory Form"
        linkText={isUserAdmin ? 'Return to inventory list' : ''}
        linkAddress={isUserAdmin ? '/inventory' : ''}
        actionButtons={
          !isUserAdmin ? (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleLogout}
            >
              LOGOUT
            </Button>
          ) : null
        }
      />
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {data.length === 0 || userForm.length === 0 ? (
            <>
              <Typography paragraph>
                {isLoading ? 'Loading data...' : 'No hardware assigned'}
              </Typography>
              {isLoading && (
                <CircularProgress size={24} className={classes.circularProgress} />
              )}
            </>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <InventoryDetailsHeader />
              <TableBody>
                {data.map((hardware, index) => {
                  const element = userForm?.find((el) => el.id === hardware.id);
                  const hardwareMatched = element?.hardwareMatched;
                  const jitTagForm = element?.jitTagForm;
                  const labelId = `enhanced-table-checkbox-${hardware.id}`;
                  const comment = element?.comment;
                  const jitTagFormValue =
                    hardware.category.name !== 'Karta dostępowa'
                      ? jitTagForm?.slice(4)
                      : jitTagForm?.slice(5);
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={hardware.id}>
                      <TableCell align="center">{hardware.category.name}</TableCell>
                      <TableCell align="center">{hardware.brand.name}</TableCell>
                      <TableCell align="center">{hardware.model}</TableCell>
                      <TableCell align="center">{hardware.serialNumber}</TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <div>Correct</div>
                          <Checkbox
                            checked={hardwareMatched === 'Matched'}
                            color="primary"
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={() => hardwareMatchingClick('Matched', hardware.id)}
                            disabled={formDisabled}
                          />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <div>Incorrect</div>
                          <Checkbox
                            checked={hardwareMatched === 'Unmatched'}
                            color="primary"
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={() =>
                              hardwareMatchingClick('Unmatched', hardware.id)
                            }
                            disabled={formDisabled}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <div style={{ minWidth: '40px' }}>
                            {hardware.category.name !== 'Karta dostępowa'
                              ? 'JIT-'
                              : 'JIT-K'}
                          </div>
                          <TextField
                            disabled={formDisabled}
                            error={
                              isUserAdmin &&
                              hardware.inventoryJitTag !== '' &&
                              hardware.inventoryJitTag !== hardware.jitTag
                            }
                            id="standard-basic"
                            name={`tag-${labelId}`}
                            value={jitTagFormValue}
                            onChange={(e) =>
                              jitTagChange(
                                e.target.value,
                                hardware.id,
                                hardware.category.name,
                              )
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" justifyContent="center">
                          <TextField
                            id="standard-basic"
                            name={`comment-${labelId}`}
                            value={comment}
                            required={hardwareMatched === 'Unmatched'}
                            onChange={(e) => commentChange(e.target.value, hardware.id)}
                            error={jitTagFormValue === '' && comment === ''}
                            disabled={formDisabled}
                          />
                          <Tooltip
                            title={
                              hardwareMatched === 'Unmatched'
                                ? 'the comment field must be filled in because you checked the "incorrect" checkbox'
                                : ''
                            }
                          >
                            <InfoIcon
                              color="error"
                              className={
                                hardwareMatched === 'Unmatched'
                                  ? classes.showIcon
                                  : classes.hideIcon
                              }
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
      <Paper className={classes.commentPaper}>
        <TextField
          className={classes.commentTextField}
          value={inventoryComment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="General inventory comment"
          disabled={formDisabled}
        />
      </Paper>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center" justifyContent="center">
          {isUserAdmin && (
            <>
              <div>Reviewed</div>
              <Checkbox
                checked={inventoryReviewed}
                color="primary"
                onClick={handleReview}
                disabled={isLoading}
              />
            </>
          )}
          {!isUserAdmin && formDisabled && (
            <Typography>
              Your inventory has been reviewed by admins and is disabled until next
              inventory
            </Typography>
          )}
        </Box>
        {submitting ? (
          <CircularProgress size={24} className={classes.circularProgress} />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          disabled={saveDisabled || submitting || isLoading || formDisabled}
          size="small"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default InventoryDetails;
