import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from './fetchWrapper';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const LocationsV = t.array(
  t.type({ name: nullableString, id: nullableNumberToZero }),
);

export type LocationsDto = { locations: t.TypeOf<typeof LocationsV> };

export function fetchLocations() {
  return fetchWrapper({
    url: '/api/location',
    dataType: LocationsV,
  });
}
