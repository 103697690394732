import { useEffect } from 'react';
import { TableStateColumn } from '../components/LazyList/LazyListHelpers';
import {
  FiltersState,
  ListKey,
  useFiltersAndSortDispatch,
  useFiltersAndSortState,
} from '../context/FiltersAndSortDataProvider';

export const useFiltersStateFromContext = (
  listKey: ListKey,
  InitialFilters: FiltersState,
) => {
  const filtersAndSort = useFiltersAndSortState(listKey);
  const setContextFilters = useFiltersAndSortDispatch();

  useEffect(() => {
    if (filtersAndSort) {
      setContextFilters(listKey, filtersAndSort);
    } else {
      setContextFilters(listKey, InitialFilters);
    }
  }, [listKey]);

  const contextEnchancedOnFiltersChange = (filters: any) => {
    if (!filtersAndSort) {
      return;
    }
    setContextFilters(listKey, {
      ...filtersAndSort,
      activeFilters: filters,
    });
  };

  const contextEnchancedOnSortChange = (selectedColumn: TableStateColumn) => {
    if (!filtersAndSort) {
      return;
    }
    setContextFilters(listKey, {
      ...filtersAndSort,
      activeSort: {
        columnName: selectedColumn.name,
        sortDirection: selectedColumn.sortDirection,
      },
    });
  };

  return {
    displayedFilters: filtersAndSort || InitialFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  };
};
