import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';

const nullableString = fromNullable(t.string, '');

export const AuthUserV = t.type({
  name: t.string,
  ldapLogin: t.string,
  roles: t.array(t.string),
  id: nullableString,
});

export function getAuthUser(token: string | null) {
  return fetch('/api/me', {
    method: 'GET',
    headers: {
      credentials: 'include',
      Authorization: `Bearer ${token}`,
    },
  });
}
