import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../fetchWrapper';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const BrandsV = t.array(
  t.type({ name: nullableString, id: nullableNumberToZero }),
);

export type BrandsDto = { brands: t.TypeOf<typeof BrandsV> };

export const CategoriesV = t.array(
  t.type({ name: nullableString, id: nullableNumberToZero }),
);

export type CategoriesDto = { categories: t.TypeOf<typeof CategoriesV> };

export const OrganizationsV = t.type({
  organizations: t.array(t.type({ name: nullableString, id: nullableNumberToZero })),
});

export type OrganizationsDto = t.TypeOf<typeof OrganizationsV>;

export const TypesV = t.array(t.type({ name: nullableString, id: nullableNumberToZero }));

export type TypesDto = { types: t.TypeOf<typeof TypesV> };

export const SuppliersV = t.array(
  t.type({ name: nullableString, id: nullableNumberToZero }),
);

export type SuppliersDto = { suppliers: t.TypeOf<typeof SuppliersV> };

export const LocationsV = t.array(
  t.type({ name: nullableString, id: nullableNumberToZero }),
);

export type LocationsDto = { locations: t.TypeOf<typeof LocationsV> };

export const AttributeV = t.type({
  id: nullableNumberToZero,
  name: nullableString,
  hardwareId: t.array(nullableNumberToZero),
  values: t.array(nullableString),
});
export const AttributesV = t.type({
  hardwareAttributeDto: t.array(AttributeV),
});

export type AttributesDto = t.TypeOf<typeof AttributesV>['hardwareAttributeDto'];

export type AttributesDtoList = {
  attributes: t.TypeOf<typeof AttributesV>['hardwareAttributeDto'];
};

export function fetchBrands() {
  return fetchWrapper({ url: '/api/hardware/brand', dataType: BrandsV });
}

export function fetchCategories() {
  return fetchWrapper({ url: '/api/hardware/category', dataType: CategoriesV });
}

export function fetchOrganizations() {
  return fetchWrapper({ url: '/api/organization', dataType: OrganizationsV });
}

export function fetchTypes() {
  return fetchWrapper({ url: '/api/hardware/type', dataType: TypesV });
}

export function fetchSuppliers() {
  return fetchWrapper({ url: '/api/supplier', dataType: SuppliersV });
}

export function fetchLocations() {
  return fetchWrapper({ url: '/api/location', dataType: LocationsV });
}

export function fetchAttributes() {
  return fetchWrapper({ url: '/api/hardware/attribute', dataType: AttributesV });
}
