import { createDataResponse, createErrorResponse } from '../fetchWrapper';

type HardwareDisposalQueryObject = {
    date: string;
};

export async function getHardwareDisposalReport(queryObject: HardwareDisposalQueryObject) {
    const searchParams = new URLSearchParams();

    if (queryObject.date) {
        searchParams.append('Date', queryObject.date);
    }

    try {
        const res = await fetch(`/api/report/hardware-disposal?${searchParams}`, {
            method: 'POST',
            headers: {
                accept: 'text/plain',
            },
        });
        if (!res.ok) {
            throw new Error('There are no disposal data for the given range of dates.');
        }
        const blob = await res.blob();
        return createDataResponse(blob);
    } catch (err) {
        return createErrorResponse("couldn't download report");
    }
}
