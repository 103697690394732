import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  AttributesDto,
  fetchAttributes,
} from '../services/fetchServices/HardwareApi/fetchHardwareMetadata';
import { compare } from './useHardwareMetaData';

export const useHardwareAttributes = () => {
  const [data, setData] = useState<AttributesDto>([]);

  useEffect(() => {
    const f = async () => {
      const res = await fetchAttributes();
      if (res.type === 'success') {
        setData(res.data.hardwareAttributeDto.sort(compare));
      } else {
        toast.error('Fetching  hardware attributes failed');
      }
    };
    f();
  }, []);
  return data;
};
