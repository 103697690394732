import { toast } from 'react-toastify';
import { addLocation, AddLocationFormValue } from './addLocation';

export const addLocationDtoInitialValues: AddLocationFormValue = {
  name: '',
};

export const useAddLocationData = () => {
  const initialValues = addLocationDtoInitialValues;

  async function submitAddLocation(values: AddLocationFormValue) {
    const res = await addLocation(values);
    if (res.type === 'success') {
      toast.success('Successfully added location');
      return res.data;
    }
    toast.error(res.error.message);

    return null;
  }

  return { submitAddLocation, initialValues };
};
