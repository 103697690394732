import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { defaultOnDownload } from '../../components/LazyList/LazyListHelpers';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { User } from '../../services/domainServices/UserService';
import { getUserReport } from '../../services/fetchServices/UserApi/getUserReport';
import { getUsers } from '../../services/fetchServices/UserApi/getUsers';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumnDisplay,
  NUMBER_OF_USERS_FETCHED,
} from './userListHelpers';

export interface UserListState {
  totalUsers: number;
  filteredUsers: number;
  loadedUsers: User[];
  numberOfRequestedUsers: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
}

const useUserListData = () => {
  const [state, setState] = useState<UserListState>({
    totalUsers: 0,
    filteredUsers: 0,
    loadedUsers: [],
    numberOfRequestedUsers: 0,
    availableFilters: {
      ...EmptyFilters,
      [ColumnName.status]: ['active', 'inactive'],
    },
    searchText: '',
    isFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Users',
    ColumnName,
    InitialColumnDisplay,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext('User', {
    activeFilters: EmptyFilters,
    activeSort: { columnName: 'userID', sortDirection: 'desc' },
  });

  const { activeFilters, activeSort } = displayedFilters;

  // use ref to access newest values of state from useEffect that resets the data without firing when they change
  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  useUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      availableFilters: {
        ...prevState.availableFilters,
        [ColumnName.status]: ['active', 'inactive'],
      },
    }));
  }, []);

  const onDownload = () => {
    getUserReport({
      filters: {
        statusFilters: debouncedFilters[ColumnName.status],
      },
      sort: {
        direction: activeSort.sortDirection,
        column: activeSort.columnName,
      },
      search: debouncedSearchText,
      columns: Object.entries(displayedColumns)
        .filter((e) => e[1])
        .map((e) => e[0]),
    }).then(defaultOnDownload('UserReport.xlsx'));
    return false;
  };

  // after filters or sort change, reset table and fetch users (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await getUsers(0, NUMBER_OF_USERS_FETCHED, {
        filters: {
          statusFilters: debouncedFilters[ColumnName.status],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
      });

      if (res.type === 'error') {
        toast.error(`Loading users failed: ${res.error.message}`);
        setState((prevState) => ({ ...prevState, isFetching: false }));
      } else {
        const { users, totalRecordsAmount, filteredRecordsAmount } = res.data;
        setState((prevState) => ({
          ...prevState,
          loadedUsers: users,
          totalUsers: totalRecordsAmount,
          filteredUsers: filteredRecordsAmount,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedUsers increments, make a request for more users (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));
      const res = await getUsers(curState.loadedUsers.length, NUMBER_OF_USERS_FETCHED, {
        filters: {
          statusFilters: curState.activeFilters[ColumnName.status],
        },
        sort: {
          direction: curState.activeSort.sortDirection,
          column: curState.activeSort.columnName,
        },
        search: curState.searchText,
      });

      if (res.type === 'error') {
        toast.error(`Loading users failed: ${res.error.message}`);
        setState((prevState) => ({ ...prevState, isFetching: false }));
      } else {
        const { users, totalRecordsAmount, filteredRecordsAmount } = res.data;
        setState((prevState) => ({
          ...prevState,
          loadedUsers: [...prevState.loadedUsers, ...users],
          totalUsers: totalRecordsAmount,
          filteredUsers: filteredRecordsAmount,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedUsers]);
  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    onDownload,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  };
};

export default useUserListData;
