/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Chip, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AddUserFormData } from '../../services/fetchServices/UserApi/addUser';

export const initialValues: AddUserFormData = {
  oid: '',
  lastName: '',
  active: true,
  email: '',
  ldapLogin: '',
  firstName: '',
  comment: '',
  phone: '+48 ', // companyPhone
  privatePhone: '+48 ',
  countryCompanyPhone: 'Poland (Polska)',
  countryPrivatePhone: 'Poland (Polska)',
  privateEmail: '',
};

export function validateAddUserData(values: AddUserFormData) {
  const errors: Partial<AddUserFormData> = {};
  if (!values.firstName) {
    errors.firstName = 'required';
  }
  if (!values.lastName) {
    errors.lastName = 'required';
  }
  if (!values.ldapLogin) {
    errors.ldapLogin = 'required';
  }
  return errors;
}

export const canAutofill = (values: { firstName: string; lastName: string }) =>
  values.firstName && values.lastName;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      marginTop: '1.2em',
      marginBottom: '1.2em',
      height: 'auto',
      minWidth: 1000,
    },
    returnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
    pad: {
      width: 170,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 205,
      marginTop: '16px',
      marginBottom: '8px',
    },
    ldapField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(3),
      width: 320,
      marginTop: '16px',
      marginBottom: '8px',
    },
    commentField: {
      width: 430,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      width: 430,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    formDirection: {
      paddingRight: '5%',
      paddingLeft: '5%',
      paddingBottom: '3%',
      paddingTop: '3%',
    },
    switch: {
      paddingTop: '5%',
    },
    disabled: {
      color: '#b1b1b1',
    },
    privatePhone: {
      fontSize: 'inherit',
      WebkitTextStroke: 'thin',
      marginTop: '2%',
      borderRadius: '30px',
      paddingLeft: 0,
      top: 0,
    },
    button: {
      fontSize: '16px',
      textTransform: 'none',
    },
  }),
);

export function replacePolishSigns(str: string) {
  return str
    .replace(/ą/g, 'a')
    .replace(/ć/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ł/g, 'l')
    .replace(/ń/g, 'n')
    .replace(/ó/g, 'o')
    .replace(/ś/g, 's')
    .replace(/ź/g, 'z')
    .replace(/ż/g, 'z');
}

export const renderChips = <T extends {}>(
  setChips: (chips: T[]) => void,
  disabled?: boolean,
  extractLabel: (value: T) => string = (v) => v as any,
) =>
  function (selected: T[]) {
    const handleDelete = (chipToDelete: T) => () => {
      setChips(selected.filter((chip) => chip !== chipToDelete));
    };

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {selected.map((value) => (
          <Chip
            key={extractLabel(value)}
            onDelete={disabled ? undefined : handleDelete(value)}
            label={extractLabel(value)}
            style={{
              margin: 2,
              color: disabled ? '#a6a6a6' : '',
              backgroundColor: disabled ? '#e0e0e0' : '',
            }}
          />
        ))}
      </div>
    );
  };
