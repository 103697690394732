import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const protocolV = t.type({
  id: nullableNumberToZero,
  filePath: nullableString,
  ldapLogin: nullableString,
  firstName: nullableString,
  lastName: nullableString,
  date: DateFromISOString,
  comment: nullableString,
  protocolType: nullableString,
});

export type Protocol = t.TypeOf<typeof protocolV>;

export const emptyProtocol: Protocol = {
  id: 0,
  filePath: '',
  ldapLogin: '',
  firstName: '',
  lastName: '',
  date: new Date(),
  comment: '',
  protocolType: '',
};
