import { toast } from 'react-toastify';
import { UpdateAttribute, updateAttribute } from './updateAttribute';

export const useUpdateAttributeData = () => {
  async function submitUpdateAttribute(id: number, values: UpdateAttribute) {
    const res = await updateAttribute(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated attribute');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitUpdateAttribute };
};
