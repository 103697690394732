import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const AddLocationV = t.type({
  name: nullableString,
});

export type AddLocationFormValue = t.TypeOf<typeof AddLocationV>;

export function addLocation(location: AddLocationFormValue) {
  return fetchWrapper({
    url: '/api/location',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: location,
    },
  });
}
