import { fetchWrapper } from '../fetchWrapper';

export async function unassignHardwareFromOffice(id: string, hardwareIds: number[]) {
  return fetchWrapper({
    url: `api/office/${id}/hardware/unassignment`,
    fetchArg: {
      method: 'PUT',
      headers: {
        accept: 'text/plain',
        'Content-Type': 'application/json-patch+json',
      },
      body: { hardwareIds },
    },
  });
}
