import {
  notificationFormData,
  NotificationWithUserData,
} from '../../services/domainServices/NotificationService';

export function validateAddUserData(values: notificationFormData) {
  const errors: Partial<Record<keyof NotificationWithUserData, string>> = {};
  if (!values.firstName) {
    errors.firstName = 'required';
  }
  if (!values.lastName) {
    errors.lastName = 'required';
  }
  if (!values.ldapLogin) {
    errors.ldapLogin = 'required';
  }
  if (values.status !== 'Pending' && !values.email) {
    errors.email = 'required';
  }
  if (!values.status) {
    errors.status = 'select status';
  }
  return errors;
}
