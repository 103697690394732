import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export type UpdateCategory = {
  name: string;
};

export const emptyCategory: UpdateCategory = {
  name: '',
};

export function updateCategory(id: number, category: UpdateCategory) {
  return fetchWrapper({
    url: `/api/hardware/category/${id}`,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: category,
    },
  });
}
