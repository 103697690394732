import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getLicenseHistory,
  LicenseHistory,
} from '../../../services/fetchServices/LicensesApi/getLicenseHistory';

export const useLicenseHistoryData = (id: number) => {
  const [historyData, setHistoryData] = useState<LicenseHistory>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const licenseHistory = await getLicenseHistory(id);
      if (licenseHistory.type === 'success') {
        setHistoryData(licenseHistory.data);
      } else {
        toast.error(licenseHistory.error.message);
      }
      setIsLoading(false);
    };
    if (id !== 0) {
      fetchData();
    }
  }, [id]);

  return {
    historyData,
    setHistoryData,
    isLoading,
    setIsLoading,
  };
};
