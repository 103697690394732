import {
  NotificationForUpdateDto,
  NotificationV,
} from '../../domainServices/NotificationService';
import { fetchWrapper } from '../fetchWrapper';

export function updateNotification(id: string, notification: NotificationForUpdateDto) {
  return fetchWrapper({
    url: `/api/notification/${id}`,
    dataType: NotificationV,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: notification,
    },
  });
}
