import { createDataResponse, createErrorResponse } from '../fetchWrapper';

export async function downloadSingleProtocol(id: number) {
  try {
    const res = await fetch(`/api/hardware/protocol/${id}`, {
      method: 'GET',
      headers: {
        accept: 'text/plain',
        'Content-Type': 'application/json-patch+json',
      },
    });
    if (!res.ok) {
      const data = await res.json();
      return createErrorResponse(data.message);
    }
    let fileName: string = '';
    const fileNameHeader: string = res.headers.get('Content-Disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(fileNameHeader);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    const blob = await res.blob();
    return createDataResponse({ protocol: blob, name: fileName });
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
