import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const officeV = t.type({
  id: nullableNumberToZero,
  name: nullableString,
  location: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  organization: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  comment: nullableString,
});

export type Office = t.TypeOf<typeof officeV>;

export const emptyOffice: Office = {
  id: 0,
  name: '',
  location: {
    id: 0,
    name: '',
  },
  organization: {
    id: 0,
    name: '',
  },
  comment: '',
};
