import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { inventoryUserV } from '../../domainServices/UserService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetInventoryUsersDtoV = t.type({
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
  users: t.array(inventoryUserV),
});

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

const columnNamesMap: Record<string, string> = {
  name: 'FirstName',
  surname: 'LastName',
  userID: 'Oid',
  inventoryComment: 'InventoryComment',
};

const tagsMap: Record<string, string> = {
  'tags-matched': 'Matched',
  'tags-unmatched': 'Unmatched',
  'tags-unchecked': 'Unchecked',
};

const inventoryMap: Record<string, string> = {
  'items-matched': 'Matched',
  'items-unmatched': 'Unmatched',
  'items-unchecked': 'Unchecked',
};

const booleanMap: Record<string, string> = {
  yes: 'true',
  no: 'false',
};

export function getUsersWithInventoryStatus(
  startingIndex: number,
  usersAmount: number,
  queryObject: {
    filters: {
      inventoryFilters: string[];
      tagsFilters: string[];
      commentFilters: string[];
      reviewedFilters: string[];
    };
    sort: {
      direction: string;
      column: string;
    };
    search: string;
  },
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: usersAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column ? columnNamesMap[queryObject.sort.column] : '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.inventoryFilters.forEach((inventory) =>
    searchParams.append('InventoryCompatibility', inventoryMap[inventory]),
  );

  queryObject.filters.tagsFilters.forEach((tag) =>
    searchParams.append('TagsCompatibility', tagsMap[tag]),
  );

  queryObject.filters.commentFilters.forEach((comment) =>
    searchParams.append('Comment', booleanMap[comment]),
  );

  queryObject.filters.reviewedFilters.forEach((comment) =>
    searchParams.append('Reviewed', booleanMap[comment]),
  );

  return fetchWrapper({
    url: `/api/user-inventory?${searchParams.toString()}`,
    dataType: GetInventoryUsersDtoV,
  });
}
