import React from 'react';
import { Link } from 'react-router-dom';
import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      marginTop: '1.2em',
      marginBottom: '1.2em',
      height: 'auto',
      minWidth: 1000,
    },
    returnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
    pad: {
      width: 170,
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
    secondaryButton: {
      margin: theme.spacing(1),
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
        fontSize: '16px',
      },
    },
  }),
);

type Props = {
  title: string;
  linkText?: string;
  linkAddress?: string;
  actionButtons?: React.ReactNode;
};

const PageHeader: React.FC<Props> = ({
  title,
  linkText,
  linkAddress,
  actionButtons = null,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.grid}
    >
      {linkText && linkAddress ? (
        <Link to={linkAddress} className={classes.returnContainer}>
          <ArrowBackIcon />
          <Typography variant="body1"> {linkText}</Typography>
        </Link>
      ) : (
        <div style={{ width: 150 }} />
      )}
      <Typography variant="h4">{title}</Typography>
      {actionButtons ? <Box>{actionButtons}</Box> : <div style={{ width: 170 }} />}
    </Grid>
  );
};

export default PageHeader;
