import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { protocolDefaultOnDownload } from '../../../components/LazyList/LazyListHelpers';
import {
  createDataResponse,
  createErrorResponse,
} from '../../../services/fetchServices/fetchWrapper';
import {
  getUserHardware,
  HardwareArrayType,
} from '../../../services/fetchServices/HardwareApi/getUserHardware';
import { getAssignProtocol } from '../../../services/fetchServices/UserApi/getAssignProtocol';
import { getUnassignProtocol } from '../../../services/fetchServices/UserApi/getUnassignProtocol';

export const useUserHardwareData = (ldapLogin: string) => {
  const [data, setData] = useState<HardwareArrayType>([]);
  const [isLoading, setIsLoading] = useState(false);

  const idsAndDatesRecord = (hardwareIds: number[]) =>
    hardwareIds.reduce(
      (a: { [name: string]: Date }, b: any) => ({
        ...a,
        [b]: new Date(),
      }),
      {},
    );

  const refetchAssignedHardware = useCallback(async () => {
    setIsLoading(true);
    const userHardware = await getUserHardware(ldapLogin);
    if (userHardware.type === 'success') {
      setData(userHardware.data);
    } else {
      toast.error(userHardware.error.message);
    }
    setIsLoading(false);
  }, [ldapLogin]);

  useEffect(() => {
    refetchAssignedHardware();
  }, [refetchAssignedHardware]);

  async function submitUnassignHardware(
    hardwareIds: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) {
    const res = await getUnassignProtocol(
      ldapLogin,
      new Date(),
      idsAndDatesRecord(hardwareIds),
      sendPrivateEmail,
      comment,
    );

    if (res.type === 'success') {
      await refetchAssignedHardware();
      toast.success('Successfully unassigned hardware');
      try {
        protocolDefaultOnDownload(res.data.name)(res.data.protocol);
      } catch (err) {
        toast.error('Error downloading protocol');
      }
      return createDataResponse({});
    }
    toast.error(`Failed to unassign hardware: ${res.error.message}`);
    return createErrorResponse("Couldn't unassign hardware");
  }

  async function submitAssignHardware(
    hardwareIds: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) {
    const res = await getAssignProtocol(
      ldapLogin,
      new Date(),
      idsAndDatesRecord(hardwareIds),
      sendPrivateEmail,
      comment,
    );
    if (res.type === 'success') {
      await refetchAssignedHardware();
      try {
        protocolDefaultOnDownload(res.data.name)(res.data.protocol);
      } catch (err) {
        toast.error("Couldn't download protocol");
      }
      return createDataResponse({});
    }
    toast.error(`Failed to assign hardware: ${res.error.message}`);
    return createErrorResponse("Couldn't assign hardware");
  }

  return {
    data,
    isLoading,
    setIsLoading,
    submitAssignHardware,
    submitUnassignHardware,
    refetchAssignedHardware,
  };
};
