import * as t from 'io-ts';
import { HardwareWithHardwareChildrenV } from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

export const hardwareArrayV = t.array(HardwareWithHardwareChildrenV);
export type HardwareArrayType = t.TypeOf<typeof hardwareArrayV>;

export function getInventoryUserHardware(ldapLogin: string) {
  return fetchWrapper({
    url: `/api/user/${ldapLogin}/inventory-hardware`,
    dataType: hardwareArrayV,
  });
}
