import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  fetchOrganizations,
  OrganizationsDto,
} from '../../services/fetchServices/HardwareApi/fetchHardwareMetadata';

type LicensesMetaData = OrganizationsDto;

export function compare(
  a: { name: string; id: number },
  b: { name: string; id: number },
) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export const useLicensesMetaData = () => {
  const [data, setData] = useState<LicensesMetaData>({
    organizations: [],
  });

  useEffect(() => {
    const f = async () => {
      const responses = await Promise.all([fetchOrganizations()]);
      if (responses[0].type === 'success') {
        setData({
          organizations: responses[0].data.organizations.sort(compare),
        });
      } else {
        toast.error('Fetching licenses metadata failed');
      }
    };
    f();
  }, []);
  return data;
};
