import { toast } from 'react-toastify';
import { UpdateSupplier, updateSupplier } from './updateSupplier';

export const useUpdateSupplierData = () => {
  async function submitUpdateSupplier(id: number, values: UpdateSupplier) {
    const res = await updateSupplier(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated supplier');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitUpdateSupplier };
};
