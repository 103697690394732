/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Button, Chip, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ColumnName } from '../../pages/HardwareList/HardwareListHelpers';
import HardwareListTable from '../../pages/HardwareList/HardwareListTable';
import { useStyles } from '../LazyList/LazyListHelpers';
import AddCommentForm from './AddCommentForm';
import { FreeHardwareFilters } from './assignHardwareHelpers';
import CommentDialog from './CommentDialog';

type AssignHardwareProps = {
  assignHardware: (
    ids: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => Promise<void>;
  setOpen: (value: boolean) => void;
};

export const AssignHardwareDialogContent = ({
  assignHardware,
  setOpen,
}: AssignHardwareProps) => {
  const lazyListClasses = useStyles();
  const [hardwareToAssign, setHardwareToAssign] = React.useState<
    {
      id: number;
      jitTag: string;
      category: string;
      brand: string;
      model: string;
    }[]
  >([]);

  const selectHardwareCallback = (
    id: number,
    jitTag: string,
    category: string,
    brand: string,
    model: string,
  ) => {
    setHardwareToAssign((prev) =>
      prev.map((h) => h.id).includes(id)
        ? prev
        : [...prev, { id, jitTag, category, brand, model }],
    );
  };

  const [state, setState] = React.useState({
    sendPrivateEmail: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [comment, setComment] = useState('');
  const [openComment, setOpenComment] = useState(false);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Typography>Selected hardware: </Typography>
            </Grid>
            {hardwareToAssign.map(({ id, jitTag, category, brand, model }) => (
              <Grid item>
                <Chip
                  key={jitTag}
                  label={`${category} ${brand} ${model}, ${jitTag}`}
                  onDelete={() => {
                    setHardwareToAssign((prev) => prev.filter((h) => h.id !== id));
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid style={{ marginLeft: 'auto' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.sendPrivateEmail}
                onChange={handleChange}
                name="sendPrivateEmail"
                color="primary"
              />
            }
            label="Send to private mail"
          />
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '8px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setOpenComment(true)}
          style={{ padding: '4px 40px', marginRight: '32px' }}
        >
          Comment
        </Button>
        <CommentDialog
          onClose={() => {
            setOpenComment(false);
          }}
          open={openComment}
        >
          <AddCommentForm
            comment={comment}
            setComment={setComment}
            setOpenComment={setOpenComment}
          />
        </CommentDialog>
        <Tooltip title="assign hardware">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '8px' }}
            disabled={!hardwareToAssign.length}
            onClick={() => {
              assignHardware(
                hardwareToAssign.map((h) => h.id),
                state.sendPrivateEmail,
                comment,
              );
              setOpen(false);
              setHardwareToAssign([]);
            }}
          >
            Assign
          </Button>
        </Tooltip>
      </Grid>
      <HardwareListTable
        initialFilters={FreeHardwareFilters}
        actionComponents={(rowData: string[]) => [
          <Tooltip title="select hardware for assignment">
            {hardwareToAssign
              .map((h) => h.id)
              .includes(Number(rowData[ColumnName.id])) ? (
              <IconButton
                onClick={() => {
                  setHardwareToAssign((prev) =>
                    prev.filter((h) => h.id !== Number(rowData[ColumnName.id])),
                  );
                }}
                className={lazyListClasses.showDetailsIconButton}
              >
                <RemoveIcon
                  fontSize="inherit"
                  className={lazyListClasses.showDetailsIcon}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={() =>
                  selectHardwareCallback(
                    Number(rowData[ColumnName.id]),
                    rowData[ColumnName.JitTag],
                    rowData[ColumnName.Category],
                    rowData[ColumnName.Brand],
                    rowData[ColumnName.Model],
                  )
                }
                className={lazyListClasses.showDetailsIconButton}
              >
                <AddIcon fontSize="inherit" className={lazyListClasses.showDetailsIcon} />
              </IconButton>
            )}
          </Tooltip>,
        ]}
      />
    </>
  );
};
