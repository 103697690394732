import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type ProviderProps = { children: ReactNode };

export type UserState = {
  name: string;
  roles: string[];
  ldapLogin: string;
  id: string;
};

export const guest: UserState = {
  name: 'guest',
  roles: ['anonymous'],
  ldapLogin: 'guest.guest',
  id: 'guest',
};

const UserStateContext = createContext<UserState | undefined>(undefined);
const UserDispatchContext = createContext<((user: UserState) => void) | undefined>(
  undefined,
);

const UserDataProvider = ({ children }: ProviderProps) => {
  const userFromLocalStorage = localStorage.getItem('atlasUserData');
  let currentUser = guest;
  try {
    currentUser = userFromLocalStorage
      ? (JSON.parse(userFromLocalStorage) as UserState)
      : guest;
  } catch (e) {
    console.error(e);
  }

  const [state, setState] = useState<UserState>(currentUser);
  const saveUser = useCallback((user: UserState) => {
    localStorage.setItem('atlasUserData', JSON.stringify(user));
    setState(user);
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={saveUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserDataProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserDataProvider');
  }
  return context;
}

export default UserDataProvider;
export { useUserState, useUserDispatch };
