import { toast } from 'react-toastify';
import { deleteCategory } from './deleteCategory';

export const useDeleteCategoryData = () => {
  async function submitDeleteCategory(id: number) {
    const res = await deleteCategory(id);
    if (res.type === 'success') {
      toast.success('Successfully deleted category');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitDeleteCategory };
};
