import React, { useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

interface OptionType {
  label: string;
}

function renderInputComponent(inputProps: any) {
  const {
    classes,
    inputRef = () => {
      // initialState
    },
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(
  suggestion: OptionType,
  { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part) => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(suggestions: string[], value: string) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion: OptionType) {
  return suggestion.label;
}

const useStylesAutosuggest = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 350,
      marginLeft: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
    valueInput: {
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
  }),
);

type Props = {
  defaultValue: string;
  disabled: boolean;
  suggestions: string[];
  onChange: ({ newValue }: { newValue: string }) => void;
  errorMessage?: string;
};

const AutosuggestField = ({
  defaultValue,
  disabled,
  suggestions,
  onChange,
  errorMessage,
}: Props) => {
  const classes = useStylesAutosuggest();
  const [value, setValue] = React.useState<string>(defaultValue);
  const [stateSuggestions, setSuggestions] = React.useState<OptionType[]>([]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSuggestionsFetchRequested = ({ value: newValue }: any) => {
    setSuggestions(getSuggestions(suggestions, newValue).map((label) => ({ label })));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={
          {
            classes: classes.valueInput,
            id: 'react-autosuggest-simple',
            label: 'value',
            disabled,
            value,
            onChange: (event: any, { newValue }: { newValue: string }) => {
              setValue(newValue);
              onChange({ newValue });
            },
            error: errorMessage !== undefined,
            helperText: errorMessage,
          } as any
        }
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
};

export default AutosuggestField;
