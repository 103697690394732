import React from 'react';
import PageHeader from '../../components/PageHeader';

const LicenseDetailsHeader = () => (
  <PageHeader
    title="License Details"
    linkText="Return to licenses list"
    linkAddress="/licenses"
  />
);

export default LicenseDetailsHeader;
