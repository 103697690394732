import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { UserState } from '../../context/UserDataProvider';
import { isAdmin } from '../../utils/isAdmin';

export type FetchError = {
  message: string;
};

export type ErrorResponse = {
  type: 'error';
  error: FetchError;
};

export type DataResponse<T> = {
  type: 'success';
  data: T;
};

export type FetchResponse<T> = DataResponse<T> | ErrorResponse;

const allowedUrlsToEdit = ['/api/user-inventory'];

const createResponse = async (res: Response, dataType?: any) => {
  const contentType = res.headers.get('content-type');

  if (!contentType || contentType.indexOf('application/json') === -1) {
    if (res.ok) {
      return createDataResponse({});
    }
    return createErrorResponse(res.statusText);
  }

  const json = await res.json();

  if (!res.ok) {
    const message = json.message ? json.message : 'Something went wrong';
    const errors = json.errors && json.errors.length ? json.errors.join('\n') : '';
    return createErrorResponse(`${message}\n${errors}`);
  }

  if (!dataType) {
    return createDataResponse(json);
  }

  const decoded = dataType.decode(json);
  return pipe(
    decoded,
    fold(
      (errors) => {
        const messages = reporter(decoded);
        return createErrorResponse(messages.join('\n'));
      },
      (value) => createDataResponse(value),
    ),
  );
};

export const fetchWrapper: <T, O, I>(config: {
  url: string;
  dataType?: t.Type<T, O, I>;
  fetchArg?: any;
  isFile?: boolean;
}) => Promise<FetchResponse<T>> = async ({ url, dataType, fetchArg, isFile }) => {
  try {
    const userFromLocalStorage = localStorage.getItem('atlasUserData');
    if (userFromLocalStorage) {
      const user = JSON.parse(localStorage.getItem('atlasUserData')!) as UserState;
      if (
        fetchArg?.method &&
        fetchArg?.method !== 'GET' &&
        !isAdmin(user.roles) &&
        !allowedUrlsToEdit.some((editUrl) => url.includes(editUrl))
      ) {
        return createErrorResponse('You are not authorized to perform this action.');
      }
    }

    const token = localStorage.getItem('atlasToken')
      ? `Bearer ${localStorage.getItem('atlasToken')}`
      : null;

    const res = await fetch(
      url,
      fetchArg
        ? {
            ...fetchArg,
            body: isFile
              ? fetchArg.body
              : fetchArg.body && JSON.stringify({ ...fetchArg.body }),
            headers: {
              ...fetchArg?.headers,
              Authorization: token,
            },
          }
        : {
            headers: {
              Authorization: token,
            },
          },
    );

    if (res.status === 401) {
      localStorage.removeItem('atlasToken');
      window.location.pathname = '/login';
      return createErrorResponse('Please log in');
    }

    return createResponse(res, dataType);
  } catch (err) {
    return createErrorResponse('Something went wrong');
  }
};

export const createDataResponse = async <T>(data: T): Promise<FetchResponse<T>> => ({
  type: 'success',
  data,
});
export const createErrorResponse = async (message: string): Promise<ErrorResponse> => ({
  type: 'error',
  error: { message },
});
