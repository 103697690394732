import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import LibraryAddIconOutlined from '@material-ui/icons/LibraryAddOutlined';
import PageHeader, { useStyles } from '../../components/PageHeader';

type Props = {
  id: string;
  generateReport: () => void;
  setDocumentsDialogOpen: () => void;
};

const HardwareDetailsHeader: React.FC<Props> = ({
  id,
  generateReport,
  setDocumentsDialogOpen,
}) => {
  const classes = useStyles();
  return (
    <PageHeader
      title="Hardware Details"
      linkText="Return to hardware list"
      linkAddress="/hardware"
      actionButtons={
        <>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={setDocumentsDialogOpen}
          >
            Uploaded Documents
          </Button>
          <Tooltip title="Add similar hardware" aria-label="add-similar">
            <Button
              className={classes.secondaryButton}
              variant="contained"
              component={Link}
              to={`/add-hardware/${id}`}
            >
              <LibraryAddIconOutlined />
            </Button>
          </Tooltip>
        </>
      }
    />
  );
};

export default HardwareDetailsHeader;
