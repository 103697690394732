/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import CheckBoxHandler from '../../pages/HardwareList/CheckBoxHandler';
import { FetchResponse } from '../../services/fetchServices/fetchWrapper';
import { HardwareArrayType } from '../../services/fetchServices/HardwareApi/getUserHardware';
import { AssignHardwareTableHeader } from './AssignHardwareTableHeader';
import { AssignHardwareTableToolbar } from './AssignHardwareTableToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: '90%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    showDetailsIconButton: {
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: '#999999',
      },
    },
    showDetailsIcon: {
      color: 'grey',
      '&:hover': {
        color: 'white',
      },
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

interface HardwareChildrenProps {
  hardwareChildrenLength: number;
  classes: ClassNameMap<
    | 'table'
    | 'root'
    | 'paper'
    | 'tableWrapper'
    | 'visuallyHidden'
    | 'showDetailsIconButton'
    | 'showDetailsIcon'
    | 'expand'
    | 'expandOpen'
  >;
  checked: boolean[];
  index: number;
  handleChange: (id: number) => void;
}

const hardwareChildren = ({
  hardwareChildrenLength,
  classes,
  checked,
  index,
  handleChange,
}: HardwareChildrenProps) => {
  const { expand, expandOpen } = classes;
  if (hardwareChildrenLength <= 0) {
    return null;
  }
  return (
    <IconButton
      className={clsx(expand, {
        [expandOpen]: checked[index],
      })}
      onClick={() => handleChange(index)}
    >
      <Tooltip
        title={`this parent has ${hardwareChildrenLength} ${
          hardwareChildrenLength === 1 ? 'child' : 'children'
        } below`}
      >
        <ExpandMoreIcon fontSize="small" style={{ color: 'grey' }} />
      </Tooltip>
    </IconButton>
  );
};

type Props = {
  data: HardwareArrayType;
  submitUnassignHardware: (
    hardwareIds: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => Promise<FetchResponse<{}>>;
  submitAssignHardware: (
    hardwareIds: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => Promise<FetchResponse<{}>>;
};

const AssignHardwareList = ({
  data,
  submitUnassignHardware,
  submitAssignHardware,
}: Props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<number[]>([]);
  const [checked, setChecked] = React.useState<boolean[]>([]);
  const [unassignMode, setUnassignMode] = React.useState(false);

  const navigate = useNavigate();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(data.map((hardware) => hardware.id));
    } else {
      setSelected([]);
    }
  };

  const handleChange = (id: number) => {
    setChecked((prev) => {
      const newState = [...prev];
      newState[id] = !newState[id];
      return newState;
    });
  };

  const selectHardwareClick = (id: number) => {
    if (!selected.includes(id)) {
      setSelected((prevSelected) => [...prevSelected, id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((item) => item !== id));
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AssignHardwareTableToolbar
          numSelected={selected.length}
          assignHardware={submitAssignHardware}
          unassignHardware={async (sendPrivateEmail: boolean, comment: string) => {
            if (selected.length === 0) {
              return;
            }
            setSelected([]);
            await submitUnassignHardware(selected, sendPrivateEmail, comment);
          }}
          unassignMode={unassignMode}
          setUnassignMode={setUnassignMode}
          data={data}
        />
        <div className={classes.tableWrapper}>
          {data.length === 0 ? (
            <Typography paragraph>No hardware assigned</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <AssignHardwareTableHeader
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={data.length}
                unassignMode={unassignMode}
              />
              <TableBody>
                {data.map((hardware, index) => {
                  const isItemSelected = isSelected(hardware.id);
                  const labelId = `enhanced-table-checkbox-${hardware.id}`;

                  return (
                    <>
                      <TableRow
                        hover={unassignMode}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={hardware.id}
                      >
                        <TableCell padding="checkbox">
                          {unassignMode ? (
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={() => selectHardwareClick(hardware.id)}
                            />
                          ) : (
                            hardwareChildren({
                              hardwareChildrenLength: hardware.hardwareChildren.length,
                              classes,
                              checked,
                              index,
                              handleChange,
                            })
                          )}
                        </TableCell>
                        <TableCell id={labelId} align="left">
                          {hardware.type.name}
                        </TableCell>
                        <TableCell align="left">{hardware.category.name}</TableCell>
                        <TableCell align="left">{hardware.brand.name}</TableCell>
                        <TableCell align="left">{hardware.model}</TableCell>
                        <TableCell align="left">{hardware.jitTag}</TableCell>
                        <TableCell align="left">{hardware.serialNumber}</TableCell>
                        <TableCell align="left">{hardware.valueInUse} PLN</TableCell>
                        <TableCell align="left">
                          <CheckBoxHandler hardware={hardware} disabled={!unassignMode} />
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="show details">
                            <Link to={`/hardware/${hardware.id}`}>
                              <IconButton
                                onClick={() => navigate(`/hardware/${hardware.id}`)}
                                className={classes.showDetailsIconButton}
                              >
                                <MoreHorizIcon
                                  fontSize="inherit"
                                  className={classes.showDetailsIcon}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                      {checked[index] &&
                        hardware.hardwareChildren.map((hc) => (
                          <TableRow
                            selected={false}
                            style={{ backgroundColor: '#F9F9F9' }}
                          >
                            <TableCell />
                            <TableCell id={labelId} align="left">
                              <Collapse in={checked[index]}>{hc.type.name}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.category.name}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.brand.name}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.model}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.jitTag}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.serialNumber}</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>{hc.valueInUse} PLN</Collapse>
                            </TableCell>
                            <TableCell align="left">
                              <Collapse in={checked[index]}>
                                <Tooltip title="show details">
                                  <Link to={`/hardware/${hc.id}`}>
                                    <IconButton
                                      onClick={() => navigate(`/hardware/${hc.id}`)}
                                      className={classes.showDetailsIconButton}
                                    >
                                      <MoreHorizIcon
                                        fontSize="inherit"
                                        className={classes.showDetailsIcon}
                                      />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default AssignHardwareList;
