import React from 'react';
import InputMask from 'react-input-mask';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { Field, FieldProps, FormikProps } from 'formik';
import { Switch } from 'formik-material-ui';
import { useDialogTimeOut } from '../../hooks/useDialogTimeOut';
import { notificationFormData } from '../../services/domainServices/NotificationService';
import { AddUserFormData } from '../../services/fetchServices/UserApi/addUser';
import DialogWithClose from '../DialogWithClose';
import { canAutofill, replacePolishSigns, useStyles } from './AddUserHelpers';
import { polishMask } from './consts';
import {
  getCountries,
  getCountryByName,
  reformatCountryPhoneMask,
  updateCompanyPhoneFieldBasedOnCountry,
  updateField,
  updatePrivatePhoneBasedOnCountry,
} from './utils';

interface AddUserFormProps {
  formikProps: FormikProps<AddUserFormData> | FormikProps<notificationFormData>;
  passOrgRequired?: boolean;
  ldapLogin?: string;
  disableFields?: boolean;
}

const AddUserForm = ({
  formikProps: { values, setFieldValue },
  passOrgRequired = true,
  ldapLogin,
  disableFields = false,
}: AddUserFormProps) => {
  const classes = useStyles();
  const closeDialogTimeout = useDialogTimeOut();
  const countries = getCountries();

  const onAutofillClick = () => {
    if (ldapLogin) {
      setFieldValue('ldapLogin', ldapLogin);
      setFieldValue('email', `${ldapLogin}@jit.team`);
    } else {
      const name = `${values.firstName.replace(/\s/g, '')}.${values.lastName.replace(
        /\s/g,
        '',
      )}`;
      const newLdapLogin = replacePolishSigns(name.toLowerCase());
      setFieldValue('ldapLogin', newLdapLogin);
      setFieldValue('email', `${newLdapLogin}@jit.team`);
    }
  };

  return (
    <Grid container direction="column">
      <Grid container item alignItems="center">
        <Field
          label="first name"
          value={values.firstName}
          inputProps={{
            name: 'firstName',
            onChange: updateField(setFieldValue),
          }}
          component={TextField}
          className={classes.textField}
          required
        />
        <Field
          label="last name"
          value={values.lastName}
          inputProps={{
            name: 'lastName',
            onChange: updateField(setFieldValue),
          }}
          component={TextField}
          className={classes.textField}
          required
        />
      </Grid>
      <Grid container item>
        <Field
          label="ldap login"
          value={values.ldapLogin}
          inputProps={{
            name: 'ldapLogin',
            onChange: updateField(setFieldValue),
          }}
          component={TextField}
          className={classes.ldapField}
        />
        <Grid item container xs={2} alignItems="center">
          <Button
            className={classes.button}
            size="small"
            variant="contained"
            onClick={() => onAutofillClick()}
            disabled={!canAutofill(values)}
          >
            Autofill
          </Button>
        </Grid>
      </Grid>
      <Field
        name="oid"
        label="oid"
        value={values.oid}
        inputProps={{ name: 'oid', onChange: updateField(setFieldValue) }}
        component={TextField}
        className={classes.ldapField}
        required
      />
      <Field
        name="email"
        type="email"
        value={values.email}
        inputProps={{
          name: 'email',
          onChange: updateField(setFieldValue),
        }}
        label="company email"
        component={TextField}
        className={classes.ldapField}
        required
      />
      {!disableFields && (
        <Field
          name="countryCompanyPhone"
          label="country"
          value={values.countryCompanyPhone}
          select
          component={TextField}
          className={classes.commentField}
          inputProps={{
            onChange: updateCompanyPhoneFieldBasedOnCountry(
              setFieldValue,
              'countryCompanyPhone',
            ),
          }}
        >
          {countries.map((country) => (
            <MenuItem value={country.name} key={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Field>
      )}
      <Field
        name="phone"
        value={values.phone}
        render={({ field }: FieldProps<any>) => {
          const country = getCountryByName(values.countryCompanyPhone);
          const maskFormat = reformatCountryPhoneMask(country.format);
          return (
            <InputMask {...field} mask={!disableFields ? maskFormat : polishMask}>
              {() => (
                <TextField
                  {...field}
                  label="company phone"
                  className={classes.commentField}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </InputMask>
          );
        }}
      />
      <Field
        label="private email"
        value={values.privateEmail}
        inputProps={{
          name: 'privateEmail',
          onChange: updateField(setFieldValue),
        }}
        component={TextField}
        id="input-privateEmail"
        className={classes.commentField}
        required
      />
      {!disableFields && (
        <Field
          name="countryPrivatePhone"
          label="country"
          value={values.countryPrivatePhone}
          select
          component={TextField}
          className={classes.commentField}
          inputProps={{
            onChange: updatePrivatePhoneBasedOnCountry(setFieldValue),
          }}
        >
          {countries.map((country) => (
            <MenuItem value={country.name} key={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Field>
      )}
      {!disableFields ? ( // add user form - not through notifications or new jiters
        <Field
          name="privatePhone"
          disabled={disableFields}
          value={values.privatePhone}
          render={({ field }: any) => {
            const country = getCountryByName(values.countryPrivatePhone);
            const maskFormat = reformatCountryPhoneMask(country.format);
            return (
              <InputMask {...field} mask={maskFormat}>
                {() => (
                  <TextField
                    {...field}
                    label="private phone"
                    className={classes.commentField}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </InputMask>
            );
          }}
        />
      ) : (
        // add user from notifications or new jiters
        <Grid>
          <Tooltip title="Show private phone number" placement="right">
            <IconButton
              className={classes.privatePhone}
              onClick={() => closeDialogTimeout.setPrivatePhoneDialogOpen(true)}
            >
              <PhoneAndroidIcon />
              private phone
            </IconButton>
          </Tooltip>
          <DialogWithClose
            onClose={() => closeDialogTimeout.setPrivatePhoneDialogOpen(false)}
            open={closeDialogTimeout.privatePhoneDialogOpen}
            titleText="Private phone number"
            maxWidth="xs"
            fullWidth
          >
            {values.privatePhone !== '' ? (
              <Field
                name="privatePhone"
                value={values.privatePhone}
                disabled
                render={({ field }: FieldProps<any>) => {
                  const country = getCountryByName(values.countryPrivatePhone);
                  const maskFormat = reformatCountryPhoneMask(country.format);
                  return (
                    <InputMask {...field} mask={maskFormat} disabled>
                      {() => (
                        <TextField
                          disabled
                          {...field}
                          label="private phone"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: Boolean(values.privatePhone),
                          }}
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            ) : (
              'There is no private phone number assigned to this user'
            )}
          </DialogWithClose>
        </Grid>
      )}
      <FormControlLabel
        control={
          <Field
            name="active"
            value={values.active}
            component={Switch}
            color="primary"
            margin="normal"
          />
        }
        label="active user"
        className={classes.switch}
      />
      <Field
        label="comment"
        value={values.comment}
        inputProps={{
          name: 'comment',
          onChange: updateField(setFieldValue),
        }}
        component={TextField}
        className={classes.commentField}
        multiline
      />
    </Grid>
  );
};

export default AddUserForm;
