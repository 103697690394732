import { createDataResponse, createErrorResponse } from '../fetchWrapper';

export async function getUserWithBusinessPhoneReport() {
  try {
    const res = await fetch('/api/report/user-with-business-phone', {
      method: 'GET',
      headers: {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
    if (!res.ok) {
      throw new Error("couldn't download report");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
