import { createDataResponse, createErrorResponse } from '../fetchWrapper';

export async function getUnassignProtocol(
  ldapLogin: string,
  protocolDate: Date,
  hardwareList: Record<number, Date>,
  sendToPrivateMail: boolean,
  comment: string,
) {
  try {
    const res = await fetch(`/api/user/${ldapLogin}/hardware/unassignment`, {
      method: 'PUT',
      headers: {
        accept: 'text/plain',
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify({
        protocolDate,
        hardwareList,
        sendToPrivateMail,
        comment,
      }),
    });
    if (!res.ok) {
      const data = await res.json();
      return createErrorResponse(data.message);
    }
    let fileName: string = '';
    const fileNameHeader: string = res.headers.get('Content-Disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(fileNameHeader);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    const blob = await res.blob();
    return createDataResponse({ protocol: blob, name: fileName });
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
