import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Footer from '../Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.background.default,
      padding: '24px',
      margin: 0,
      paddingTop: 0,
      minHeight: '98vh',
      boxSizing: 'content-box',
    },
  }),
);

type Props = { children: React.ReactNode };

const NoDrawerWrapper = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      {children}
      <Footer />
    </main>
  );
};

export default NoDrawerWrapper;
