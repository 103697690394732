import * as t from 'io-ts';
import { HardwareWithHardwareChildrenV } from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

export const hardwareArrayV = t.array(HardwareWithHardwareChildrenV);
export type HardwareArrayType = t.TypeOf<typeof hardwareArrayV>;

export function getOfficeHardware(id: string) {
  return fetchWrapper({
    url: `/api/office/${id}/hardware`,
    dataType: hardwareArrayV,
  });
}
