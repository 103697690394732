import { createDataResponse, createErrorResponse } from '../fetchWrapper';

type HardwareMonthQueryObject = {
  date: string;
};

export async function getHardwareMonthReport(queryObject: HardwareMonthQueryObject) {
  const searchParams = new URLSearchParams();

  if (queryObject.date) {
    searchParams.append('Date', queryObject.date);
  }

  try {
    const res = await fetch(`/api/report/hardware-month?${searchParams}`, {
      method: 'POST',
      headers: {
        accept: 'text/plain',
      },
    });
    if (!res.ok) {
      throw new Error('There are no assign/unassign data for the given month.');
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
