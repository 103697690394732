import React from 'react';
import { Checkbox } from '@material-ui/core';
import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import { User } from '../../services/domainServices/UserService';
import { checkboxStyle } from '../HardwareList/CheckBoxHandler';

export enum ColumnName {
  'userID',
  'name',
  'surname',
  'companyEmail',
  'companyPhone',
  'status',
  'assignValue',
  'currentValue',
  'comment',
  'ldapLogin',
  'hasHardwareWithLinux',
  'hasAdmin',
}

export interface Filters extends Record<ColumnName, string[]> {}

export const NUMBER_OF_USERS_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.userID]: [],
  [ColumnName.name]: [],
  [ColumnName.surname]: [],
  [ColumnName.companyEmail]: [],
  [ColumnName.companyPhone]: [],
  [ColumnName.status]: ['active'],
  [ColumnName.assignValue]: [],
  [ColumnName.currentValue]: [],
  [ColumnName.comment]: [],
  [ColumnName.ldapLogin]: [],
  [ColumnName.hasHardwareWithLinux]: [],
  [ColumnName.hasAdmin]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: true,
  [ColumnName[2]]: true,
  [ColumnName[3]]: true,
  [ColumnName[4]]: false,
  [ColumnName[5]]: true,
  [ColumnName[6]]: false,
  [ColumnName[7]]: false,
  [ColumnName[8]]: false,
  [ColumnName[10]]: false,
  [ColumnName[11]]: false,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    {
      name: ColumnName[0],
      label: 'oid number',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[0]),
      },
      widthUnits: 0.7,
    },
    {
      name: ColumnName[1],
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
        filter: false,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.name],
        customFilterListRender: (v: string) => `Name: ${v}`,
      },
    },
    {
      name: ColumnName[2],
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[2]),
        filter: false,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.surname],
        customFilterListRender: (v: string) => `Surname: ${v}`,
      },
    },
    {
      name: ColumnName[3],
      label: 'company email',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
      widthUnits: 1.2,
    },
    {
      name: ColumnName[4],
      label: 'company phone',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        filter: false,
        sort: false,
      },
      widthUnits: 0.7,
    },
    {
      name: ColumnName[5],
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        sort: false,
        filter: true,
        filterList: activeFilters[ColumnName.status],
        filterOptions: { names: availableFilters[ColumnName.status] },
      },
      widthUnits: 0.7,
    },
    {
      name: ColumnName[6],
      label: 'assigned value',
      options: {
        display: displayedColumns[ColumnName[6]],
        filter: false,
        sort: false,
      },
      widthUnits: 0.5,
    },
    {
      name: ColumnName[7],
      label: 'current value',
      options: {
        display: displayedColumns[ColumnName[7]],
        filter: false,
        sort: false,
      },
      widthUnits: 0.5,
    },
    // add fourth filter and set it to be invisible as a workaround for material ui gridlist bug
    {
      name: ColumnName[8],
      options: {
        display: displayedColumns[ColumnName[8]].toString(),
        sort: false,
        filter: true,
        filterType: 'custom',
        customFilterListRender: () => false,
        filterOptions: {
          display: () => <div style={{ width: 70 }} />,
        },
      },
    },
    // add invisible column to have access to data required for redirection to user data
    {
      name: ColumnName[9],
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: ColumnName[10],
      label: 'has hardware with linux',
      options: {
        display: displayedColumns[ColumnName[10]],
        filter: false,
        sort: false,
      },
      widthUnits: 0.5,
    },
    {
      name: ColumnName[11],
      label: 'has admin',
      options: {
        display: displayedColumns[ColumnName[11]],
        filter: false,
        sort: false,
      },
      widthUnits: 0.5,
    },
  ];
}

export function mapUserToDataRow(user: User) {
  return [
    user.oid,
    user.firstName,
    user.lastName,
    user.email,
    user.phone,
    user.active ? 'active' : 'inactive',
    `${user.totalAssignedHardwareValue} PLN`,
    `${user.totalCurrentHardwareValue} PLN`,
    user.comment,
    user.ldapLogin,
    <Checkbox checked={user.hasHardwareWithLinux} style={checkboxStyle} disabled />,
    <Checkbox checked={user.hasAdmin || false} style={checkboxStyle} disabled />,
  ];
}
