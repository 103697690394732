import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const AddAttributeV = t.type({
  id: nullableNumberToZero,
  name: nullableString,
  hardwareId: t.array(nullableNumberToZero),
  values: t.array(nullableString),
});

export type AddAttributeFormValue = t.TypeOf<typeof AddAttributeV>;

export function addAttribute(attribute: AddAttributeFormValue) {
  return fetchWrapper({
    url: '/api/hardware/attribute',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: attribute,
    },
  });
}
