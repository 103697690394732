import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export type UpdateOrganization = {
  name: string;
};

export const emptyOrganization: UpdateOrganization = {
  name: '',
};

export function updateOrganization(id: number, organization: UpdateOrganization) {
  return fetchWrapper({
    url: `/api/organization/${id}`,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: organization,
    },
  });
}
