import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const licenseHistoryDtoV = t.array(
  t.type({
    ldapLogin: t.string,
    licenseDifferences: t.array(
      t.type({
        propertyName: nullableString,
        valueBefore: nullableString,
        valueAfter: nullableString,
      }),
    ),
    actionType: nullableString,
    date: DateFromISOString,
  }),
);

export type LicenseHistoryDto = t.TypeOf<typeof licenseHistoryDtoV>;
export type LicenseHistory = {
  ldapLogin: string;
  licenseDifferences: {
    propertyName: string;
    valueBefore: string | string[];
    valueAfter: string | string[];
  }[];
  actionType: string;
  date: Date;
}[];

export function getLicenseHistory(id: number) {
  return fetchWrapper({
    url: `/api/license/${id}/history`,
    dataType: licenseHistoryDtoV,
  });
}
