import React from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type ConfirmDialogProps = {
  content: string | JSX.Element;
  onClose: () => void;
  open: boolean;
  handleClick: () => void;
  title: string;
  buttonLabel?: string;
  submitting?: boolean;
};

const useConfirmDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      alignSelf: 'center',
      marginTop: '20px',
      fontSize: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: '20px',
      textDecoration: 'underline',
      textDecorationColor: '#ffd242',
      textUnderlineOffset: '8px',
    },
    content: {
      padding: '30px 100px',
    },
    label: {
      alignSelf: 'center',
      fontSize: '20px',
      marginBottom: '10px',
      textAlign: 'center',
    },
    buttons: {
      width: '400px',
      margin: 'auto',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '50px',
    },
    cancelButton: {
      width: '170px',
      fontSize: 'large',
      height: '50px',
      borderRadius: '0',
      fontWeight: 'bold',
      border: '0',
      boxShadow: '0px 8px 6px lightgray',
      textTransform: 'none',
    },
  }),
);

const ConfirmDialog = ({
  content,
  onClose,
  open,
  handleClick,
  title,
  buttonLabel = 'Confirm',
  submitting,
}: ConfirmDialogProps) => {
  const classes = useConfirmDialogStyles();

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <Typography className={classes.title}>{title}</Typography>
      <DialogContent className={classes.content}>
        <Typography className={classes.label}>{content}</Typography>
        <Typography className={classes.label}>Are you sure?</Typography>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          onClick={onClose}
          color="default"
          variant="outlined"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          startIcon={submitting ? <CircularProgress size={20} /> : null}
          disabled={submitting}
          onClick={handleClick}
          variant="contained"
          color="primary"
          className={classes.cancelButton}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
