import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addHardware,
  AddHardwareFormValues,
  mapHardwareToFormValues,
} from '../../services/fetchServices/HardwareApi/addHardware';
import { getHardware } from '../../services/fetchServices/HardwareApi/getHardware';
import { addHardwareDtoInitialValues } from './AddHardwareHelpers';

export const useAddHardwareData = (id: string) => {
  const [initialValues, setInitialValues] = useState(addHardwareDtoInitialValues);
  const [redirectLocation, setRedirectLocation] = useState(`/hardware`);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      return;
    }
    getHardware(id).then((res) => {
      if (res.type === 'success') {
        const values = mapHardwareToFormValues(res.data);
        setInitialValues(values);
      } else {
        toast.error(`Fetching hardware failed: ${res.error.message}`);
      }
    });
  }, [id]);

  async function submitAddHardware(
    values: AddHardwareFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await addHardware(values);
    if (res.type === 'success') {
      setSubmitting(false);
      toast.success('Hardware added successfully');
      if (redirectLocation === `/add-hardware/0`) {
        navigate(`/add-hardware/${res.data.id}`);
      } else {
        navigate(redirectLocation);
      }
    } else {
      setSubmitting(false);
      toast.error(`Adding hardware failed: ${res.error.message}`);
    }
  }

  return {
    submitAddHardware,
    initialValues,
    setRedirectLocation,
  };
};
