import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export function deleteAttribute(id: number) {
  return fetchWrapper({
    url: `/api/hardware/attribute/${id}`,
    fetchArg: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}
