import React from 'react';
import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import { License } from '../../services/domainServices/LicensesService';
import AssignedUsersList from './AssignedUsersList';

export interface Filters extends Record<ColumnName, string[]> {}

export enum ColumnName {
  'Name',
  'Login',
  'LicenseType',
  'ExpirationStatus',
  'ExpirationDate',
  'OrganizationName',
  'Available',
  'AssignedUsers',
  'Id',
}

export const NUMBER_OF_LICENSES_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.Name]: [],
  [ColumnName.Login]: [],
  [ColumnName.LicenseType]: [],
  [ColumnName.ExpirationStatus]: [],
  [ColumnName.ExpirationDate]: [],
  [ColumnName.OrganizationName]: [],
  [ColumnName.Available]: [],
  [ColumnName.AssignedUsers]: [],
  [ColumnName.Id]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: false,
  [ColumnName[2]]: true,
  [ColumnName[3]]: true,
  [ColumnName[4]]: true,
  [ColumnName[5]]: false,
  [ColumnName[6]]: true,
  [ColumnName[7]]: true,
  [ColumnName[8]]: false,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    // add invisible column to have access to data required for redirection to licenses data
    {
      name: ColumnName[0],
      label: 'name',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[0]),
      },
    },
    {
      name: ColumnName[1],
      label: 'login',
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
      },
    },
    {
      name: ColumnName[2],
      label: 'type of license',
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        filter: true,
        filterList: activeFilters[ColumnName.LicenseType],
        filterOptions: { names: availableFilters[ColumnName.LicenseType] },
        sort: false,
      },
    },
    {
      name: ColumnName[3],
      label: 'expiration status',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[4],
      label: 'expiration date',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[4]),
      },
    },
    {
      name: ColumnName[5],
      label: 'organization',
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        filter: true,
        filterList: activeFilters[ColumnName.OrganizationName],
        filterOptions: { names: availableFilters[ColumnName.OrganizationName] },
        sort: false,
      },
    },
    {
      name: ColumnName[6],
      label: 'availability',
      options: {
        display: displayedColumns[ColumnName[6]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[7],
      label: 'assigned to',
      options: {
        display: displayedColumns[ColumnName[7]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[8],
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];
}

export function mapLicenses(licenses: License) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return [
    licenses.name,
    licenses.login,
    licenses.licenseType.toString(),
    setExpirationStatus(licenses, options),
    setExpirationDate(licenses, options),
    licenses.organization.name,
    licenses.available ? 'available' : 'not available',
    <AssignedUsersList licenses={licenses} />,
    licenses.id.toString(),
  ];
}

function setExpirationDate(licenses: License, options: Intl.DateTimeFormatOptions) {
  return !licenses.expirationDate ||
    licenses.expirationDate.toLocaleDateString('en-GB', options) === '01/01/1'
    ? ''
    : licenses.expirationDate.toLocaleDateString('en-GB', options);
}

function setExpirationStatus(licenses: License, options: Intl.DateTimeFormatOptions) {
  switch (true) {
    case licenses.expirationDate && licenses.expirationDate >= new Date():
      return 'active';
    case licenses.expirationDate &&
      licenses.expirationDate < new Date() &&
      licenses.expirationDate.toLocaleDateString('en-GB', options) === '01/01/1':
      return 'lifetime';
    case licenses.expirationDate &&
      licenses.expirationDate < new Date() &&
      licenses.expirationDate.toLocaleDateString('en-GB', options) !== '01/01/1':
      return 'expired';
    default:
      return '';
  }
}
