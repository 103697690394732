import { toast } from 'react-toastify';
import { addType, AddTypeFormValue } from './addType';

export const addTypeDtoInitialValues: AddTypeFormValue = {
  name: '',
};

export const useAddTypeData = () => {
  const initialValues = addTypeDtoInitialValues;

  async function submitAddType(values: AddTypeFormValue) {
    const res = await addType(values);
    if (res.type === 'success') {
      toast.success('Successfully added type');
      return res.data;
    }
    toast.error(res.error.message);

    return null;
  }

  return { submitAddType, initialValues };
};
