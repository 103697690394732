import { License, licenseV } from '../../domainServices/LicensesService';
import { fetchWrapper } from '../fetchWrapper';

export function getLicense(id: string) {
  return fetchWrapper({
    url: `/api/license/${id}`,
    dataType: licenseV,
  }).then((res) =>
    res.type === 'success' ? { ...res, data: mapLicenseData(res.data) } : res,
  );
}

function mapLicenseData(data: License) {
  return {
    ...data,
    expirationDate: convertMinimalData(data.expirationDate),
  };
}

function convertMinimalData(date: Date | null) {
  if (
    date !== null &&
    date.toString() === new Date('0001-01-01T00:00:00+00:00').toString()
  ) {
    return null;
  }
  return date;
}
