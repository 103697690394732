import { AddLicenseFormValues } from '../../services/fetchServices/LicensesApi/addLicense';
import { isPositiveInteger } from '../AddHardware/AddHardwareHelpers';

export const addLicenseDtoInitialValues: AddLicenseFormValues = {
  name: '',
  licenseType: 'Box',
  licenseKind: 'Single',
  organization: 0,
  firstKey: '',
  secondKey: '',
  login: '',
  password: '',
  expirationDate: null,
  purchasePrice: undefined,
  capacity: 0,
  available: true,
  comment: '',
  expirationStatus: 'active',
};

export type FormikValues = typeof addLicenseDtoInitialValues;

export function validateAddLicenseData(values: AddLicenseFormValues) {
  const errors: Partial<Record<keyof AddLicenseFormValues, string>> = {};
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.licenseType) {
    errors.licenseType = 'required';
  }
  if (!values.licenseKind) {
    errors.licenseKind = 'required';
  }
  if (!values.organization) {
    errors.organization = 'required';
  }
  if (values.capacity && !isPositiveInteger(values.capacity.toString())) {
    errors.capacity = 'value must be a positive integer';
  }

  return errors;
}
