import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import LazyList from '../../components/LazyList/LazyList';
import {
  defaultOnSearchChange,
  useStyles,
} from '../../components/LazyList/LazyListHelpers';
import {
  ColumnName,
  composeColumnsDefinition,
  mapProtocols,
  NUMBER_OF_PROTOCOLS_FETCHED,
} from './ProtocolsListHelpers';
import useProtocolsListData from './useProtocolsListData';

const ProtocolsListBody = () => {
  const {
    state: {
      totalProtocols,
      filteredProtocols,
      loadedProtocols,
      activeFilters,
      availableFilters,
      displayedColumns,
      activeSort,
      searchText,
      isFetching,
    },
    setState,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onColumnsChangeCallback,
    downloadProtocol,
  } = useProtocolsListData();

  const lazyListClasses = useStyles();

  const onInfiniteScroll = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        numberOfRequestedProtocols:
          prevState.numberOfRequestedProtocols + NUMBER_OF_PROTOCOLS_FETCHED,
      })),
    [setState],
  );

  const allActionComponents = (rowData: any[]) => [
    <IconButton
      onClick={() => downloadProtocol(rowData[ColumnName.ID])}
      className={lazyListClasses.showDetailsIconButton}
    >
      <GetAppIcon fontSize="inherit" className={lazyListClasses.showDetailsIcon} />
    </IconButton>,
  ];

  const columns = composeColumnsDefinition(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const options = {
    serverSide: true,
    responsive: 'standard',
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    filter: false,
    filterType: 'multiselect',
    serverSideFilterList: Object.values(activeFilters),
    searchText,
    selectableRows: 'none',
    rowHover: false,
    searchOpen: true,
    textLabels: {
      toolbar: {
        downloadCsv: 'Download Report',
      },
    },
  };

  const data = loadedProtocols.map(mapProtocols);

  return (
    <LazyList
      data={data}
      columns={columns}
      options={options}
      onFilterChange={contextEnchancedOnFiltersChange}
      onSortChange={contextEnchancedOnSortChange}
      onSearchChange={defaultOnSearchChange(setState)}
      onColumnsChange={onColumnsChangeCallback}
      totalItems={totalProtocols}
      filteredItems={filteredProtocols}
      onInfiniteScrollCallback={onInfiniteScroll}
      isFetching={isFetching}
      actionComponents={allActionComponents}
    />
  );
};

export default ProtocolsListBody;
