import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  emptyExtendedNotification,
  notificationFormData,
  NotificationForUpdateDto,
} from '../../services/domainServices/NotificationService';
import { getNotification } from '../../services/fetchServices/NotificationApi/getNotifiaction';
import { updateNotification } from '../../services/fetchServices/NotificationApi/updateNotification';

const getPrivatePhone = (
  valuesPrivatePhone: string,
  notificationResponsePrivatePhone: string,
) =>
  notificationResponsePrivatePhone
    ? `${valuesPrivatePhone} ${notificationResponsePrivatePhone}`
    : '';

const getLdapLogin = (ldapLogin: string, pendingLdapLogin: string) =>
  ldapLogin || pendingLdapLogin;

export const useNotificationData = (id: string) => {
  const [initialValues, setValues] = useState(emptyExtendedNotification);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const [notificationResponse] = await Promise.all([getNotification(id)]);
      if (notificationResponse.type === 'success') {
        setValues((values) => ({
          ...values,
          ...notificationResponse.data,
          privatePhone: getPrivatePhone(
            values.privatePhone,
            notificationResponse.data.privatePhone,
          ),
          ldapLogin: getLdapLogin(
            notificationResponse.data.ldapLogin,
            notificationResponse.data.pendingLdapLogin,
          ),
          status: notificationResponse.data.status,
        }));
      } else {
        const notificationError =
          notificationResponse.type === 'error' ? notificationResponse.error.message : '';

        toast.error(notificationError);
      }
    };
    fetchData();
  }, [id]);

  async function submitAddUserFromNotification(
    values: notificationFormData,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const body: NotificationForUpdateDto = {
      user: {
        active: values.active,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email === '' ? null : values.email,
        comment: values.comment,
        phone: values.phone.substring(4), // removing +48
        privatePhone: values.privatePhone.substring(4), // removing +48
        privateEmail: values.privateEmail === '' ? null : values.privateEmail,
        oid: values.oid,
      },
      ldapLogin: values.ldapLogin,
      status: values.status,
      additionalInfo: values.additionalInfo,
      startDate: values.startDate,
      technology: values.technology,
      location: values.location,
      assignment: values.assignment,
      assignHardware: values.assignHardware,
      assignBoardingPass: values.assignBoardingPass,
      wifiPassword: values.status === 'Realized' ? values.wifiPassword : '',
      officePassword: values.status === 'Realized' ? values.officePassword : '',
    };
    if (values.status === 'Realized' && values.officePassword === '') {
      toast.error('Office password is required');
      setSubmitting(false);
      return;
    }
    const res = await updateNotification(id, body);
    if (res.type === 'success') {
      setSubmitting(false);
      const messageText =
        values.status === 'Pending'
          ? 'Succesfully updated notification'
          : 'Succesfully added user from the notification';
      toast.success(messageText);
      navigate('/notifications');
    } else {
      setSubmitting(false);
      toast.error(res.error.message);
    }
  }

  return {
    initialValues,
    submitAddUserFromNotification,
  };
};
