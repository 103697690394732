import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface Data {
  name: string;
  licenseType: string;
  licenseKind: string;
  action: any;
}

interface Column {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const columns: Column[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'name' },
  { id: 'licenseType', numeric: false, disablePadding: true, label: 'type of license' },
  { id: 'licenseKind', numeric: false, disablePadding: true, label: 'kind of license' },
  { id: 'action', numeric: false, disablePadding: true, label: 'action' },
];

export const UserLicensesTableHeader = () => (
  <TableHead>
    <TableRow>
      {columns.map((column) => (
        <TableCell key={column.id} align="left">
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
