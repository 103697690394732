import { toast } from 'react-toastify';
import { addBrand, AddBrandFormValue } from './addBrand';

export const addBrandDtoInitialValues: AddBrandFormValue = {
  id: 0,
  name: '',
};

export const useAddBrandData = () => {
  const initialValues = addBrandDtoInitialValues;

  async function submitAddBrand(values: AddBrandFormValue) {
    const res = await addBrand(values);
    if (res.type === 'success') {
      toast.success('Successfully added brand');
      return res.data;
    }
    toast.error(res.error.message);
    return null;
  }

  return { submitAddBrand, initialValues };
};
