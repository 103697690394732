import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHardwareMetaData } from '../../hooks/useHardwareMetaData';
import {
  emptyHardware,
  HardwareWithHardwareChildren,
} from '../../services/domainServices/HardwareService';
import { getHardware } from '../../services/fetchServices/HardwareApi/getHardware';
import {
  getHardwareHistory,
  parseHistoryData,
} from '../../services/fetchServices/HardwareApi/getHardwareHistory';
import {
  emptyUpdateHardwareFormValues,
  mapHardwareToFormValues,
  updateHardware,
  UpdateHardwareFormValues,
} from '../../services/fetchServices/HardwareApi/updateHardware';
import { useHardwareHistoryData } from './HardwareHistory/useHardwareHistoryData';

export const useHardwareData = (id: string) => {
  const [data, setData] = useState(emptyHardware);
  const [formData, setFormData] = useState(emptyUpdateHardwareFormValues);
  const metaData = useHardwareMetaData();
  const [isEdit, setIsEdit] = useState(false);

  const { historyData, setHistoryData, setIsLoading } = useHardwareHistoryData(data.id);

  // fetch initial data for details form
  useEffect(() => {
    const fetchData = async () => {
      const res = await getHardware(id);
      if (res.type === 'success') {
        setData(res.data);
        setFormData(mapHardwareToFormValues(res.data));
      } else {
        toast.error(`Fetching hardware data failed: ${res.error.message}`);
      }
    };
    fetchData();
  }, [id]);

  // fetch parent data for details form
  useEffect(() => {
    const fetchData = async () => {
      if (data.parentHardwareId) {
        const parentRes = await getHardware(data.parentHardwareId.toString());
        if (parentRes.type === 'success' && data.parentHardwareId === parentRes.data.id) {
          setFormData(mapParent(mapHardwareToFormValues(data), parentRes.data));
        } else {
          setFormData(mapHardwareToFormValues(data));
        }
      }
    };
    fetchData();
  }, [data]);

  function mapParent(
    values: UpdateHardwareFormValues,
    parentData: HardwareWithHardwareChildren,
  ) {
    return {
      ...values,
      parentHardware: `${parentData.jitTag}, ${parentData.brand.name}, ${parentData.model}`,
    };
  }

  // formik submit handler
  async function submitUpdateHardware(
    values: UpdateHardwareFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await updateHardware(id, values);
    if (res.type === 'success') {
      await refetchHardwareHistory();
      setData(res.data);
      setIsEdit(false);
      toast.success('Successfully updated hardware');
    } else {
      toast.error(`Error: ${res.error.message}`);
    }
    setSubmitting(false);
  }

  async function refetchHardwareHistory() {
    setIsLoading(true);
    const userHistory = await getHardwareHistory(data.id);
    if (userHistory.type === 'success') {
      const parsedHistory = parseHistoryData(userHistory.data);
      setHistoryData(parsedHistory);
    } else {
      toast.error(userHistory.error.message);
    }
    setIsLoading(false);
  }

  return {
    state: {
      data,
      metaData,
      formData,
      isEditMode: isEdit,
    },
    setIsEdit,
    submitUpdateHardware,
    historyData,
  };
};
