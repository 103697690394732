import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { defaultOnDownload } from '../../components/LazyList/LazyListHelpers';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import { useHardwareMetaData } from '../../hooks/useHardwareMetaData';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import {
  Hardware,
  hardwareStatusLabels,
} from '../../services/domainServices/HardwareService';
import { fetchHardware } from '../../services/fetchServices/HardwareApi/fetchHardware';
import { getHardwareReport } from '../../services/fetchServices/HardwareApi/getHardwareReport';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumnDisplay,
  NUMBER_OF_HARDWARE_FETCHED,
} from './HardwareListHelpers';

export type DisplayedColumns = Record<ColumnName, boolean>;

export interface HardwareListState {
  totalHardware: number;
  filteredHardware: number;
  loadedHardware: Hardware[];
  numberOfRequestedHardware: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
  isCalendarOpen: boolean;
  monthReportDate: string | null;
  isMonthReportFetching: boolean;
  isDisposalCalendarOpen: boolean;
  disposalReportDate: string | null;
  isDisposalReportFetching: boolean;
}

const useHardwareListData = (parents: boolean, initialFilters?: Filters) => {
  const [state, setState] = useState<HardwareListState>({
    totalHardware: 0,
    filteredHardware: 0,
    loadedHardware: [],
    numberOfRequestedHardware: 0,
    availableFilters: {
      ...EmptyFilters,
      [ColumnName.Status]: Object.values(hardwareStatusLabels),
    },
    searchText: '',
    isFetching: false,
    isCalendarOpen: false,
    monthReportDate: null,
    isMonthReportFetching: false,
    isDisposalCalendarOpen: false,
    disposalReportDate: null,
    isDisposalReportFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Hardware',
    ColumnName,
    InitialColumnDisplay,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext(
    initialFilters ? 'hardwareMainList' : 'hardwareDialogList',
    {
      activeFilters: initialFilters || EmptyFilters,
      activeSort: { columnName: 'CreationDate', sortDirection: 'desc' },
    },
  );

  const { activeFilters, activeSort } = displayedFilters;

  // use ref to access newest values of state from useEffect that resets the data without firing when they change
  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  // initial get organizations and roles
  const hardwareMetadata = useHardwareMetaData();
  useUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      availableFilters: {
        ...prevState.availableFilters,
        [ColumnName.Organization]: hardwareMetadata.organizations.map((o) => o.name),
        [ColumnName.Brand]: hardwareMetadata.brands.map((b) => b.name),
        [ColumnName.Category]: hardwareMetadata.categories.map((c) => c.name),
      },
    }));
  }, [hardwareMetadata]);

  const onDownload = () => {
    getHardwareReport({
      filters: {
        statusFilters: debouncedFilters[ColumnName.Status],
        brandFilters: debouncedFilters[ColumnName.Brand],
        categoryFilters: debouncedFilters[ColumnName.Category],
        organizationFilters: debouncedFilters[ColumnName.Organization],
      },
      sort: {
        direction: activeSort.sortDirection,
        column: activeSort.columnName,
      },
      search: debouncedSearchText,
      priceFrom: debouncedFilters[ColumnName.PriceFrom][0],
      priceTo: debouncedFilters[ColumnName.PriceTo][0],
      columns: Object.entries(displayedColumns)
        .filter((e) => e[1])
        .map((e) => e[0]),
    }).then(defaultOnDownload('HardwareReport.xlsx'));
    return false;
  };

  // after filters or sort change, reset table and fetch hardware (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchHardware(0, NUMBER_OF_HARDWARE_FETCHED, {
        filters: {
          statusFilters: debouncedFilters[ColumnName.Status],
          brandFilters: debouncedFilters[ColumnName.Brand],
          categoryFilters: debouncedFilters[ColumnName.Category],
          organizationFilters: debouncedFilters[ColumnName.Organization],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
        priceFrom: debouncedFilters[ColumnName.PriceFrom][0],
        priceTo: debouncedFilters[ColumnName.PriceTo][0],
        parents,
      });

      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedHardware: res.data.hardware,
          totalHardware: res.data.totalRecordsAmount,
          filteredHardware: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading hardware failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedHardware increments, make a request for more hardware (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchHardware(
        curState.loadedHardware.length,
        NUMBER_OF_HARDWARE_FETCHED,
        {
          filters: {
            statusFilters: debouncedFilters[ColumnName.Status],
            brandFilters: debouncedFilters[ColumnName.Brand],
            categoryFilters: debouncedFilters[ColumnName.Category],
            organizationFilters: debouncedFilters[ColumnName.Organization],
          },
          sort: {
            direction: curState.activeSort.sortDirection,
            column: curState.activeSort.columnName,
          },
          search: curState.searchText,
          priceFrom: debouncedFilters[ColumnName.PriceFrom][0],
          priceTo: debouncedFilters[ColumnName.PriceTo][0],
          parents,
        },
      );
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedHardware: [...prevState.loadedHardware, ...res.data.hardware],
          totalHardware: res.data.totalRecordsAmount,
          filteredHardware: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading hardware failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedHardware]);

  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onDownload,
  };
};

export default useHardwareListData;
