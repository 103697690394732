import React from 'react';
import HardwareListHeader from './HardwareListHeader';
import HardwareListTable from './HardwareListTable';

const HardwareList = () => (
  <div>
    <HardwareListHeader />
    <HardwareListTable />
  </div>
);

export default HardwareList;
