import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import {
  HardwareStatus,
  hardwareStatusLabels,
  HardwareV,
} from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetHardwareDtoV = t.type({
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
  hardware: t.array(HardwareV),
});

export type GetHardwareDto = t.TypeOf<typeof GetHardwareDtoV>;

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

export const statusLabelsMap = (label: string) =>
  Object.keys(hardwareStatusLabels).find(
    (status) => hardwareStatusLabels[status as HardwareStatus] === label,
  ) || '';

type HardwareListQueryObject = {
  filters: {
    statusFilters: string[];
    brandFilters: string[];
    categoryFilters: string[];
    organizationFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
  priceFrom: string | undefined;
  priceTo: string | undefined;
  parents: boolean;
};

export function fetchHardware(
  startingIndex: number,
  hardwareAmount: number,
  queryObject: HardwareListQueryObject,
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have hardware indexing starting from 1
    numberOfRecords: hardwareAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }

  if (queryObject.priceFrom) {
    searchParams.append('PriceFrom', queryObject.priceFrom);
  }

  if (queryObject.priceTo) {
    searchParams.append('PriceTo', queryObject.priceTo);
  }

  if (queryObject.parents) {
    searchParams.append('Expansibility', 'true');
  }

  queryObject.filters.statusFilters.forEach((status) =>
    searchParams.append('Statuses', statusLabelsMap(status)),
  );
  queryObject.filters.brandFilters.forEach((brand) =>
    searchParams.append('Brands', brand),
  );
  queryObject.filters.categoryFilters.forEach((category) =>
    searchParams.append('Categories', category),
  );
  queryObject.filters.organizationFilters.forEach((organization) =>
    searchParams.append('Organizations', organization),
  );
  return fetchWrapper({
    url: `/api/hardware?${searchParams.toString()}`,
    dataType: GetHardwareDtoV,
  });
}
