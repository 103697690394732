import {
  License,
  LicensesKind,
  LicensesType,
} from '../../domainServices/LicensesService';
import { fetchWrapper } from '../fetchWrapper';

export type AddLicenseFormValues = {
  name: string;
  licenseType: LicensesType;
  licenseKind: LicensesKind;
  organization: number;
  firstKey: string;
  secondKey: string;
  login: string;
  password: string;
  expirationDate: Date | null;
  purchasePrice?: string | null;
  capacity: number;
  available: boolean;
  comment: string;
  expirationStatus: string;
};

export const mapLicenseToFormValues: (license: License) => AddLicenseFormValues = (
  license,
) => ({
  name: license.name,
  licenseType: license.licenseType,
  licenseKind: license.licenseKind,
  organization: license.organization.id,
  firstKey: license.firstKey,
  secondKey: license.secondKey,
  login: license.login,
  password: license.password,
  expirationDate: license.expirationDate,
  purchasePrice: license.purchasePrice ? license.purchasePrice.toString() : null,
  capacity: license.capacity,
  available: license.available,
  comment: license.comment,
  expirationStatus: license.expirationStatus,
});

export interface AddLicenseDto {
  name: string;
  licenseType: LicensesType;
  licenseKind: LicensesKind;
  organizationId: number;
  firstKey: string;
  secondKey: string;
  login: string;
  password: string;
  expirationDate?: string;
  purchasePrice?: number;
  capacity: number;
  available: boolean;
  comment: string;
}

export const mapLicenseFormValuesToDto: (
  values: AddLicenseFormValues,
) => AddLicenseDto = (values) => ({
  name: values.name,
  licenseType: values.licenseType,
  licenseKind: values.licenseKind,
  organizationId: values.organization || 0,
  firstKey: values.firstKey,
  secondKey: values.secondKey,
  login: values.login,
  password: values.password,
  expirationDate: values.expirationDate ? values.expirationDate.toISOString() : undefined,
  purchasePrice: values.purchasePrice ? Number(values.purchasePrice) : undefined,
  capacity: values.capacity,
  available: values.available,
  comment: values.comment,
});

export function addLicense(license: AddLicenseFormValues) {
  const licenseDto = mapLicenseFormValuesToDto(license);
  return fetchWrapper({
    url: '/api/license',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: licenseDto,
    },
  });
}
