import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DrawerWrapper from './components/DrawerWrapper/DrawerWrapper';
import NoDrawerWrapper from './components/NoDrawerWrapper/NoDrawerWrapper';
import Spinner from './components/Spinner';
import {
  guest,
  UserState,
  useUserDispatch,
  useUserState,
} from './context/UserDataProvider';
import AddHardware from './pages/AddHardware/AddHardware';
import AddLicense from './pages/AddLicense/AddLicense';
import AddOffice from './pages/AddOffice/AddOffice';
import AddUserFromNotification from './pages/AddUserFromNotification/AddUserFromNotification';
import HardwareDetails from './pages/HardwareDetails/HardwareDetails';
import HardwareList from './pages/HardwareList/HardwareList';
import InventoryDetails from './pages/InventoryDetails/InventoryDetails';
import InventoryList from './pages/InventoryList/InventoryList';
import LicenseDetails from './pages/LicenseDetails/LicenseDetails';
import LicensesList from './pages/LicensesList/LicensesList';
import NotificationsList from './pages/NotificationsList/NotificationsList';
import OfficeDetails from './pages/OfficeDetails/OfficeDetails';
import OfficesList from './pages/OfficesList/OfficesList';
import ProtocolsList from './pages/ProtocolsList/ProtocolsList';
import SettingsList from './pages/Settings/SettingsList';
import AddUser from './pages/UserAdd/AddUser';
import UserDetails from './pages/UserDetails/UserDetails';
import UserList from './pages/UserList/UserList';
import { getAuthUser } from './services/fetchServices/getAuthUser';
import { logout } from './services/fetchServices/logout';
import { canReadApplication } from './utils/isAdmin';
import { loginRequest } from './authConfig';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.background.default,
      padding: '24px',
      margin: 0,
      paddingTop: 0,
      minHeight: '100vh',
      boxSizing: 'content-box',
    },
  }),
);

const App: React.FC = () => {
  const user = useUserState();
  const classes = useStyles();
  const setUser = useUserDispatch();
  const [userNotFound, setUserNotFound] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const fetchAuthenticatedUser = useCallback(
    async (accessToken?: string) => {
      try {
        if (localStorage.getItem('atlasToken') || !!accessToken) {
          const res = await getAuthUser(
            accessToken ?? localStorage.getItem('atlasToken'),
          );
          if (res.status === 200) {
            const data = await res.json();
            setUser(data as UserState);
          } else if (res.status === 404) {
            setUserNotFound(true);
          } else if (res.status === 401) {
            localStorage.removeItem('atlasToken');
            await logout();
            window.location.reload();
          } else {
            toast.error('Unexpected error occurred');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setUser],
  );

  useEffect(() => {
    fetchAuthenticatedUser();
  }, [fetchAuthenticatedUser]);

  useEffect(() => {
    if (localStorage.getItem('atlasToken')) {
      return;
    }

    if (accounts[0] && isAuthenticated) {
      const tokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          localStorage.setItem('atlasToken', response.accessToken);
          fetchAuthenticatedUser(response.accessToken);
        })
        .catch((err) => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return instance
              .acquireTokenPopup(tokenRequest)
              .then((response) => {
                localStorage.setItem('atlasToken', response.accessToken);
                fetchAuthenticatedUser(response.accessToken);
              })
              .catch((error) => {
                console.error(error);
              });
          }
          console.error(err);
        });
    } else if (inProgress === InteractionStatus.None && !isAuthenticating) {
      setIsAuthenticating(true);
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
      });
    } else if (inProgress === InteractionStatus.None && isAuthenticating) {
      setIsAuthenticating(false);
      // fetchAuthenticatedUser();
    }
  }, [
    isAuthenticated,
    accounts,
    instance,
    inProgress,
    isAuthenticating,
    fetchAuthenticatedUser,
  ]);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('atlasToken');
      setUser(guest);
      const logoutRequest = {
        account: accounts[0],
      };
      instance.logoutRedirect(logoutRequest).catch((e) => {
        console.error(e);
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (userNotFound) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h4" align="center">
          User not found. Please contact with servicedesk@jit.team to resolve this
          problem.
        </Typography>
        <Button
          style={{ backgroundColor: '#ffd242', color: '#000', marginTop: '10px' }}
          variant="contained"
          color="primary"
          onClick={handleLogout}
        >
          LOGOUT
        </Button>
      </div>
    );
  }

  if (
    inProgress === InteractionStatus.HandleRedirect ||
    !localStorage.getItem('atlasToken')
  ) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="primary" size="7rem" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Routes>
        {canReadApplication(user.roles) ? (
          <>
            <Route
              path="/"
              element={
                <DrawerWrapper>
                  <UserList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/hardware"
              element={
                <DrawerWrapper>
                  <HardwareList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/hardware/:id"
              element={
                <DrawerWrapper>
                  <HardwareDetails />
                </DrawerWrapper>
              }
            />
            <Route
              path="/notifications"
              element={
                <DrawerWrapper>
                  <NotificationsList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/notification/:id"
              element={
                <DrawerWrapper>
                  <AddUserFromNotification />
                </DrawerWrapper>
              }
            />
            <Route
              path="/offices"
              element={
                <DrawerWrapper>
                  <OfficesList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/office/:id"
              element={
                <DrawerWrapper>
                  <OfficeDetails />
                </DrawerWrapper>
              }
            />
            <Route
              path="/license/:id"
              element={
                <DrawerWrapper>
                  <LicenseDetails />
                </DrawerWrapper>
              }
            />
            <Route
              path="/licenses"
              element={
                <DrawerWrapper>
                  <LicensesList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/add-user"
              element={
                <DrawerWrapper>
                  <AddUser />
                </DrawerWrapper>
              }
            />
            <Route
              path="/add-office"
              element={
                <DrawerWrapper>
                  <AddOffice />
                </DrawerWrapper>
              }
            />
            <Route
              path="/add-license"
              element={
                <DrawerWrapper>
                  <AddLicense />
                </DrawerWrapper>
              }
            />
            <Route
              path="/user/:ldapLogin"
              element={
                <DrawerWrapper>
                  <UserDetails />
                </DrawerWrapper>
              }
            />
            <Route
              path="/add-hardware"
              element={
                <DrawerWrapper>
                  <AddHardware />
                </DrawerWrapper>
              }
            />
            <Route
              path="/add-hardware/:id"
              element={
                <DrawerWrapper>
                  <AddHardware />
                </DrawerWrapper>
              }
            />
            <Route
              path="/settings"
              element={
                <DrawerWrapper>
                  <SettingsList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/protocols"
              element={
                <DrawerWrapper>
                  <ProtocolsList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/inventory"
              element={
                <DrawerWrapper>
                  <InventoryList />
                </DrawerWrapper>
              }
            />
            <Route
              path="/inventory/:ldapLogin"
              element={
                <DrawerWrapper>
                  <InventoryDetails />
                </DrawerWrapper>
              }
            />
          </>
        ) : (
          <Route
            path={`/inventory/${user.ldapLogin}`}
            element={
              <NoDrawerWrapper>
                <InventoryDetails />
              </NoDrawerWrapper>
            }
          />
        )}
        <Route
          path="*"
          element={
            user.roles?.includes('anonymous') ? (
              <Spinner />
            ) : (
              <Navigate
                to={canReadApplication(user.roles) ? '/' : `/inventory/${user.ldapLogin}`}
              />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default App;
