import React from 'react';
import ComputerOutlinedIcon from '@material-ui/icons/ComputerOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

export const jiraAtlasLink =
  ' https://jira.jit.team/s/1248254186d2282b79f32f10a9b3715f-T/re2ctw/805000/9f9fb7f608d3015990e9867491affa4a/3.1.0/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en&collectorId=4bbaa98c';

export const drawerWidth = 250;

export const adminTabs = [
  {
    text: 'Notifications',
    icon: <NotificationsNoneOutlinedIcon />,
    to: '/notifications',
    active: true,
  },
  {
    text: 'Users',
    icon: <PeopleOutlineOutlinedIcon />,
    to: '/',
    active: true,
    badge: false,
  },
  {
    text: 'Offices',
    icon: <MeetingRoomOutlinedIcon />,
    to: '/offices',
    active: true,
    badge: false,
  },
  {
    text: 'Licenses',
    icon: <InsertDriveFileOutlinedIcon />,
    to: '/licenses',
    active: true,
    badge: false,
  },
  {
    text: 'Hardware',
    icon: <ComputerOutlinedIcon />,
    to: '/hardware',
    active: true,
    badge: false,
  },
  {
    text: 'Inventory',
    icon: <InboxOutlinedIcon />,
    to: '/inventory',
    active: true,
    badge: false,
  },
  {
    text: 'Protocols',
    icon: <DescriptionOutlinedIcon />,
    to: '/protocols',
    active: true,
    badge: false,
  },
  {
    text: 'Settings ',
    icon: <SettingsOutlinedIcon />,
    to: '/settings',
    active: true,
    badge: false,
  },
];
