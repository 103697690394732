import { ChangeEvent } from 'react';
import countryTelData from 'country-telephone-data';

/*
Do not remove this function! 
Created due to library defect (some countries don't have spaces before and after brackets in their phone formats)
Function fixes that defect and adds spaces
E.g. Papua New Guinea +675 (___) __ ___ 
(all before the first space is the prefix and it is used to find country name since country is not saved in backend)
*/
const addSpaceToCountryFormats = (countryCodeFormat: string) => {
  const [beforeBracket, ...after] = countryCodeFormat.split('(');
  const beforeBracketList = beforeBracket.split('');

  const [afterBracket, ...rest] = after.join('').split(')');
  const afterBracketList = afterBracket.split('');
  if (
    beforeBracketList[beforeBracketList.length - 1] !== ' ' &&
    countryCodeFormat.includes('(')
  ) {
    beforeBracketList.push(' (');
  } else if (countryCodeFormat.includes('(')) {
    beforeBracketList.push('(');
  }

  if (afterBracketList[0] !== ' ' && countryCodeFormat.includes(')')) {
    afterBracketList.push(') ');
  } else if (countryCodeFormat.includes(')')) {
    beforeBracketList.push(')');
  }

  return `${beforeBracketList.join('')}${afterBracketList.join('')}${rest.join('')}`;
};

export const getCountries = () =>
  countryTelData.allCountries
    .filter((country) => country.format !== undefined)
    .map((country) => ({
      ...country,
      format: addSpaceToCountryFormats(country.format),
    }));

export const getCountryByName = (name: string) =>
  getCountries().find((c) => c.name === name)!;

const getPrefix = (phone: string) => phone.split(' ')[0];

export const getCountryNameByPhone = (phone: string) => {
  const prefix = getPrefix(phone);
  const country = getCountries().find((c) => c.dialCode === prefix)?.name;
  return phone.includes('+') && country ? country : 'Poland (Polska)';
};

export const reformatCountryPhoneMask = (countryCodeFormat: string) =>
  countryCodeFormat
    .split('')
    .map((char) => {
      if (char === '.') {
        return '9';
      }
      if (char === '-') {
        return ' ';
      }
      return char;
    })
    .join('');

export const updateField =
  (setFieldValue: (field: string, value: string) => void) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFieldValue(name, value);
  };

export const updatePrivatePhoneBasedOnCountry =
  (setFieldValue: (field: string, value: string) => void) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const country = getCountryByName(value);
    setFieldValue('privatePhone', `+${country.dialCode} `);
    setFieldValue('countryPrivatePhone', value);
  };

export const updateCompanyPhoneFieldBasedOnCountry =
  (setFieldValue: (field: string, value: string) => void, fieldName: string) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const country = getCountryByName(value);
    setFieldValue('phone', `+${country.dialCode} `);
    setFieldValue(fieldName, value);
  };
