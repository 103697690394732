import {
  HardwareWithHardwareChildren,
  HardwareWithHardwareChildrenV,
} from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

export function getHardware(id: string) {
  return fetchWrapper({
    url: `/api/hardware/${id}`,
    dataType: HardwareWithHardwareChildrenV,
  }).then((res) =>
    res.type === 'success' ? { ...res, data: mapHardwareData(res.data) } : res,
  );
}

function mapHardwareData(data: HardwareWithHardwareChildren) {
  return {
    ...data,
    warrantyPeriod: convertMinimalData(data.warrantyPeriod),
    salesDate: convertMinimalData(data.salesDate),
    purchaseDate: convertMinimalData(data.purchaseDate),
    inventoryDate: convertMinimalData(data.inventoryDate),
  };
}

function convertMinimalData(date: Date | null) {
  if (
    date !== null &&
    date.toString() === new Date('0001-01-01T00:00:00+00:00').toString()
  ) {
    return null;
  }
  return date;
}
