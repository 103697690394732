import { createDataResponse, createErrorResponse } from '../fetchWrapper';

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

const statusMap: Record<string, string> = {
  active: 'true',
  inactive: 'false',
};

const columnNamesMap = (name: string) => {
  if (name === 'name') {
    return 'FirstName';
  }
  if (name === 'surname') {
    return 'LastName';
  }
  if (name === 'userID') {
    return 'Oid';
  }
  if (name === 'companyPhone') {
    return 'phone';
  }
  if (name === 'companyEmail') {
    return 'email';
  }
  if (name === 'status') {
    return 'active';
  }
  return name;
};

type UserListQueryObject = {
  filters: {
    statusFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
  columns: string[];
};

export async function getUserReport(queryObject: UserListQueryObject) {
  const params: Record<string, string> = {
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: columnNamesMap(queryObject.sort.column) || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }

  queryObject.filters.statusFilters.forEach((status) =>
    searchParams.append('Active', statusMap[status]),
  );
  queryObject.columns.forEach((column) => {
    if (column) {
      searchParams.append('fields', columnNamesMap(column));
    }
  });

  try {
    const res = await fetch(`/api/report/user?${searchParams.toString()}`, {
      method: 'POST',
      headers: {
        accept: 'text/plain',
      },
    });
    if (!res.ok) {
      throw new Error("couldn't download report");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
