import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useStylesForSettings } from '../../../components/SettingsComponents/settingsHelpers';
import SettingsTableHeader from '../../../components/SettingsComponents/SettingsTableHeader';
import { useHardwareMetaData } from '../../../hooks/useHardwareMetaData';
import { AddCategoryFormValue } from './addCategory';
import { emptyCategory, UpdateCategory } from './updateCategory';
import { addCategoryDtoInitialValues, useAddCategoryData } from './useAddCategoryData';
import { useDeleteCategoryData } from './useDeleteCategoryData';
import { useUpdateCategoryData } from './useUpdateCategoryData';

const CategoriesList = () => {
  const classes = useStylesForSettings();
  const data = useHardwareMetaData();
  const { submitDeleteCategory } = useDeleteCategoryData();
  const { submitUpdateCategory } = useUpdateCategoryData();
  const { submitAddCategory } = useAddCategoryData();
  const [categories, setCategories] = React.useState(data.categories);
  const [selected, setSelected] = React.useState(-1);
  const [values, setValues] = React.useState<AddCategoryFormValue>(
    addCategoryDtoInitialValues,
  );
  const [updatedValues, setUpdatedValues] = React.useState<UpdateCategory>(emptyCategory);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    try {
      const res = await submitAddCategory(values);
      if (res) {
        setCategories([...categories, res as { id: number; name: string }]);
        setValues(addCategoryDtoInitialValues);
      }
    } catch {
      toast.error('Error adding category');
    }
    event.preventDefault();
  };

  const handleUpdate = async (id: number) => {
    try {
      const resType = await submitUpdateCategory(id, updatedValues);
      if (resType === 'success') {
        setCategories(
          categories.map((b) => (b.id === id ? { ...b, name: updatedValues.name } : b)),
        );
      }
      deactivateEditMode();
    } catch {
      toast.error('Error updating brand');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const resType = await submitDeleteCategory(id);
      if (resType === 'success') {
        setCategories(categories.filter((b) => b.id !== id));
      }
    } catch {
      toast.error('Error deleting brand');
    }
  };

  const handleUpdateChange =
    (name: keyof UpdateCategory) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdatedValues({ ...updatedValues, [name]: event.target.value });
    };

  const handleClick = (id: number) => {
    setSelected(id);
  };

  function deactivateEditMode() {
    setSelected(-1);
  }

  useEffect(() => {
    setCategories(data.categories);
  }, [data.categories]);

  const isSelected = (id: number) => selected === id;

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <SettingsTableHeader />
          {categories.length === 0 ? (
            <TableRow>
              <TableCell align="center">
                <TextField
                  id="standard-basic"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  style={{ width: '20vw' }}
                  multiline
                />
              </TableCell>
              <TableCell align="center">
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                >
                  Add New
                </Button>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <TextField
                    id="standard-basic"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    style={{ width: '20vw' }}
                    multiline
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Add New
                  </Button>
                </TableCell>
              </TableRow>
              {categories.map((d) => {
                const isItemSelected = isSelected(d.id);

                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={d.id}
                  >
                    {isItemSelected ? (
                      <TableCell align="center">
                        <TextField
                          id="standard-basic"
                          name="name"
                          defaultValue={d.name}
                          style={{ width: '20vw' }}
                          onChange={handleUpdateChange('name')}
                          multiline
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">{d.name}</TableCell>
                    )}
                    {isItemSelected ? (
                      <TableCell align="right">
                        <Tooltip title="save changes">
                          <IconButton
                            onClick={() => handleUpdate(d.id)}
                            className={classes.showDetailsIconButton}
                          >
                            <CheckIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="cancel">
                          <IconButton
                            onClick={() => deactivateEditMode()}
                            className={classes.showDetailsIconButton}
                          >
                            <ClearIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        <Tooltip title="edit">
                          <IconButton
                            disabled={selected !== -1}
                            onClick={(event) => handleClick(d.id)}
                            className={classes.showDetailsIconButton}
                          >
                            <EditIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="delete">
                          <IconButton
                            className={classes.showDetailsIconButton}
                            onClick={() => handleDelete(d.id)}
                          >
                            <DeleteIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </Paper>
  );
};

export default CategoriesList;
