import { useState } from 'react';
import { defaultOnColumnsChange } from '../components/LazyList/LazyListHelpers';

export type DisplayedColumns = Record<string, boolean>;

export const useColumnsFromLocalStorage = <T>(
  tableName: string,
  ColumnName: T,
  InitialColumnDisplay: DisplayedColumns,
) => {
  const [displayedColumns, setDisplayedColumns] = useState(InitialColumnDisplay);

  const onColumnsChangeCallback = defaultOnColumnsChange(setDisplayedColumns, tableName);

  return {
    displayedColumns,
    onColumnsChangeCallback,
  };
};
