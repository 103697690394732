import { HardwareMatching } from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';
import { hardwareArrayV } from '../HardwareApi/getUserHardware';

export type InventoryFormValues = {
  id: number;
  hardwareMatched: HardwareMatching;
  jitTagForm: string;
  comment: string;
};

export function updateInventory(
  ldapLogin: string,
  inventoryForm: Array<InventoryFormValues>,
  inventoryComment: string,
) {
  const url = `/api/user-inventory/${ldapLogin}`;
  return fetchWrapper({
    url,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { hardwareList: inventoryForm, inventoryComment },
    },
    dataType: hardwareArrayV,
  });
}
