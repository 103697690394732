import React from 'react';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AssignHardwareList from '../../components/AssignHardwareComponents/AssignHardwareList';
import { UserHistory } from '../../services/fetchServices/UserApi/getUserHistory';
import { useUserHardwareData } from './UserHardware/useUserHardwareData';
import UserHistoryTable from './UserHistory/UserHistory';
import UserLicensesList from './UserLicenses/UserLicensesList';
import { useUserLicensesData } from './UserLicenses/useUserLicensesData';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  appBar: {
    backgroundColor: 'inherit',
    border: 'none',
    boxShadow: 'none',
  },
  grid: {
    height: 'auto',
    width: '100%',
    minWidth: 1000,
  },
  bar: {
    width: '100%',
  },
  tabs: {
    width: '100%',
  },
}));

type Props = { ldapLogin: string; id: number; historyData: UserHistory };

const UserDetailsTabs = ({ ldapLogin, id, historyData }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { data, submitUnassignHardware, submitAssignHardware } =
    useUserHardwareData(ldapLogin);
  const { licensesData, submitAssignLicense, submitUnassignLicense } =
    useUserLicensesData(ldapLogin);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.grid}
    >
      <div className={classes.bar}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label="History" {...a11yProps(0)} />
            <Tab label="Licenses" {...a11yProps(1)} />
            <Tab label="Hardware" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </div>
      <div className={classes.tabs}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UserHistoryTable id={id} historyData={historyData} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <UserLicensesList
            licensesData={licensesData}
            submitAssignLicense={submitAssignLicense}
            submitUnassignLicense={submitUnassignLicense}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <AssignHardwareList
            data={data}
            submitUnassignHardware={submitUnassignHardware}
            submitAssignHardware={submitAssignHardware}
          />
        </TabPanel>
      </div>
    </Grid>
  );
};

export default UserDetailsTabs;
