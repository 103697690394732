import React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import {
  Notification,
  notificationStatusLabels,
} from '../../services/domainServices/NotificationService';

export interface Sort {
  name: string;
  sortDirection: string;
}

export type DisplayFunction = (
  filterList: string[][],
  onChange: (e: any) => void,
  index: number,
  column: string,
) => JSX.Element;

export interface Filters extends Record<ColumnName, string[]> {}

export enum ColumnName {
  'name',
  'surname',
  'status',
  'mailingList',
  'additionalInfo',
  'startDate',
  'comment',
  'id',
  'oid',
}

export const NUMBER_OF_NOTIFICATIONS_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.name]: [],
  [ColumnName.surname]: [],
  [ColumnName.status]: [notificationStatusLabels.Pending],
  [ColumnName.mailingList]: [],
  [ColumnName.additionalInfo]: [],
  [ColumnName.startDate]: [],
  [ColumnName.comment]: [],
  [ColumnName.id]: [],
  [ColumnName.oid]: [],
};

export const InitialColumns: DisplayedColumns = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: true,
  [ColumnName[2]]: true,
  [ColumnName[3]]: false,
  [ColumnName[4]]: false,
  [ColumnName[5]]: true,
  [ColumnName[6]]: false,
  [ColumnName[7]]: true,
};

const compareCurrentDateWithStartingDate = (startDateString: any) => {
  if (typeof startDateString !== 'string') {
    return false;
  }
  const [day, month, year] = startDateString.split('/');
  const currentDate = new Date(Date.now());
  const startDate = new Date(Number(year), Number(month) - 1, Number(day));
  const differenceInDays =
    (Number(startDate) - Number(currentDate)) / (1000 * 60 * 60 * 24);

  return differenceInDays <= 7;
};

export function composeColumnsDefinitionForNotifications(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    {
      name: 'name',
      label: 'first name',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        sort: false,
        filter: false,
      },
    },
    {
      name: 'surname',
      label: 'last name',
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        sort: false,
        filter: false,
      },
    },
    {
      name: 'status', // remove this column in new-jiters table
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        sort: false,
        filter: true,
        filterList: activeFilters[ColumnName.status],
        filterOptions: { names: availableFilters[ColumnName.status] },
        customBodyRender: (value: any, tableMeta: any) => {
          const oid = tableMeta.rowData[ColumnName.oid];
          const shouldSetFlag =
            value === 'pending' && oid !== null && oid !== undefined && oid !== '';
          return (
            <div
              style={{
                fontSize: '0.85rem',
                width: '120px',
                overflowWrap: 'break-word',
              }}
            >
              {value}
              {shouldSetFlag ? (
                <Tooltip title="User already exists in LDAP.">
                  <WarningIcon
                    htmlColor="#ff8000"
                    style={{
                      paddingLeft: '10%',
                      marginBottom: '-7.5%',
                      width: '25%',
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: 'mailingList',
      label: 'mailing list',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'additionalInfo',
      label: 'additional info',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        filter: false,
        sort: false,
      },
      overflow: 'wrapAuto',
    },

    {
      name: 'startDate',
      label: 'start date',
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, 'startDate'),
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const status = tableMeta.rowData ? tableMeta.rowData[ColumnName.status] : '';
          const shouldSetFlag =
            status === 'pending' && compareCurrentDateWithStartingDate(value);
          return (
            <div
              style={{
                fontSize: '0.85rem',
                width: '120px',
                overflowWrap: 'break-word',
              }}
            >
              {value}
              {shouldSetFlag ? (
                <Tooltip title="less than 7 days left till start date">
                  <InfoIcon
                    color="error"
                    style={{
                      paddingLeft: '10%',
                      marginBottom: '-5%',
                      width: '25%',
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
    },
    // add fourth filter and set it to be invisible as a workaround for material ui gridlist bug
    {
      name: 'comment',
      label: 'other',
      options: {
        display: displayedColumns[ColumnName[6]].toString(),
        sort: false,
        filter: true,
        filterType: 'custom',
        customFilterListRender: () => false,
        filterOptions: {
          display: () => <div style={{ width: 100 }} />,
        },
      },
      overflow: 'wrapAuto',
    },
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];
}

export function mapNotifications(notification: Notification) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const notificationLocation = notification.location
    ? `location: ${notification.location}\n`
    : '';
  const notificationTechnology = notification.technology
    ? `position: ${notification.technology}\n`
    : '';

  const notificationAssignHardware = notification.assignHardware
    ? 'hardware: required\n'
    : '\n';
  const notificationAssignBoardingPass = notification.assignBoardingPass
    ? 'boarding pass: required\n'
    : '\n';

  const commentString =
    notificationLocation +
    notificationTechnology +
    notificationAssignHardware +
    notificationAssignBoardingPass;

  return [
    notification.firstName,
    notification.lastName,
    notificationStatusLabels[notification.status],
    notification.mailingList.join(', '),
    notification.additionalInfo,
    notification.startDate.toLocaleDateString('en-GB', options),
    commentString,
    notification.id.toString(),
    notification.oid,
  ];
}
