import React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import AddUserForm from '../../components/AddUserForm/AddUserForm';
import {
  initialValues,
  validateAddUserData,
} from '../../components/AddUserForm/AddUserHelpers';
import AddUserHeader from './AddUserHeader';
import { useAddUserData } from './useAddUserData';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: theme.spacing(10),
      maxWidth: 550,
    },
    paper: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: 1500,
    },
    progress: {
      marginLeft: 15,
      position: 'relative',
      marginTop: theme.spacing(3),
      top: 4,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
      textTransform: 'none',
    },
  }),
);

const AddUser: React.FC = () => {
  const classes = useStyles();
  const { submitAddUser } = useAddUserData();
  return (
    <div>
      <AddUserHeader />

      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={initialValues}
            validate={validateAddUserData}
            onSubmit={submitAddUser}
            render={(formikProps) => (
              <Form>
                <AddUserForm formikProps={formikProps} />
                <Grid container item justifyContent="flex-end">
                  {formikProps.isSubmitting ? (
                    <CircularProgress size={24} className={classes.progress} />
                  ) : null}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={formikProps.submitForm}
                    disabled={formikProps.isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            )}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default AddUser;
