import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { protocolV } from '../../domainServices/ProtocolsService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetProtocolsDtoV = t.type({
  hardwareProtocols: t.array(protocolV),
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
});

export type GetProtocolsDto = t.TypeOf<typeof GetProtocolsDtoV>;

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

type ProtocolsListQueryObject = {
  filters: {
    idFilters: string[];
    firstNameFilters: string[];
    lastNameFilters: string[];
    dateFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
};

export function fetchProtocols(
  startingIndex: number,
  protocolsAmount: number,
  queryObject: ProtocolsListQueryObject,
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: protocolsAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.idFilters.forEach((id) => searchParams.append('ID', id));
  queryObject.filters.firstNameFilters.forEach((fn) =>
    searchParams.append('firstName', fn),
  );
  queryObject.filters.lastNameFilters.forEach((ln) =>
    searchParams.append('lastName', ln),
  );
  queryObject.filters.dateFilters.forEach((d) => searchParams.append('creationDate', d));

  return fetchWrapper({
    url: `/api/hardware/protocol?${searchParams.toString()}`,
    dataType: GetProtocolsDtoV,
  });
}
