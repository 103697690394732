import { UpdateUserDto, userV } from '../../domainServices/UserService';
import { fetchWrapper } from '../fetchWrapper';

export function updateUser(ldapLogin: string, user: UpdateUserDto) {
  return fetchWrapper({
    url: `/api/userv2/${ldapLogin}`,
    dataType: userV,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        ...user,
      },
    },
  });
}
