import { toast } from 'react-toastify';
import { addOrganization, AddOrganizationFormValue } from './addOrganization';

export const addOrganizationDtoInitialValues: AddOrganizationFormValue = {
  name: '',
};

export const useAddOrganizationData = () => {
  const initialValues = addOrganizationDtoInitialValues;

  async function submitAddOrganization(values: AddOrganizationFormValue) {
    const res = await addOrganization(values);
    if (res.type === 'success') {
      toast.success('Successfully added organization');
      return res.data;
    }
    toast.error(res.error.message);

    return null;
  }

  return { submitAddOrganization, initialValues };
};
