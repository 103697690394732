import { Office } from '../../domainServices/OfficesService';
import { fetchWrapper } from '../fetchWrapper';

export type AddOfficeFormValues = {
  name: string;
  location?: number;
  organization?: number;
  comment: string;
};

export const mapOfficeToFormValues: (office: Office) => AddOfficeFormValues = (
  office,
) => ({
  name: office.name,
  location: office.location.id,
  organization: office.organization.id,
  comment: office.comment,
});

export interface AddOfficeDto {
  name: string;
  locationId: number;
  organizationId: number;
  comment?: string;
}

export const mapOfficeFormValuesToDto: (values: AddOfficeFormValues) => AddOfficeDto = (
  values,
) => ({
  name: values.name,
  locationId: values.location || 0,
  organizationId: values.organization || 0,
  comment: values.comment,
});

export function addOffice(office: AddOfficeFormValues) {
  const officeDto = mapOfficeFormValuesToDto(office);
  return fetchWrapper({
    url: '/api/office',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: officeDto,
    },
  });
}
