import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { defaultOnDownload } from '../../components/LazyList/LazyListHelpers';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import {
  License,
  licensesTypeLabels,
} from '../../services/domainServices/LicensesService';
import { fetchLicenses } from '../../services/fetchServices/LicensesApi/fetchLicenses';
import { getLicensesReport } from '../../services/fetchServices/LicensesApi/getLicensesReport';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumnDisplay,
  NUMBER_OF_LICENSES_FETCHED,
} from './LicensesListHelpers';
import { useLicensesMetaData } from './useLicensesMetaData';

export type DisplayedColumns = Record<ColumnName, boolean>;

export interface LicensesListState {
  totalLicenses: number;
  filteredLicenses: number;
  loadedLicenses: License[];
  numberOfRequestedLicenses: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
}

const useLicensesListData = (initialFilters?: Filters) => {
  const [state, setState] = useState<LicensesListState>({
    totalLicenses: 0,
    filteredLicenses: 0,
    loadedLicenses: [],
    numberOfRequestedLicenses: 0,
    availableFilters: {
      ...EmptyFilters,
      [ColumnName.LicenseType]: Object.values(licensesTypeLabels),
    },
    searchText: '',
    isFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Licenses',
    ColumnName,
    InitialColumnDisplay,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext('Licenses', {
    activeFilters: initialFilters || EmptyFilters,
    activeSort: { columnName: 'ExpirationDate', sortDirection: 'desc' },
  });

  const { activeFilters, activeSort } = displayedFilters;

  // use ref to access newest values of state from useEffect that resets the data without firing when they change
  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  const licensesMetaData = useLicensesMetaData();
  useUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      availableFilters: {
        ...prevState.availableFilters,
        [ColumnName.OrganizationName]: licensesMetaData.organizations.map((o) => o.name),
      },
    }));
  }, [licensesMetaData]);

  const onDownload = () => {
    getLicensesReport({
      filters: {
        organizationFilters: debouncedFilters[ColumnName.OrganizationName],
        licensesTypeFilters: debouncedFilters[ColumnName.LicenseType],
      },
      sort: {
        direction: activeSort.sortDirection,
        column: activeSort.columnName,
      },
      search: debouncedSearchText,
      columns: Object.entries(displayedColumns)
        .filter((e) => e[1] && e[0] !== 'ExpirationStatus')
        .map((e) => e[0]),
    }).then(defaultOnDownload('LicensesReport.xlsx'));
    return false;
  };

  // after filters or sort change, reset table and fetch licenses (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchLicenses(0, NUMBER_OF_LICENSES_FETCHED, {
        filters: {
          organizationFilters: debouncedFilters[ColumnName.OrganizationName],
          typeFilters: debouncedFilters[ColumnName.LicenseType],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
      });

      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedLicenses: res.data.licenses,
          totalLicenses: res.data.totalRecordsAmount,
          filteredLicenses: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading licenses failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedLicenses increments, make a request for more licenses (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));
      const res = await fetchLicenses(
        curState.loadedLicenses.length,
        NUMBER_OF_LICENSES_FETCHED,
        {
          filters: {
            organizationFilters: debouncedFilters[ColumnName.OrganizationName],
            typeFilters: debouncedFilters[ColumnName.LicenseType],
          },
          sort: {
            direction: curState.activeSort.sortDirection,
            column: curState.activeSort.columnName,
          },
          search: curState.searchText,
        },
      );
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedLicenses: [...prevState.loadedLicenses, ...res.data.licenses],
          totalLicenses: res.data.totalRecordsAmount,
          filteredLicenses: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading licenses failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedLicenses]);

  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    onDownload,
  };
};

export default useLicensesListData;
