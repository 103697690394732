import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import {
  notificationStatusLabels,
  NotificationV,
} from '../../domainServices/NotificationService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetNotificationsDtoV = t.type({
  notifications: t.array(NotificationV),
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
});

export type GetNotificationsDto = t.TypeOf<typeof GetNotificationsDtoV>;

export type CommentColumn = {
  location: string;
  technology: string;
  assignment: string;
  assignHardware: boolean;
  assignBoardingPass: boolean;
};

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

const invertObject = (obj: any) =>
  Object.assign({}, ...Object.entries(obj).map(([a, b]: any) => ({ [b]: a })));

export function fetchNotifications(
  startingIndex: number,
  notificationsAmount: number,
  queryObject: {
    filters: {
      statusFilters: string[];
    };
    sort: {
      direction: keyof typeof sortDirectionMap;
      column: string;
    };
    search: string;
  },
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: notificationsAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.statusFilters.forEach((status) =>
    searchParams.append('Status', invertObject(notificationStatusLabels)[status]),
  );
  return fetchWrapper({
    url: `/api/notification?${searchParams.toString()}`,
    dataType: GetNotificationsDtoV,
  });
}
