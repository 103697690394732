import { toast } from 'react-toastify';
import { UpdateLocation, updateLocation } from './updateLocation';

export const useUpdateLocationData = () => {
  async function submitUpdateLocation(id: number, values: UpdateLocation) {
    const res = await updateLocation(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated location');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitUpdateLocation };
};
