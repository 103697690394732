import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import { Protocol } from '../../services/domainServices/ProtocolsService';

export interface Filters extends Record<ColumnName, string[]> {}

export enum ColumnName {
  'ID',
  'FirstName',
  'LastName',
  'ProtocolType',
  'FilePath',
  'CreationDate',
}

export const NUMBER_OF_PROTOCOLS_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.ID]: [],
  [ColumnName.FirstName]: [],
  [ColumnName.LastName]: [],
  [ColumnName.ProtocolType]: [],
  [ColumnName.CreationDate]: [],
  [ColumnName.FilePath]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: false,
  [ColumnName[1]]: true,
  [ColumnName[2]]: true,
  [ColumnName[3]]: true,
  [ColumnName[4]]: true,
  [ColumnName[5]]: true,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    // add invisible column to have access to data required for redirection to protocols data
    {
      name: ColumnName[0],
      label: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: ColumnName[1],
      label: 'first name',
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
      },
    },
    {
      name: ColumnName[2],
      label: 'last name',
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        filter: true,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[2]),
      },
    },
    {
      name: ColumnName[3],
      label: 'protocol type',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[4],
      label: 'file name',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        filter: false,
        sort: false,
      },
    },
    {
      name: ColumnName[5],
      label: 'creation date',
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[5]),
      },
    },
    {
      name: ColumnName[6],
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];
}

export function mapProtocols(protocols: Protocol) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return [
    protocols.id,
    protocols.firstName,
    protocols.lastName,
    protocols.protocolType,
    protocols.filePath,
    protocols.date.toLocaleDateString('en-GB', options),
  ];
}
