import React from 'react';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import AddUserForm from '../../components/AddUserForm/AddUserForm';
import { useStyles } from '../../components/AddUserForm/AddUserHelpers';
import {
  NotificationStatus,
  notificationStatusLabels,
} from '../../services/domainServices/NotificationService';
import { calendarTheme } from '../../themes/CalendarTheme';
import AddUserFromNotificationHeader from './AddUserFromNotificationHeader';
import { validateAddUserData } from './AddUserFromNotificationHelpers';
import { useNotificationData } from './useNotificationData';

const useNotificationStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(10),
    },
    paper: {
      padding: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
      fontSize: '16px',
      textTransform: 'none',
    },
    border: {
      borderLeft: '1px solid grey',
    },
    title: {
      fontSize: '1.5rem',
      color: 'black',
      paddingBottom: '5%',
    },
    commentField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    switchField: {
      margin: theme.spacing(4, 1, 4, 1),
    },
    formDirection: {
      display: 'flex',
      padding: '2%',
    },
  }),
);

const AddUserFromNotification = () => {
  const { id = '' } = useParams();
  const { initialValues, submitAddUserFromNotification } = useNotificationData(id);
  const addUserClasses = useStyles();
  const notificationClasses = useNotificationStyles();

  return (
    <div>
      <AddUserFromNotificationHeader />
      <Container className={notificationClasses.container} maxWidth="lg">
        <Paper className={notificationClasses.paper}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validateAddUserData}
            onSubmit={submitAddUserFromNotification}
            render={(formikProps) => {
              const { submitForm, values, isSubmitting, setFieldValue } = formikProps;
              return (
                <Form className={notificationClasses.formDirection}>
                  <Grid container direction="column" alignItems="center">
                    <div className={notificationClasses.title}>USER</div>
                    <AddUserForm
                      formikProps={formikProps}
                      passOrgRequired={values.status === 'Realized'}
                      ldapLogin={initialValues.ldapLogin}
                      disableFields
                    />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    className={notificationClasses.border}
                  >
                    <div className={notificationClasses.title}>NOTIFICATION</div>
                    <Grid item>
                      <Field
                        name="status"
                        label="notification status"
                        component={TextField}
                        select
                        id="input-notificationStatus"
                        margin="normal"
                        InputLabelProps={{ shrink: Boolean(values.status) }}
                        className={addUserClasses.commentField}
                        fullWidth
                        required
                      >
                        {Object.keys(notificationStatusLabels).map((status) => (
                          <MenuItem value={status} key={status}>
                            {notificationStatusLabels[status as NotificationStatus]}
                          </MenuItem>
                        ))}
                      </Field>
                      <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={calendarTheme}>
                            <KeyboardDatePicker
                              disableToolbar
                              className={addUserClasses.commentField}
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="none"
                              id="startDate"
                              label="start date"
                              value={values.startDate}
                              onChange={(date) => setFieldValue('startDate', date)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item className={notificationClasses.commentField}>
                        <Field
                          name="technology"
                          label="position"
                          component={TextField}
                          id="input-technology"
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item className={notificationClasses.commentField}>
                        <Field
                          name="location"
                          label="location"
                          component={TextField}
                          id="input-location"
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item className={notificationClasses.commentField}>
                        <Field
                          name="assignment"
                          label="current project"
                          component={TextField}
                          id="input-assignment"
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item className={notificationClasses.commentField}>
                        <Field
                          name="additionalInfo"
                          label="additional info"
                          component={TextField}
                          id="input-additionalInfo"
                          fullWidth
                          multiline
                          maxRows="3"
                          margin="normal"
                        />
                      </Grid>
                      {values.status === 'Realized' && (
                        <>
                          <Grid item className={notificationClasses.commentField}>
                            <Field
                              name="officePassword"
                              label="Office password"
                              required
                              component={TextField}
                              id="input-officePassword"
                              fullWidth
                              margin="normal"
                            />
                          </Grid>
                          <Grid item className={notificationClasses.commentField}>
                            <Field
                              name="wifiPassword"
                              label="WiFi Password"
                              component={TextField}
                              id="input-wifiPassword"
                              fullWidth
                              margin="normal"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item>
                        <div className={notificationClasses.switchField}>
                          <FormControlLabel
                            control={
                              <Field
                                checked={values.assignHardware}
                                value={values.assignHardware}
                                component={Switch}
                                inputProps={{
                                  name: 'assignHardware',
                                  onChange: () => {
                                    setFieldValue(
                                      'assignHardware',
                                      !values.assignHardware,
                                    );
                                  },
                                }}
                                color="primary"
                                id="input-assignHardware"
                                margin="normal"
                              />
                            }
                            label={
                              values.assignHardware
                                ? 'hardware required'
                                : 'hardware not required'
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={notificationClasses.switchField}>
                          <FormControlLabel
                            control={
                              <Field
                                checked={values.assignBoardingPass}
                                value={values.assignBoardingPass}
                                inputProps={{
                                  name: 'assignBoardingPass',
                                  onChange: () => {
                                    setFieldValue(
                                      'assignBoardingPass',
                                      !values.assignBoardingPass,
                                    );
                                  },
                                }}
                                component={Switch}
                                color="primary"
                                id="input-assignBoardingPass"
                                margin="normal"
                              />
                            }
                            label={
                              values.assignBoardingPass
                                ? 'boarding pass required'
                                : 'boarding pass not required'
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container direction="row-reverse" alignItems="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        disabled={
                          isSubmitting ||
                          ['Realized', 'Cancelled'].includes(initialValues.status)
                        }
                        className={notificationClasses.button}
                      >
                        Submit
                      </Button>
                      {isSubmitting ? <CircularProgress size={24} /> : null}
                      {['Realized', 'Cancelled'].includes(initialValues.status) ? (
                        <Typography>
                          You can&apos;t edit submitted notification
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default AddUserFromNotification;
