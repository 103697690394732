/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useHardwareMetaData } from '../../../hooks/useHardwareMetaData';
import { HardwareHistory } from '../../../services/fetchServices/HardwareApi/getHardwareHistory';
import { HardwareHistoryTableHeader } from './HardwareHistoryTableHeader';
import { HardwareHistoryTableToolbar } from './HardwareHistoryTableToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    differencesCell: {
      minWidth: 200,
      maxWidth: 200,
      border: 'none',
      overflowWrap: 'break-word',
    },
  }),
);

type Props = {
  id: number;
  historyData: HardwareHistory;
};

type Attribute = {
  AttributeId: number;
  Value: string;
};

const HardwareHistoryTable = ({ id, historyData }: Props) => {
  const classes = useStyles();
  const metaData = useHardwareMetaData();

  function addSpaceBeforeUpperCase(propertyName: string) {
    const newValue = propertyName.replace(/([A-Z])/g, ' $1').trim();
    return newValue.toLocaleLowerCase().replace('id', '');
  }

  function changeDefaultValueToEmptyString(value: string | string[]) {
    if (
      value === '0' ||
      value === 'null' ||
      value === '1.01.2001' ||
      value === 'Invalid Date'
    )
      return '';
    return value;
  }

  const formatStringOrArray = (value: string | string[]) => {
    let returnValue = value;
    if (returnValue === '[]') returnValue = '';
    return Array.isArray(returnValue)
      ? returnValue.map((v, idx) => (idx !== 0 ? `, ${v}` : v))
      : returnValue;
  };

  const formatProperty = (
    metaDataProperty: {
      name: string;
      id: number;
    }[],
    valueOf: string | string[],
  ) => {
    for (const value in metaDataProperty) {
      if (
        metaDataProperty.hasOwnProperty(value) &&
        Number(valueOf) === metaDataProperty.map((org) => org.id)[value]
      ) {
        return formatStringOrArray(metaDataProperty.map((org) => org.name)[value]);
      }
    }

    return formatStringOrArray(valueOf);
  };

  function mapHardwareHistoryData(propertyName: string, valueOf: string | string[]) {
    if (propertyName === 'OrganizationId') {
      return formatProperty(metaData.organizations, valueOf);
    }
    if (propertyName === 'CategoryId') {
      return formatProperty(metaData.categories, valueOf);
    }
    if (propertyName === 'BrandId') {
      return formatProperty(metaData.brands, valueOf);
    }
    if (propertyName === 'TypeId') {
      return formatProperty(metaData.types, valueOf);
    }
    if (propertyName === 'SupplierId') {
      return formatProperty(metaData.suppliers, valueOf);
    }
    if (propertyName === 'Expansibility') {
      if (valueOf === 'True') {
        return 'yes';
      }
      return 'no';
    }

    if (propertyName === 'Attributes') {
      const allAttributes: string[] = [];
      const parsedAttributes = JSON.parse(valueOf as string) as Attribute[];

      for (const attribute of parsedAttributes) {
        const matchedAttribute = metaData.attributes.find(
          (a) => a.id === attribute.AttributeId,
        );

        if (matchedAttribute !== undefined) {
          const formattedAttribute = `${matchedAttribute.name} ${attribute.Value}`;
          allAttributes.push(formattedAttribute);
        }
      }
      return formatStringOrArray(allAttributes);
    }
    if (propertyName === 'Status' && valueOf === 'InUse') {
      return formatStringOrArray('In Use');
    }

    return formatStringOrArray(valueOf);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <HardwareHistoryTableToolbar />
        <div className={classes.tableWrapper}>
          {historyData.length === 0 ? (
            <Typography paragraph>No history for this hardware</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <HardwareHistoryTableHeader />
              <TableBody>
                {historyData.map((d) => {
                  d.hardwareDifferences.forEach((h) => {
                    h.valueBefore = changeDefaultValueToEmptyString(h.valueBefore);
                    h.valueAfter = changeDefaultValueToEmptyString(h.valueAfter);
                  });

                  d.hardwareDifferences = d.hardwareDifferences.filter(
                    (h) => !(h.valueBefore === '' && h.valueAfter === ''),
                  );

                  const labelId = `enhanced-table-checkbox-${d.actionType}`;

                  if (!d.hardwareDifferences.length) {
                    return null;
                  }

                  return (
                    <TableRow tabIndex={-1}>
                      <TableCell id={labelId} align="left">
                        {d.ldapLogin}
                      </TableCell>
                      <TableCell id={labelId} align="left">
                        {d.actionType}
                      </TableCell>
                      <TableCell align="left">
                        {d.hardwareDifferences.map((diff) => (
                          <TableRow>
                            <TableCell className={classes.differencesCell}>
                              {addSpaceBeforeUpperCase(diff.propertyName)}
                            </TableCell>
                            <TableCell className={classes.differencesCell}>
                              {diff.valueBefore !== ''
                                ? mapHardwareHistoryData(
                                    diff.propertyName,
                                    diff.valueBefore,
                                  )
                                : '-'}
                            </TableCell>
                            <TableCell className={classes.differencesCell}>
                              {diff.valueAfter !== ''
                                ? mapHardwareHistoryData(
                                    diff.propertyName,
                                    diff.valueAfter,
                                  )
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableCell>
                      <TableCell align="left">{d.date.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default HardwareHistoryTable;
