import { AddHardwareFormValues } from '../../services/fetchServices/HardwareApi/addHardware';

export const addHardwareDtoInitialValues: AddHardwareFormValues = {
  type: 0,
  category: 0,
  attributeName: '',
  attributeValue: '',
  attributes: [],
  supplier: 0,
  brand: 0,
  organization: 0,
  assigneeType: 'user',
  assignee: 0,
  parentHardware: '',
  parentHardwareId: '',
  status: 'Free',
  model: '',
  serialNumber: '',
  jitTag: 'JIT-',
  warrantyPeriod: null,
  inventoryDate: null,
  purchasePrice: undefined,
  vatRate: '23',
  usableDepreciation: undefined,
  salesDate: null,
  purchaseDate: null,
  expansibility: true,
  comment: '',
  parentDialogOpen: false,
};

export type FormikValues = typeof addHardwareDtoInitialValues;

export function isPriceNumber(str: string) {
  const n = Math.round(Number(str) * 100) / 100;
  return (
    !Number.isNaN(n) &&
    n >= 0 &&
    (str.indexOf('.') === -1 || (n !== Infinity && n.toFixed(2) === str && n >= 0))
  );
}

export function isPositiveInteger(str: string) {
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

export function validateAddHardwareData(values: AddHardwareFormValues) {
  const errors: Partial<Record<keyof AddHardwareFormValues, string>> = {};
  if (!values.type) {
    errors.type = 'required';
  }
  if (!values.category) {
    errors.category = 'required';
  }
  if (!values.brand) {
    errors.brand = 'required';
  }
  if (!values.supplier) {
    errors.supplier = 'required';
  }
  if (!values.organization) {
    errors.organization = 'required';
  }
  if (!values.model) {
    errors.model = 'required';
  }
  if (!values.serialNumber) {
    errors.serialNumber = 'required';
  }
  if (values.vatRate && !isPriceNumber(values.vatRate)) {
    errors.vatRate = 'incorrect numeric value';
  }
  if (values.purchasePrice && !isPriceNumber(values.purchasePrice)) {
    errors.purchasePrice = 'incorrect numeric value';
  }
  if (values.usableDepreciation && !isPositiveInteger(values.usableDepreciation)) {
    errors.usableDepreciation = 'value must be a positive integer';
  }

  return errors;
}
