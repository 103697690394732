import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import UserLicenseDialog from './UserLicenseDialog';
import { UserLicenseDialogContent } from './UserLicenseDialogContent';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
  }),
);

type AssignLicenseProps = {
  assigning: boolean;
  assignLicense: (id: number) => Promise<void>;
};

export const UserLicenses = ({ assigning, assignLicense }: AssignLicenseProps) => {
  const classes = useToolbarStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={assigning}
        size="small"
      >
        Assign license
      </Button>
      <UserLicenseDialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <UserLicenseDialogContent assignLicense={assignLicense} setOpen={setOpen} />
      </UserLicenseDialog>
    </>
  );
};
