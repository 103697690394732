import React from 'react';
import {
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { Field, FormikProps } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { useHardwareAttributes } from '../../hooks/useHardwareAttributes';
import IntegrationAutosuggest from '../../pages/AddHardware/AutosuggestField';
import { renderChips } from '../AddUserForm/AddUserHelpers';
import { validateAttributes } from './attributesInputHelpers';

export type attributeFormValue = {
  attributeId: number;
  value: string;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attributeNameField: {
      maxWidth: 350,
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeField: {
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeTitleBox: {
      maxWidth: 1000,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      border: '1px solid rgb(186, 186, 186)',
      borderRadius: '4px',
      marginTop: '4%',
      marginBottom: '1%',
    },
    attributeTitle: {
      fontSize: '1.2em',
    },
    addIcon: {
      marginLeft: '4%',
      marginTop: '3.5%',
    },
    attributesField: {
      display: 'flex',
      flexDirection: 'row',
    },
  }),
);

type Props<T> = { formikProps: FormikProps<T>; disabled: boolean };

const AttributesInput = <
  T extends {
    attributes: any[];
    attributeName: number | '';
    attributeValue: string;
  },
>({
  formikProps: { values, setFieldValue, errors, setErrors, setFieldTouched },
  disabled,
}: Props<T>) => {
  const classes = useStyles();
  const attributes = useHardwareAttributes();
  const currentAttribute = attributes.find((a) => a.id === values.attributeName);
  return (
    <fieldset className={classes.attributeTitleBox}>
      <legend className={classes.attributeTitle}>attributes</legend>
      <div className={classes.attributesField}>
        <Field
          name="attributeName"
          label="name"
          select
          component={TextField}
          id="input-attributesName"
          className={classes.attributeNameField}
          margin="normal"
          fullWidth
          disabled={disabled}
        >
          {attributes.map((attribute) => (
            <MenuItem value={attribute.id} key={attribute.id}>
              {attribute.name}
            </MenuItem>
          ))}
        </Field>
        <IntegrationAutosuggest
          defaultValue={values.attributeValue}
          disabled={disabled}
          suggestions={currentAttribute ? currentAttribute.values : []}
          onChange={({ newValue }) => setFieldValue('attributeValue', newValue)}
          errorMessage={errors.attributeValue as string}
        />
        <IconButton
          className={classes.addIcon}
          onClick={() => {
            const attributeErrors = validateAttributes(values);
            if (Object.keys(attributeErrors).length !== 0) {
              setFieldTouched('attributeName', true, false);
              setErrors(attributeErrors);
            } else {
              setFieldValue('attributes', [
                ...values.attributes,
                {
                  attributeId: values.attributeName,
                  value: values.attributeValue,
                },
              ]);
            }
            setFieldValue('attributeName', '');
            setFieldValue('attributeValue', '');
          }}
          disabled={disabled}
        >
          <AddIcon />
        </IconButton>
      </div>
      <FormControl>
        <InputLabel htmlFor="input-attributes" disabled={disabled}>
          attributes
        </InputLabel>
        <Field
          type="text"
          name="attributes"
          disabled
          InputLabelProps={{ shrink: Boolean(values.attributes.length) }}
          component={Select}
          className={classes.attributeField}
          multiple
          fullWidth
          inputProps={{
            name: 'attributes',
            id: 'input-attributesId',
          }}
          renderValue={renderChips<attributeFormValue>(
            (newChips) => setFieldValue('attributes', newChips),
            disabled,
            (value: any) => {
              const attr = attributes.find((a) => a.id === value.attributeId);
              const attrName = attr ? attr.name : '';
              return `${attrName}: ${value.value}`;
            },
          )}
        />
      </FormControl>
    </fieldset>
  );
};

export default AttributesInput;
