import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { FetchResponse } from '../../services/fetchServices/fetchWrapper';
import { HardwareArrayType } from '../../services/fetchServices/HardwareApi/getUserHardware';
import AddCommentForm from './AddCommentForm';
import { AssignHardware } from './AssignHardware';
import CommentDialog from './CommentDialog';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: '#fafafa',
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    tableText: {},
    button: {
      borderRadius: 0,
      textTransform: 'none',
      backgroundColor: '#f2f2f2',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    commentButton: { padding: '4px 40px', marginLeft: '30%' },
  }),
);

interface UserHardwareTableToolbarProps {
  numSelected: number;
  unassignMode: boolean;
  setUnassignMode: (val: boolean) => void;
  assignHardware: (
    hardwareIds: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => Promise<FetchResponse<{}>>;
  unassignHardware: (sendPrivateEmail: boolean, comment: string) => Promise<void>;
  data: HardwareArrayType;
}

export const AssignHardwareTableToolbar = ({
  numSelected,
  setUnassignMode,
  unassignMode,
  assignHardware,
  unassignHardware,
  data,
}: UserHardwareTableToolbarProps) => {
  const [state, setState] = React.useState({
    sendPrivateEmail: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const classes = useToolbarStyles();
  const [assigning, setAssigning] = React.useState(false);
  const [assignComment, setComment] = useState('');
  const [openComment, setOpenComment] = useState(false);
  const assignHardwareCallback = async (
    ids: number[],
    sendPrivateEmail: boolean,
    comment: string,
  ) => {
    setAssigning(true);
    const res = await assignHardware(ids, sendPrivateEmail, comment);
    if (res.type === 'success') {
      toast.success('Hardware assigned successfully');
    }
    setAssigning(false);
  };
  return (
    <Toolbar>
      {unassignMode ? (
        <Typography className={classes.tableText} color="inherit" variant="subtitle1">
          {numSelected} row(s) selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" />
      )}
      {unassignMode ? (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenComment(true)}
            className={classes.commentButton}
          >
            Comment
          </Button>
          <CommentDialog onClose={() => setOpenComment(false)} open={openComment}>
            <AddCommentForm
              comment={assignComment}
              setComment={setComment}
              setOpenComment={setOpenComment}
            />
          </CommentDialog>
          <FormControlLabel
            style={{ marginLeft: 'auto' }}
            control={
              <Checkbox
                checked={state.sendPrivateEmail}
                onChange={handleChange}
                name="sendPrivateEmail"
                color="primary"
              />
            }
            label="Send to private mail"
          />
          <Tooltip title="Unassign">
            <IconButton
              aria-label="delete"
              onClick={async () => {
                setAssigning(true);
                setUnassignMode(false);
                await unassignHardware(state.sendPrivateEmail, assignComment);
                setAssigning(false);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="cancel">
            <IconButton
              aria-label="cancel"
              onClick={() => {
                setUnassignMode(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
          <Grid item>{assigning ? <CircularProgress size={24} /> : null}</Grid>
          <Grid item>
            <AssignHardware
              assigning={assigning}
              assignHardware={assignHardwareCallback}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => setUnassignMode(true)}
              disabled={assigning || data.length === 0}
              size="small"
            >
              Unassign Hardware
            </Button>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};
