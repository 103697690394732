import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import {
  Notification,
  notificationStatusLabels,
} from '../../services/domainServices/NotificationService';
import { fetchNotifications } from '../../services/fetchServices/NotificationApi/fetchNotifications';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumns,
  NUMBER_OF_NOTIFICATIONS_FETCHED,
} from './notificationListHelpers';

export interface NotificationsListState {
  totalNotifications: number;
  filteredNotifications: number;
  loadedNotifications: Notification[];
  numberOfRequestedNotifications: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
}

const useNotificationsListData = () => {
  const [state, setState] = useState<NotificationsListState>({
    totalNotifications: 0,
    filteredNotifications: 0,
    loadedNotifications: [],
    numberOfRequestedNotifications: 0,
    availableFilters: {
      ...EmptyFilters,
      [ColumnName.status]: Object.values(notificationStatusLabels),
    },
    searchText: '',
    isFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Notifications',
    ColumnName,
    InitialColumns,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext('NewJiters', {
    activeFilters: EmptyFilters,
    activeSort: { columnName: 'startDate', sortDirection: 'desc' },
  });

  const { activeFilters, activeSort } = displayedFilters;

  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  // after filters or sort change, reset table and fetch notifications (but not on initial render)
  useEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));
      const res = await fetchNotifications(0, NUMBER_OF_NOTIFICATIONS_FETCHED, {
        filters: {
          statusFilters: debouncedFilters[ColumnName.status],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
      });
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedNotifications: res.data.notifications,
          totalNotifications: res.data.totalRecordsAmount,
          filteredNotifications: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading notifications failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedNotifications increments, make a request for more notifications (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchNotifications(
        curState.loadedNotifications.length,
        NUMBER_OF_NOTIFICATIONS_FETCHED,
        {
          filters: {
            statusFilters: curState.activeFilters[ColumnName.status],
          },
          sort: {
            direction: curState.activeSort.sortDirection,
            column: curState.activeSort.columnName,
          },
          search: curState.searchText,
        },
      );
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedNotifications: [
            ...prevState.loadedNotifications,
            ...res.data.notifications,
          ],
          totalNotifications: res.data.totalRecordsAmount,
          filteredNotifications: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading notifications failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedNotifications]);

  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  };
};

export default useNotificationsListData;
