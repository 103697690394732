import { createStyles, makeStyles, Theme } from '@material-ui/core';

export interface Column {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

export const columns: Column[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'name' },
  { id: 'action', numeric: false, disablePadding: true, label: '' },
];

export const useStylesForSettings = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '30vw',
    },
    paper: {
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    showDetailsIconButton: {
      marginRight: 4,
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: '#999999',
      },
    },
    showDetailsIcon: {
      color: 'grey',
      '&:hover': {
        color: 'white',
      },
    },
    button: {
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
  }),
);
