import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import { downloadHardwareDocument } from '../../services/fetchServices/HardwareApi/HardwareDocuments/downloadHardwareDocument';
import {
  getHardwareDocuments,
  HardwareDocument,
} from '../../services/fetchServices/HardwareApi/HardwareDocuments/getHardwareDocuments';
import { removeHardwareDocument } from '../../services/fetchServices/HardwareApi/HardwareDocuments/removeHardwareDocument';
import { uploadHardwareDocument } from '../../services/fetchServices/HardwareApi/HardwareDocuments/uploadHardwareDocument';
import { defaultOnDownload } from '../LazyList/LazyListHelpers';

export type HardwareDocumentForUpload = Partial<
  Omit<HardwareDocument, 'documentName'> & {
    file: File;
  }
>;

export const useHardwareDocumentsData = (hardwareId: string) => {
  const [uploadedDocuments, setUploadedDocuments] = useState<HardwareDocument[]>([]);

  const refetchDocuments = useCallback(async () => {
    const res = await getHardwareDocuments(hardwareId);
    if (res.type === 'success') {
      setUploadedDocuments(
        res.data.map((d) => {
          const { hardwareId: _, ...rest } = d;
          return rest;
        }),
      );
    } else {
      toast.error(`Fetching documents failed: ${res.error.message}`);
    }
  }, [hardwareId]);

  // fetch initial data
  useEffect(() => {
    refetchDocuments();
  }, [refetchDocuments]);

  const uploadDocument = async (
    values: HardwareDocumentForUpload,
    { setSubmitting, resetForm }: FormikHelpers<HardwareDocumentForUpload>,
  ) => {
    if (values.file && values.documentType) {
      const { file, documentType, comment } = values;
      setSubmitting(true);
      const res = await uploadHardwareDocument(hardwareId, file, documentType, comment);
      if (res.type === 'success') {
        resetForm();
        refetchDocuments();
        toast.success('Successfully uploaded document');
      } else {
        toast.error(`Uploading document failed: ${res.error.message}`);
      }
    } else {
      toast.error("Couldn't upload a document");
    }
    setSubmitting(false);
  };

  const deleteDocument = async (id: number) => {
    const res = await removeHardwareDocument(hardwareId, id);
    if (res.type === 'success') {
      setUploadedDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
      toast.success('Successfully deleted document');
    } else {
      toast.error(`Deleting document failed: ${res.error.message}`);
    }
  };

  const downloadDocument = (id: number, name: string) => {
    downloadHardwareDocument(Number(hardwareId), id).then(defaultOnDownload(name));
  };

  return {
    uploadedDocuments,
    uploadDocument,
    deleteDocument,
    downloadDocument,
  };
};

export const documentTypeLabels = {
  Purchase: 'Purchase document',
  Sale: 'Sale document',
  Other: 'Other',
};

export const documentTypeLabelsShort = {
  Purchase: 'Purchase',
  Sale: 'Sale',
  Other: 'Other',
};
