import React from 'react';
import { Box, createStyles, IconButton, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  titleText: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  children: React.ReactNode;
}

const DialogWithClose = ({
  onClose,
  open,
  titleText,
  maxWidth = 'xl',
  fullWidth = false,
  children,
}: SimpleDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <MuiDialogTitle>
        <Typography variant="h6">{titleText}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <Box pb={2} pl={2} pr={2}>
        {children}
      </Box>
    </Dialog>
  );
};

export default DialogWithClose;
