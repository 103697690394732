import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { columns } from './settingsHelpers';

const SettingsTableHeader = () => (
  <TableHead>
    <TableRow>
      {columns.map((column) => (
        <TableCell key={column.id} align="center">
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default SettingsTableHeader;
