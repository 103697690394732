import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export type UpdateType = {
  name: string;
};

export const emptyType: UpdateType = {
  name: '',
};

export function updateType(id: number, type: UpdateType) {
  return fetchWrapper({
    url: `/api/hardware/type/${id}`,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: type,
    },
  });
}
