import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { emptyLicense } from '../../services/domainServices/LicensesService';
import { getLicense } from '../../services/fetchServices/LicensesApi/getLicense';
import { getLicenseHistory } from '../../services/fetchServices/LicensesApi/getLicenseHistory';
import {
  emptyUpdateLicenseFormValues,
  mapLicenseToFormValues,
  updateLicense,
  UpdateLicenseFormValues,
} from '../../services/fetchServices/LicensesApi/updateLicense';
import { useLicensesMetaData } from '../LicensesList/useLicensesMetaData';
import { useLicenseHistoryData } from './LicenseHistory/useLicenseHistoryData';

export const useLicenseData = (id: string) => {
  const [data, setData] = useState(emptyLicense);
  const [formData, setFormData] = useState(emptyUpdateLicenseFormValues);
  const metaData = useLicensesMetaData();
  const [isEdit, setIsEdit] = useState(false);

  const { historyData, setHistoryData, setIsLoading } = useLicenseHistoryData(data.id);

  // fetch initial data for details form
  useEffect(() => {
    const fetchData = async () => {
      const res = await getLicense(id);
      if (res.type === 'success') {
        setData(res.data);
        setFormData(mapLicenseToFormValues(res.data));
      } else {
        toast.error(`Fetching license data failed: ${res.error.message}`);
      }
    };
    fetchData();
  }, [id]);

  // formik submit handler
  async function submitUpdateLicense(
    values: UpdateLicenseFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await updateLicense(id, values);
    if (res.type === 'success') {
      await refetchLicenseHistory();
      setData(res.data);
      setIsEdit(false);
      toast.success('Successfully updated license');
    } else {
      toast.error(`Error: ${res.error.message}`);
    }
    setSubmitting(false);
  }

  async function refetchLicenseHistory() {
    setIsLoading(true);
    const licenseHistory = await getLicenseHistory(data.id);
    if (licenseHistory.type === 'success') {
      setHistoryData(licenseHistory.data);
    } else {
      toast.error(licenseHistory.error.message);
    }
    setIsLoading(false);
  }

  return {
    state: { data, metaData, formData, isEditMode: isEdit },
    setIsEdit,
    submitUpdateLicense,
    historyData,
  };
};
