import React from 'react';
import ProtocolsListBody from './ProtocolsListBody';
import ProtocolsListHeader from './ProtocolsListHeader';

const ProtocolsList = () => (
  <div>
    <ProtocolsListHeader />
    <ProtocolsListBody />
  </div>
);

export default ProtocolsList;
