import React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { ActiveSort, getSortDirection } from '../../components/LazyList/LazyListHelpers';
import { DisplayedColumns } from '../../hooks/useColumnsFromLocalStorage';
import { InventoryUser } from '../../services/domainServices/UserService';

export enum ColumnName {
  'userID',
  'name',
  'surname',
  'companyEmail',
  'inventoryStatus',
  'tagsStatus',
  'inventoryComment',
  'inventoryReviewed',
  'ldapLogin',
}

export interface Filters extends Record<ColumnName, string[]> {}

export const NUMBER_OF_USERS_FETCHED = 20;

export const EmptyFilters: Filters = {
  [ColumnName.userID]: [],
  [ColumnName.name]: [],
  [ColumnName.surname]: [],
  [ColumnName.companyEmail]: [],
  [ColumnName.inventoryStatus]: [],
  [ColumnName.tagsStatus]: [],
  [ColumnName.inventoryComment]: [],
  [ColumnName.inventoryReviewed]: [],
  [ColumnName.ldapLogin]: [],
};

export const InitialColumnDisplay: Record<string, boolean> = {
  [ColumnName[0]]: true,
  [ColumnName[1]]: true,
  [ColumnName[2]]: true,
  [ColumnName[3]]: false,
  [ColumnName[4]]: true,
  [ColumnName[5]]: true,
  [ColumnName[6]]: true,
  [ColumnName[7]]: true,
};

export function composeColumnsDefinition(
  activeFilters: Filters,
  availableFilters: Filters,
  activeSort: ActiveSort,
  displayedColumns: DisplayedColumns,
) {
  return [
    {
      name: ColumnName[0],
      label: 'oid number',
      options: {
        display: displayedColumns[ColumnName[0]].toString(),
        filter: false,
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[0]),
      },
      widthUnits: 0.7,
    },
    {
      name: ColumnName[1],
      options: {
        display: displayedColumns[ColumnName[1]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[1]),
        filter: false,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.name],
        customFilterListRender: (v: string) => `Name: ${v}`,
      },
    },
    {
      name: ColumnName[2],
      options: {
        display: displayedColumns[ColumnName[2]].toString(),
        sort: true,
        sortDirection: getSortDirection(activeSort, ColumnName[2]),
        filter: false,
        filterType: 'textField',
        filterList: activeFilters[ColumnName.surname],
        customFilterListRender: (v: string) => `Surname: ${v}`,
      },
    },
    {
      name: ColumnName[3],
      label: 'company email',
      options: {
        display: displayedColumns[ColumnName[3]].toString(),
        filter: false,
        sort: false,
      },
      widthUnits: 1.2,
    },
    {
      name: ColumnName[4],
      label: 'items status',
      options: {
        display: displayedColumns[ColumnName[4]].toString(),
        sort: false,
        filter: true,
        filterList: activeFilters[ColumnName.inventoryStatus],
        filterOptions: { names: availableFilters[ColumnName.inventoryStatus] },
        customBodyRender: (value: any, tableMeta: any) => {
          const addWarning = value === 'Unmatched';
          return (
            <div
              style={{
                fontSize: '0.85rem',
                width: '120px',
                overflowWrap: 'break-word',
              }}
            >
              {value}
              {addWarning ? (
                <Tooltip title="Some user hardwares do not match">
                  <InfoIcon
                    color="error"
                    style={{
                      paddingLeft: '10%',
                      marginBottom: '-7.5%',
                      width: '25%',
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
      widthUnits: 1.2,
    },
    {
      name: ColumnName[5],
      label: 'JIT Tags status',
      options: {
        display: displayedColumns[ColumnName[5]].toString(),
        sort: false,
        filter: true,
        filterList: activeFilters[ColumnName.tagsStatus],
        filterOptions: { names: availableFilters[ColumnName.tagsStatus] },
        customBodyRender: (value: any, tableMeta: any) => {
          const addWarning = value === 'Unmatched';
          return (
            <div
              style={{
                fontSize: '0.85rem',
                width: '120px',
                overflowWrap: 'break-word',
              }}
            >
              {value}
              {addWarning ? (
                <Tooltip title="Some JIT's Tags do not match">
                  <InfoIcon
                    color="error"
                    style={{
                      paddingLeft: '10%',
                      marginBottom: '-7.5%',
                      width: '25%',
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
      widthUnits: 1.2,
    },
    {
      name: ColumnName[6],
      label: 'Comment exists',
      options: {
        display: displayedColumns[ColumnName[6]].toString(),
        sort: true,
        filter: true,
        filterList: activeFilters[ColumnName.inventoryComment],
        filterOptions: { names: availableFilters[ColumnName.inventoryComment] },
      },
    },
    {
      name: ColumnName[7],
      label: 'Reviewed',
      options: {
        sort: false,

        display: displayedColumns[ColumnName[7]].toString(),
        filter: true,
        filterList: activeFilters[ColumnName.inventoryReviewed],
        filterOptions: { names: availableFilters[ColumnName.inventoryReviewed] },
      },
    },
    {
      name: ColumnName[9],
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];
}

export function mapUserToDataRow(user: InventoryUser) {
  return [
    user.oid,
    user.firstName,
    user.lastName,
    user.email,
    user.inventoryStatus,
    user.tagsStatus,
    user.inventoryComment ? 'yes' : 'no',
    user.inventoryReviewed ? 'yes' : 'no',
    user.ldapLogin,
  ];
}
