import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';

export const licensesTypeLabels: Record<LicensesType, string> = {
  Box: 'Box',
  Electronic: 'Electronic',
};

export const licensesKindLabels: Record<LicensesKind, string> = {
  Single: 'Single',
  Collective: 'Collective',
  Unlimited: 'Unlimited',
};

export const LicensesTypeV = t.union([t.literal('Box'), t.literal('Electronic')]);

export const LicensesKindV = t.union([
  t.literal('Single'),
  t.literal('Collective'),
  t.literal('Unlimited'),
]);

export type LicensesType = t.TypeOf<typeof LicensesTypeV>;
export type LicensesKind = t.TypeOf<typeof LicensesKindV>;

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);
const optionalDate = t.union([DateFromISOString, t.null]);

export const licenseV = t.type({
  id: nullableNumberToZero,
  name: nullableString,
  licenseType: LicensesTypeV,
  licenseKind: LicensesKindV,
  organization: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  firstKey: nullableString,
  secondKey: nullableString,
  login: nullableString,
  password: nullableString,
  expirationDate: optionalDate,
  purchasePrice: nullableNumberToZero,
  capacity: nullableNumberToZero,
  available: t.boolean,
  comment: nullableString,
  userLicenses: t.array(
    t.type({
      licenseId: nullableNumberToZero,
      userId: nullableNumberToZero,
      firstName: nullableString,
      lastName: nullableString,
      ldapLogin: nullableString,
    }),
  ),
  expirationStatus: nullableString,
});

export type License = t.TypeOf<typeof licenseV>;

export interface UserLicense {
  licenseId: number;
  userId: number;
  firstName: string;
  lastName: string;
  ldapLogin: string;
}

export const emptyLicense: License = {
  id: 0,
  name: '',
  licenseType: 'Box',
  licenseKind: 'Single',
  organization: {
    id: 0,
    name: '',
  },
  firstKey: '',
  secondKey: '',
  login: '',
  password: '',
  expirationDate: new Date(),
  purchasePrice: 0,
  capacity: 0,
  available: true,
  comment: '',
  userLicenses: [
    {
      licenseId: 0,
      userId: 0,
      firstName: '',
      lastName: '',
      ldapLogin: '',
    },
  ],
  expirationStatus: 'active',
};
