import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  AttributesDtoList,
  BrandsDto,
  CategoriesDto,
  fetchAttributes,
  fetchBrands,
  fetchCategories,
  fetchLocations,
  fetchOrganizations,
  fetchSuppliers,
  fetchTypes,
  LocationsDto,
  OrganizationsDto,
  SuppliersDto,
  TypesDto,
} from '../services/fetchServices/HardwareApi/fetchHardwareMetadata';

export type HardwareMetaData = OrganizationsDto &
  BrandsDto &
  CategoriesDto &
  TypesDto &
  SuppliersDto &
  LocationsDto &
  AttributesDtoList;

export function compare(
  a: { name: string; id: number },
  b: { name: string; id: number },
) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export const useHardwareMetaData = () => {
  const [data, setData] = useState<HardwareMetaData>({
    organizations: [],
    brands: [],
    categories: [],
    types: [],
    suppliers: [],
    locations: [],
    attributes: [],
  });

  useEffect(() => {
    const f = async () => {
      const responses = await Promise.all([
        fetchOrganizations(),
        fetchBrands(),
        fetchCategories(),
        fetchTypes(),
        fetchSuppliers(),
        fetchLocations(),
        fetchAttributes(),
      ]);
      if (
        responses[0].type === 'success' &&
        responses[1].type === 'success' &&
        responses[2].type === 'success' &&
        responses[3].type === 'success' &&
        responses[4].type === 'success' &&
        responses[5].type === 'success' &&
        responses[6].type === 'success'
      ) {
        setData({
          organizations: responses[0].data.organizations.sort(compare),
          brands: responses[1].data.sort(compare),
          categories: responses[2].data.sort(compare),
          types: responses[3].data.sort(compare),
          suppliers: responses[4].data.sort(compare),
          locations: responses[5].data.sort(compare),
          attributes: responses[6].data.hardwareAttributeDto.sort(compare),
        });
      } else {
        toast.error('Fetching  hardware metadata failed');
      }
    };
    f();
  }, []);
  return data;
};
