import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../fetchWrapper';

const nullableString = fromNullable(t.string, '');
const nullableBoolean = fromNullable(t.boolean, false);

export const inventoryDataV = t.type({
  inventoryComment: nullableString,
  inventoryReviewed: nullableBoolean,
});

export function getInventoryUserData(ldapLogin: string) {
  return fetchWrapper({
    url: `/api/user/${ldapLogin}/inventory`,
    dataType: inventoryDataV,
  });
}
