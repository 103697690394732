import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { UserHistory } from '../../../services/fetchServices/UserApi/getUserHistory';
import { UserHistoryTableHeader } from './UserHistoryTableHeader';
import { UserHistoryTableToolbar } from './UserHistoryTableToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    differencesCell: {
      minWidth: 200,
      maxWidth: 200,
      border: 'none',
      overflowWrap: 'break-word',
    },
  }),
);

type Props = {
  id: number;
  historyData: UserHistory;
};

export function formatStringOrArray(value: string | string[]) {
  return Array.isArray(value) ? value.map((v, idx) => (idx !== 0 ? `, ${v}` : v)) : value;
}

function addSpaceBeforeUpperCase(value: string) {
  const newValue = value.replace(/([A-Z])/g, ' $1').trim();
  return newValue.toLocaleLowerCase();
}

const UserHistoryTable = ({ id, historyData }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserHistoryTableToolbar />
        <div className={classes.tableWrapper}>
          {historyData.length === 0 ? (
            <Typography paragraph>No history for this user</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <UserHistoryTableHeader />
              <TableBody>
                {historyData.map((d) => {
                  const labelId = `enhanced-table-checkbox-${d.actionType}`;

                  return (
                    <TableRow tabIndex={-1}>
                      <TableCell id={labelId} align="left">
                        {d.ldapLogin}
                      </TableCell>
                      <TableCell id={labelId} align="left">
                        {d.actionType}
                      </TableCell>
                      <TableCell align="left">
                        {d.userDifferences.map((diff) => (
                          <TableRow>
                            <TableCell className={classes.differencesCell}>
                              {addSpaceBeforeUpperCase(diff.propertyName)}
                            </TableCell>
                            <TableCell className={classes.differencesCell}>
                              {formatStringOrArray(diff.valueBefore)}
                            </TableCell>
                            <TableCell className={classes.differencesCell}>
                              {formatStringOrArray(diff.valueAfter)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableCell>
                      <TableCell align="left">{d.date.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default UserHistoryTable;
