/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useState } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DetailsLink } from '../../components/LazyList/DetailsLink';
import LazyList from '../../components/LazyList/LazyList';
import {
  defaultOnDownload,
  defaultOnSearchChange,
} from '../../components/LazyList/LazyListHelpers';
import { getHardwareInventoryReport } from '../../services/fetchServices/InventoryApi/getHardwareInventoryReport';
import InventoryListHeader from './InventoryListHeader';
import {
  ColumnName,
  composeColumnsDefinition,
  mapUserToDataRow,
  NUMBER_OF_USERS_FETCHED,
} from './inventoryListHelpers';
import useInventoryListData from './useInventoryListData';

const useStyles = makeStyles({
  button: {
    position: 'relative',
    top: '10px',
    right: '112px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: ' background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '10px',
    borderRadius: '50%',
    '&:hover': {
      color: '#ffd242',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&:focus': {
      outline: '0',
    },
  },
  spinner: {
    marginRight: '10px',
  },
});

const materialTheme = () =>
  createTheme({
    palette: {
      primary: { main: '#ffd242' },
    },
    typography: {
      fontSize: 18,
      fontFamily: ['ModeratJIT', 'sans-serif'].join(','),
    },
    overrides: {
      MuiPaper: {
        elevation2: {
          width: '30%',
          minWidth: '30%',
          maxWidth: '30%',
        },
      },
      ...(window.location.pathname === '/inventory' && {
        MUIDataTableToolbar: {
          actions: {
            '& > span': {
              position: 'relative',
              right: '-50px',
            },
          },
        },
      }),
    },
  });

const InventoryList = () => {
  const [isFetchingHardwareData, setIsFetchingHardwareData] = useState(false);
  const {
    state: {
      totalUsers,
      filteredUsers,
      loadedUsers,
      activeFilters,
      availableFilters,
      activeSort,
      searchText,
      isFetching,
      displayedColumns,
    },
    setState,
    onColumnsChangeCallback,
    onDownload,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useInventoryListData();

  const onInfiniteScroll = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        numberOfRequestedUsers:
          prevState.numberOfRequestedUsers + NUMBER_OF_USERS_FETCHED,
      })),
    [setState],
  );

  const allActionComponents = (rowData: any[]) => [
    <DetailsLink link={`/inventory/${rowData[ColumnName.ldapLogin]}`} />,
  ];

  const columns = composeColumnsDefinition(
    activeFilters,
    availableFilters,
    activeSort,
    displayedColumns,
  );

  const handleDownloadHardwareInventoryReport = () => {
    setIsFetchingHardwareData(true);
    getHardwareInventoryReport()
      .then(defaultOnDownload('HardwareInventoryReport.xlsx'))
      .finally(() => setIsFetchingHardwareData(false));
  };

  const classes = useStyles();

  const options = {
    filter: true,
    filterType: 'multiselect',
    responsive: 'standard',
    serverSide: true,
    serverSideFilterList: Object.values(activeFilters),
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    selectableRows: 'none',
    rowHover: false,
    searchText,
    onDownload,
    searchOpen: true,
    textLabels: {
      toolbar: {
        downloadCsv: 'Download User Report',
      },
    },
    customToolbar: () => (
      <>
        {window.location.pathname === '/inventory' && (
          <Tooltip title="Download Hardware Report">
            <button
              type="button"
              className={classes.button}
              onClick={handleDownloadHardwareInventoryReport}
            >
              {!isFetchingHardwareData ? (
                <GetAppIcon />
              ) : (
                <CircularProgress className={classes.spinner} size={20} />
              )}
            </button>
          </Tooltip>
        )}
      </>
    ),
  };

  const data = loadedUsers.map(mapUserToDataRow);

  return (
    <div>
      <InventoryListHeader />
      <LazyList
        data={data}
        columns={columns}
        options={options}
        onFilterChange={contextEnchancedOnFiltersChange}
        onSortChange={contextEnchancedOnSortChange}
        onSearchChange={defaultOnSearchChange(setState)}
        onColumnsChange={onColumnsChangeCallback}
        totalItems={totalUsers}
        filteredItems={filteredUsers}
        onInfiniteScrollCallback={onInfiniteScroll}
        isFetching={isFetching}
        actionComponents={allActionComponents}
        muiTheme={materialTheme}
      />
    </div>
  );
};

export default InventoryList;
