import { toast } from 'react-toastify';
import { getHardwareDetailsReport } from '../../services/fetchServices/HardwareApi/getHardwareDetailsReport';

export const useHardwareDataHeader = (id: string) => {
  function generateReport() {
    getHardwareDetailsReport(id).then((res) => {
      if (res.type === 'success') {
        toast.success('Succesfully downloaded report');
      } else {
        toast.error(`Error: ${res.error.message}`);
      }
    });
  }

  return {
    generateReport,
  };
};
