import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const hardwareHistoryDtoV = t.array(
  t.type({
    ldapLogin: t.string,
    hardwareDifferences: t.array(
      t.type({
        propertyName: nullableString,
        valueBefore: nullableString,
        valueAfter: nullableString,
      }),
    ),
    actionType: nullableString,
    date: DateFromISOString,
  }),
);

export type HardwareHistoryDto = t.TypeOf<typeof hardwareHistoryDtoV>;
export type HardwareHistory = {
  ldapLogin: string;
  hardwareDifferences: {
    propertyName: string;
    valueBefore: string | string[];
    valueAfter: string | string[];
  }[];
  actionType: string;
  date: Date;
}[];

export function mapHardwareHistoryData(propertyName: string, value: string) {
  if (
    propertyName === 'PurchaseDate' ||
    propertyName === 'WarrantyPeriod' ||
    propertyName === 'SalesDate' ||
    propertyName === 'InventoryDate'
  ) {
    return new Date(value).toLocaleDateString();
  }
  return value;
}

export function getHardwareHistory(id: number) {
  return fetchWrapper({
    url: `/api/hardware/${id}/history`,
    dataType: hardwareHistoryDtoV,
  });
}

export function parseHistoryData(input: HardwareHistoryDto) {
  const parsed: HardwareHistory = input.map((history) => ({
    ...history,
    hardwareDifferences: history.hardwareDifferences.map((diff) => {
      const valueBefore = mapHardwareHistoryData(diff.propertyName, diff.valueBefore);
      const valueAfter = mapHardwareHistoryData(diff.propertyName, diff.valueAfter);
      return { ...diff, valueBefore, valueAfter };
    }),
  }));
  return parsed;
}
