import React from 'react';
import LicensesListBody from './LicensesListBody';
import LicensesListHeader from './LicensesListHeader';

const LicensesList = () => (
  <div>
    <LicensesListHeader />
    <LicensesListBody />
  </div>
);

export default LicensesList;
