import React from 'react';
import { toast } from 'react-toastify';
import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    showDetailsIconButton: {
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: '#999999',
      },
    },
    showDetailsIcon: {
      color: 'grey',
      '&:hover': {
        color: 'white',
      },
    },
  }),
);

export type DisplayFunction = (
  filterList: string[][],
  index: number,
  column: string,
) => JSX.Element;

export interface TableStateColumn {
  name: string;
  sortDirection: string;
  display: 'true' | 'false';
}

export type ActiveSort = {
  columnName: string;
  sortDirection: string;
};

export interface ColumnsDefinition {
  name: string;
  sort: boolean;
  filter: boolean;
  filterNames?: string[];
  filterType?: string;
  display?: DisplayFunction;
  customFilterListRender?: (v: string) => string | boolean;
}

export const ListWidth = 1615;

export function extractSelectedColumn(tableState: {
  filterList: string[][];
  columns: TableStateColumn[];
}) {
  const selectedColumn = tableState.columns.find(
    (column) => column.sortDirection !== 'none',
  );
  return selectedColumn !== undefined ? selectedColumn : { name: '', sortDirection: '' };
}

export function getSortDirection(activeSort: ActiveSort, name: string) {
  return activeSort.columnName === name ? activeSort.sortDirection : undefined;
}

export const defaultOnFilterChange =
  <T extends object>(setState: React.Dispatch<React.SetStateAction<T>>) =>
  (filters: any) => {
    setState(
      (prevState) =>
        ({
          ...(prevState as {}),
          activeFilters: filters,
        } as T),
    );
  };

export const defaultOnSortChange =
  <T extends object>(setState: React.Dispatch<React.SetStateAction<T>>) =>
  (selectedColumn: TableStateColumn) => {
    setState(
      (prevState) =>
        ({
          ...(prevState as {}),
          activeSort: {
            columnName: selectedColumn.name,
            sortDirection: selectedColumn.sortDirection,
          },
        } as T),
    );
  };

export const defaultOnColumnsChange =
  <T extends any>(setState: React.Dispatch<React.SetStateAction<T>>, tableName: string) =>
  (
    selectedColums: {
      name: string;
      display: 'true' | 'false';
    }[],
  ) => {
    localStorage.setItem(`${tableName}Columns`, JSON.stringify(selectedColums));
    const colObject = Object.assign(
      {},
      ...selectedColums.map((col) => ({ [col.name]: col.display === 'true' })),
    );
    setState(colObject);
  };

export const defaultOnSearchChange =
  <T extends object>(setState: React.Dispatch<React.SetStateAction<T>>) =>
  (search: string) => {
    setState((prevState) => ({ ...(prevState as {}), searchText: search } as T));
  };

export const defaultOnDownload = (name: string) => (res: any) => {
  try {
    if (res.type === 'success') {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      toast.error(`Download failed: ${res.error.message}`);
    }
  } catch (err) {
    toast.error(`Download failed: ${res.error.message}`);
  }
};

export const protocolDefaultOnDownload = (name: string) => (blob: any) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const fechingIndicator = ['Fetching more data...'];

type AddActionsToColumnsProps = {
  actionComponents?: (rowData: string[]) => JSX.Element[];
  columns: any;
};

export function AddActionsToColumns(props: AddActionsToColumnsProps) {
  const { actionComponents, columns } = props;
  return actionComponents
    ? [
        ...columns,
        {
          name: '',
          label: 'actions',
          options: {
            display: 'true',
            sort: false,
            viewColumns: false,
            filter: false,
            customBodyRender: (
              value: string,
              tableMeta: {
                rowData: string[];
              },
            ) =>
              tableMeta.rowData && tableMeta.rowData[0] !== fechingIndicator[0] ? (
                <Grid
                  container
                  spacing={1}
                  style={{
                    fontSize: '0.85rem',
                    width: `${60 * actionComponents(tableMeta.rowData).length}px`,
                    overflowWrap: 'break-word',
                  }}
                >
                  {actionComponents(tableMeta.rowData).map((component) => (
                    <Grid item>{component}</Grid>
                  ))}
                </Grid>
              ) : null,
          },
        },
      ]
    : columns;
}

function setBoxOverflow(overflow: string) {
  return overflow === 'wrapAuto' ? 'auto' : 'hidden';
}

function setTextOverflow(overflow: string) {
  return overflow !== 'wrapAuto' && overflow !== 'wrapHidden';
}

export function setColumnsStyle(columns: any[], tableWidth: number) {
  return columns.map((col) => {
    const label = col.label || col.name;
    const capitalLetterLabel =
      label && typeof label === 'string'
        ? label.charAt(0).toUpperCase() + label.slice(1)
        : '';
    // this guard is important for extracting value from display data in mui-datatables custom toolbar. Don't remove.
    if (col.options.customBodyRender) {
      return {
        ...col,
        label: capitalLetterLabel,
      };
    }
    return {
      ...col,
      label: capitalLetterLabel,
      options: {
        ...col.options,
        customBodyRender: (value: string) => (
          <Box
            style={{
              overflow: setBoxOverflow(col.overflow),
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            <Typography
              variant="caption"
              noWrap={setTextOverflow(col.overflow)}
              style={setTextOverflow(col.overflow) ? {} : { whiteSpace: 'pre-line' }}
            >
              {value}
            </Typography>
          </Box>
        ),
      },
    };
  });
}
