import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createStyles, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FetchResponse } from '../../../services/fetchServices/fetchWrapper';
import { LicensesArrayType } from '../../../services/fetchServices/UserApi/getUserLicenses';
import { UserLicensesTableHeader } from './UserLicensesTableHeader';
import { UserLicensesTableToolbar } from './UserLicensesTableToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    showDetailsIconButton: {
      marginRight: 4,
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: '#999999',
      },
    },
    showDetailsIcon: {
      color: 'grey',
      '&:hover': {
        color: 'white',
      },
    },
  }),
);

type Props = {
  licensesData: LicensesArrayType;
  submitUnassignLicense: (licenseId: number) => Promise<FetchResponse<{}>>;
  submitAssignLicense: (licenseId: number) => Promise<FetchResponse<{}>>;
};
const UserLicensesList = ({
  licensesData,
  submitUnassignLicense,
  submitAssignLicense,
}: Props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserLicensesTableToolbar
          assignLicense={submitAssignLicense}
          data={licensesData}
        />
        <div className={classes.tableWrapper}>
          {licensesData.length === 0 ? (
            <Typography paragraph>No license assigned</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <UserLicensesTableHeader />
              <TableBody>
                {licensesData.map((d) => (
                  <TableRow role="checkbox" tabIndex={-1} key={d.id}>
                    <TableCell align="left">{d.name}</TableCell>
                    <TableCell align="left">{d.licenseType}</TableCell>
                    <TableCell align="left">{d.licenseKind}</TableCell>
                    <TableCell align="left">
                      <Tooltip title="show details">
                        <Link to={`/license/${d.id}`}>
                          <IconButton
                            onClick={() => navigate(`/license/${d.id}`)}
                            className={classes.showDetailsIconButton}
                          >
                            <MoreHorizIcon
                              fontSize="inherit"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="unassign license">
                        <IconButton
                          onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            submitUnassignLicense(d.id);
                          }}
                          className={classes.showDetailsIconButton}
                        >
                          <DeleteIcon
                            fontSize="inherit"
                            className={classes.showDetailsIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default UserLicensesList;
