import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { protocolDefaultOnDownload } from '../../components/LazyList/LazyListHelpers';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { Protocol } from '../../services/domainServices/ProtocolsService';
import {
  createDataResponse,
  createErrorResponse,
} from '../../services/fetchServices/fetchWrapper';
import { downloadSingleProtocol } from '../../services/fetchServices/ProtocolsApi/downloadSingleProtocol';
import { fetchProtocols } from '../../services/fetchServices/ProtocolsApi/fetchProtocols';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumnDisplay,
  NUMBER_OF_PROTOCOLS_FETCHED,
} from './ProtocolsListHelpers';

export type DisplayedColumns = Record<ColumnName, boolean>;

export interface ProtocolsListState {
  totalProtocols: number;
  filteredProtocols: number;
  loadedProtocols: Protocol[];
  numberOfRequestedProtocols: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
}

const useProtocolsListData = (initialFilters?: Filters) => {
  const [state, setState] = useState<ProtocolsListState>({
    totalProtocols: 0,
    filteredProtocols: 0,
    loadedProtocols: [],
    numberOfRequestedProtocols: 0,
    availableFilters: {
      ...EmptyFilters,
    },
    searchText: '',
    isFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Protocols',
    ColumnName,
    InitialColumnDisplay,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext('Protocols', {
    activeFilters: EmptyFilters,
    activeSort: { columnName: 'creationDate', sortDirection: 'desc' },
  });

  const { activeFilters, activeSort } = displayedFilters;

  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  // after filters or sort change, reset table and fetch protocols (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchProtocols(0, NUMBER_OF_PROTOCOLS_FETCHED, {
        filters: {
          idFilters: debouncedFilters[ColumnName.ID],
          firstNameFilters: debouncedFilters[ColumnName.FirstName],
          lastNameFilters: debouncedFilters[ColumnName.LastName],
          dateFilters: debouncedFilters[ColumnName.CreationDate],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
      });

      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedProtocols: res.data.hardwareProtocols,
          totalProtocols: res.data.totalRecordsAmount,
          filteredProtocols: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading protocols failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedProtocols increments, make a request for more protocols (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchProtocols(
        curState.loadedProtocols.length,
        NUMBER_OF_PROTOCOLS_FETCHED,
        {
          filters: {
            idFilters: debouncedFilters[ColumnName.ID],
            firstNameFilters: debouncedFilters[ColumnName.FirstName],
            lastNameFilters: debouncedFilters[ColumnName.LastName],
            dateFilters: debouncedFilters[ColumnName.CreationDate],
          },
          sort: {
            direction: curState.activeSort.sortDirection,
            column: curState.activeSort.columnName,
          },
          search: curState.searchText,
        },
      );
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedProtocols: [...prevState.loadedProtocols, ...res.data.hardwareProtocols],
          totalProtocols: res.data.totalRecordsAmount,
          filteredProtocols: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading protocols failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedProtocols]);

  async function downloadProtocol(id: number) {
    const res = await downloadSingleProtocol(id);

    if (res.type === 'success') {
      toast.success('Successfully downloaded protocol');
      try {
        protocolDefaultOnDownload(res.data.name)(res.data.protocol);
      } catch (err) {
        toast.error('Failed to download protocol');
      }
      return createDataResponse({});
    }
    toast.error(`Failed to assign hardware: ${res.error.message}`);
    return createErrorResponse("Couldn't download protocol");
  }
  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
    downloadProtocol,
  };
};

export default useProtocolsListData;
