import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../fetchWrapper';
import { DocumentTypeEnum } from './getHardwareDocuments';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const UploadHardwareResponseDtoV = t.type({
  hardwareId: nullableNumberToZero,
  documentType: nullableString,
  documentName: nullableString,
});

export function uploadHardwareDocument<T extends File>(
  hardwareId: string,
  document: T,
  type: DocumentTypeEnum,
  comment?: string,
) {
  const formData = new FormData();

  formData.append('file', document);
  formData.append('hardwareId', hardwareId);
  formData.append('documentType', type.toString());
  formData.append('comment', comment || '');

  return fetchWrapper({
    url: `/api/hardware/${hardwareId}/document`,
    dataType: UploadHardwareResponseDtoV,
    isFile: true,
    fetchArg: {
      method: 'POST',
      body: formData,
    },
  });
}
