import React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { DropZone } from './DropZone';
import {
  documentTypeLabels,
  documentTypeLabelsShort,
  HardwareDocumentForUpload,
  useHardwareDocumentsData,
} from './useHardwareDocumentsData';

const useStyles = makeStyles((theme) =>
  createStyles({
    attributeTitleBox: {
      width: '100%',
      border: '1px solid rgb(186, 186, 186)',
      borderRadius: '4px',
      padding: theme.spacing(1),
    },
    table: {
      tableLayout: 'fixed',
      width: '100%',
    },
    button: {
      fontSize: '16px',
      textTransform: 'none',
    },
  }),
);

const HardwareDocuments = ({ id }: { id: string }) => {
  const classes = useStyles();
  const { uploadedDocuments, uploadDocument, deleteDocument, downloadDocument } =
    useHardwareDocumentsData(id);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs>
        <Container maxWidth="xs">
          <FormControl component="fieldset" className={classes.attributeTitleBox}>
            <FormLabel component="legend">upload new document</FormLabel>
            <Formik
              initialValues={{
                documentType: undefined,
                comment: '',
                file: undefined,
              }}
              onSubmit={uploadDocument}
              validate={validateDocument}
              render={({
                submitForm,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                errors,
                touched,
                values,
              }) => (
                <Form>
                  <Grid container direction="column" spacing={3} alignItems="stretch">
                    <Grid item xs>
                      <Field
                        name="documentType"
                        label="type"
                        component={TextField}
                        select
                        id="input-type"
                        required
                        fullWidth
                      >
                        {Object.entries(documentTypeLabels).map(([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs>
                      <Field
                        name="comment"
                        label="comment"
                        component={TextField}
                        id="input-comment"
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <DropZone
                      tempDocument={values.file}
                      setTempDocument={(file) => {
                        setFieldTouched('file');
                        setFieldValue('file', file);
                      }}
                    />
                    <Grid
                      container
                      item
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      {errors.file && touched.file ? (
                        <Typography color="error">file is required</Typography>
                      ) : null}

                      <Grid item>
                        {isSubmitting ? <CircularProgress size={24} /> : null}
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </FormControl>
        </Container>
      </Grid>
      <Grid item xs>
        <Typography>Uploaded documents:</Typography>

        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>document type</TableCell>
              <TableCell>file name</TableCell>
              <TableCell>comment</TableCell>
              <TableCell>actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedDocuments.map((document) => (
              <TableRow key={document.documentName}>
                <TableCell style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={documentTypeLabelsShort[document.documentType]}>
                    <Typography variant="caption" noWrap>
                      {documentTypeLabelsShort[document.documentType]}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={document.documentName}>
                    <Typography variant="caption" noWrap>
                      {document.documentName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={document.comment!}>
                    <Typography variant="caption" noWrap>
                      {document.comment}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Download document" aria-label="add-similar">
                    <IconButton
                      onClick={() => downloadDocument(document.id, document.documentName)}
                    >
                      <CloudDownloadIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete document" aria-label="add-similar">
                    <IconButton onClick={() => deleteDocument(document.id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default HardwareDocuments;

function validateDocument(values: HardwareDocumentForUpload) {
  const errors: Partial<Record<keyof HardwareDocumentForUpload, string>> = {};
  if (!values.documentType) {
    errors.documentType = 'required';
  }
  if (!values.file) {
    errors.file = 'required';
  }
  return errors;
}
