import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { OrganizationsV } from '../fetchServices/fetchOrganizations';
import { officeV } from './OfficesService';
import { userV } from './UserService';

export const hardwareStatusLabels: Record<HardwareStatus, string> = {
  InUse: 'in use',
  FreeWarsaw: 'free in warsaw',
  Free: 'free',
  CSR: 'CSR',
  Used: 'used',
  Unknown: 'unknown',
  Disposed: 'disposed',
  Sold: 'sold',
  Service: 'service',
};

export const HardwareStatusV = t.union([
  t.literal('InUse'),
  t.literal('FreeWarsaw'),
  t.literal('Free'),
  t.literal('CSR'),
  t.literal('Used'),
  t.literal('Unknown'),
  t.literal('Disposed'),
  t.literal('Sold'),
  t.literal('Service'),
]);

export type HardwareStatus = t.TypeOf<typeof HardwareStatusV>;

export const hardwareMatchingLabels: Record<HardwareMatching, string> = {
  Unchecked: 'unchecked',
  Matched: 'matched',
  Unmatched: 'unmatched',
};

export const HardwareMatchingV = t.union([
  t.literal('Unchecked'),
  t.literal('Matched'),
  t.literal('Unmatched'),
]);

export type HardwareMatching = t.TypeOf<typeof HardwareMatchingV>;

const optionalDate = t.union([DateFromISOString, t.null]);
const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);
const nullableNumber = t.union([t.number, t.null]);
const nullableUser = t.union([userV, t.null]);
const nullableOffice = t.union([officeV, t.null]);
const nullableType = t.union([
  t.type({ name: nullableString, id: nullableNumberToZero }),
  t.null,
]);
const nullableOrganization = t.union([OrganizationsV, t.null]);
const nullableBrand = t.union([
  t.type({ name: nullableString, id: nullableNumberToZero }),
  t.null,
]);
const nullableCategory = t.union([
  t.type({ name: nullableString, id: nullableNumberToZero }),
  t.null,
]);
const nullableSupplier = t.union([
  t.type({ name: nullableString, id: nullableNumberToZero }),
  t.null,
]);

export const HardwareV = t.type({
  id: nullableNumberToZero,
  type: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  category: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  attributes: t.array(
    t.type({
      attributeId: nullableNumberToZero,
      value: nullableString,
    }),
  ),
  supplier: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  brand: t.type({
    id: nullableNumberToZero,
    name: nullableString,
  }),
  organization: t.type({ id: nullableNumberToZero, name: nullableString }),
  user: nullableUser,
  office: nullableOffice,
  status: HardwareStatusV,
  model: nullableString,
  serialNumber: nullableString,
  jitTag: nullableString,
  parentHardwareId: nullableNumberToZero,
  warrantyPeriod: optionalDate,
  inventoryDate: optionalDate,
  purchasePrice: nullableNumber,
  purchaseDate: optionalDate,
  vatRate: nullableNumber,
  usableDepreciation: nullableNumber,
  valueAssigned: nullableNumber,
  valueInUse: nullableNumber,
  salesDate: optionalDate,
  expansibility: t.boolean,
  comment: nullableString,
  atHome: t.boolean,
  jitTagMatched: HardwareMatchingV,
  hardwareMatched: HardwareMatchingV,
  inventoryJitTag: nullableString,
  inventoryComment: nullableString,
  hardening: t.boolean,
  version: nullableString,
  hasInstalledLinux: t.boolean,
});

export const createHardwareResponseV = t.type({
  id: nullableNumberToZero,
  type: nullableType,
  category: nullableCategory,
  attributes: t.array(
    t.type({
      attributeId: nullableNumberToZero,
      value: nullableString,
    }),
  ),
  supplier: nullableSupplier,
  brand: nullableBrand,
  organization: nullableOrganization,
  user: nullableUser,
  office: nullableOffice,
  status: HardwareStatusV,
  model: nullableString,
  serialNumber: nullableString,
  jitTag: nullableString,
  parentHardwareId: nullableNumberToZero,
  warrantyPeriod: optionalDate,
  inventoryDate: optionalDate,
  purchasePrice: nullableNumber,
  purchaseDate: optionalDate,
  vatRate: nullableNumber,
  usableDepreciation: nullableNumber,
  valueAssigned: nullableNumber,
  valueInUse: nullableNumber,
  salesDate: optionalDate,
  expansibility: t.boolean,
  comment: nullableString,
  atHome: t.boolean,
  hardening: t.boolean,
  version: nullableString,
  hasInstalledLinux: t.boolean,
});

export type Hardware = t.TypeOf<typeof HardwareV>;

export const HardwareWithHardwareChildrenV = t.intersection([
  HardwareV,
  t.type({
    hardwareChildren: t.array(HardwareV),
  }),
]);

export type HardwareWithHardwareChildren = t.TypeOf<typeof HardwareWithHardwareChildrenV>;

export const emptyHardware: HardwareWithHardwareChildren = {
  id: 0,
  type: {
    id: 0,
    name: '',
  },
  category: {
    id: 0,
    name: '',
  },
  attributes: [
    {
      attributeId: 0,
      value: '',
    },
  ],
  supplier: {
    id: 0,
    name: '',
  },
  brand: {
    id: 0,
    name: '',
  },
  organization: { id: 0, name: '' },
  status: 'InUse',
  user: null,
  office: null,
  model: '',
  serialNumber: '',
  jitTag: '',
  parentHardwareId: 0,
  warrantyPeriod: new Date(),
  inventoryDate: new Date(),
  purchasePrice: 0,
  vatRate: 0,
  usableDepreciation: 0,
  valueAssigned: 0,
  valueInUse: 0,
  salesDate: new Date(),
  purchaseDate: new Date(),
  expansibility: true,
  comment: '',
  hardwareChildren: [],
  atHome: false,
  jitTagMatched: 'Unchecked',
  hardwareMatched: 'Unchecked',
  inventoryJitTag: '',
  inventoryComment: '',
  version: '',
  hardening: false,
  hasInstalledLinux: false,
};
