import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useColumnsFromLocalStorage } from '../../hooks/useColumnsFromLocalStorage';
import { useDebounce } from '../../hooks/useDebounce';
import { useFiltersStateFromContext } from '../../hooks/useFiltersStateFromContext';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { Office } from '../../services/domainServices/OfficesService';
import { fetchOffices } from '../../services/fetchServices/OfficesApi/fetchOffices';
import {
  ColumnName,
  EmptyFilters,
  Filters,
  InitialColumnDisplay,
  NUMBER_OF_OFFICES_FETCHED,
} from './OfficesListHelpers';
import { useOfficesMetaData } from './useOfficesMetaData';

export type DisplayedColumns = Record<ColumnName, boolean>;

export interface OfficesListState {
  totalOffices: number;
  filteredOffices: number;
  loadedOffices: Office[];
  numberOfRequestedOffices: number;
  availableFilters: Filters;
  searchText: string;
  isFetching: boolean;
}

const useOfficesListData = (initialFilters?: Filters) => {
  const [state, setState] = useState<OfficesListState>({
    totalOffices: 0,
    filteredOffices: 0,
    loadedOffices: [],
    numberOfRequestedOffices: 0,
    availableFilters: {
      ...EmptyFilters,
    },
    searchText: '',
    isFetching: false,
  });

  const { displayedColumns, onColumnsChangeCallback } = useColumnsFromLocalStorage(
    'Offices',
    ColumnName,
    InitialColumnDisplay,
  );

  const {
    displayedFilters,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  } = useFiltersStateFromContext('Offices', {
    activeFilters: EmptyFilters,
    activeSort: { columnName: 'Name', sortDirection: 'desc' },
  });

  const { activeFilters, activeSort } = displayedFilters;

  const stateRef = useRef({ ...state, ...displayedFilters });
  stateRef.current = { ...state, ...displayedFilters };

  const debouncedFilters = useDebounce<Filters>(activeFilters, 300);
  const debouncedSearchText = useDebounce<string>(state.searchText, 300);

  // initial get organizations and locations
  const officesMetaData = useOfficesMetaData();
  useUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      availableFilters: {
        ...prevState.availableFilters,
        [ColumnName.Organization]: officesMetaData.organizations.map((o) => o.name),
      },
    }));
  }, [officesMetaData]);

  // after filters or sort change, reset table and fetch offices (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchOffices(0, NUMBER_OF_OFFICES_FETCHED, {
        filters: {
          organizationFilters: debouncedFilters[ColumnName.Organization],
        },
        sort: {
          direction: activeSort.sortDirection,
          column: activeSort.columnName,
        },
        search: debouncedSearchText,
      });

      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedOffices: res.data.offices,
          totalOffices: res.data.totalRecordsAmount,
          filteredOffices: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading offices failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [debouncedFilters, activeSort, debouncedSearchText]);

  // after numberOfRequestedOffices increments, make a request for more offices (but not on initial render)
  useUpdateEffect(() => {
    const f = async () => {
      const curState = stateRef.current;
      if (curState.isFetching) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const res = await fetchOffices(
        curState.loadedOffices.length,
        NUMBER_OF_OFFICES_FETCHED,
        {
          filters: {
            organizationFilters: debouncedFilters[ColumnName.Organization],
          },
          sort: {
            direction: curState.activeSort.sortDirection,
            column: curState.activeSort.columnName,
          },
          search: curState.searchText,
        },
      );
      if (res.type === 'success') {
        setState((prevState) => ({
          ...prevState,
          loadedOffices: [...prevState.loadedOffices, ...res.data.offices],
          totalOffices: res.data.totalRecordsAmount,
          filteredOffices: res.data.filteredRecordsAmount,
          isFetching: false,
        }));
      } else {
        toast.error(`Loading offices failed: ${res.error.message}`);
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };
    f();
  }, [state.numberOfRequestedOffices]);

  return {
    state: { ...state, displayedColumns, ...displayedFilters },
    setState,
    onColumnsChangeCallback,
    contextEnchancedOnFiltersChange,
    contextEnchancedOnSortChange,
  };
};

export default useOfficesListData;
