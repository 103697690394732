import React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useOfficesMetaData } from '../OfficesList/useOfficesMetaData';
import AddOfficeHeader from './AddOfficeHeader';
import { validateAddOfficeData } from './AddOfficeHelpers';
import { useAddOfficeData } from './useAddOfficeData';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      jutifyContent: 'center',
      padding: theme.spacing(2),
      paddingTop: '2%',
      marginTop: '5%',
      color: theme.palette.text.secondary,
      minWidth: 1000,
      width: 1200,
    },
    progress: {
      marginLeft: 15,
      position: 'relative',
      marginTop: theme.spacing(3),
      top: 4,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
      textTransform: 'none',
    },
    commentField: {
      width: 800,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
  }),
);

const AddOffice = () => {
  const classes = useStyles();
  const { initialValues, submitAddOffice } = useAddOfficeData();
  const officesMetaData = useOfficesMetaData();
  return (
    <div>
      <AddOfficeHeader />
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validateAddOfficeData}
            onSubmit={submitAddOffice}
            render={(formikProps) => {
              const { submitForm, isSubmitting, values } = formikProps;
              return (
                <Form>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Field
                      name="name"
                      label="name"
                      component={TextField}
                      id="input-name"
                      className={classes.commentField}
                      margin="normal"
                      required
                    />
                    <Field
                      name="location"
                      label="location"
                      select
                      component={TextField}
                      id="input-locationId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{ shrink: Boolean(values.location) }}
                      margin="normal"
                    >
                      {officesMetaData.locations.map((location) => (
                        <MenuItem value={location.id} key={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="organization"
                      label="organization"
                      select
                      component={TextField}
                      id="input-organizationId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.organization),
                      }}
                      margin="normal"
                    >
                      {officesMetaData.organizations.map((org) => (
                        <MenuItem value={org.id} key={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="comment"
                      label="comment"
                      component={TextField}
                      id="input-comment"
                      className={classes.commentField}
                      margin="normal"
                      multiline
                    />
                    <Grid container item justifyContent="flex-end">
                      {isSubmitting ? (
                        <CircularProgress size={24} className={classes.progress} />
                      ) : null}
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default AddOffice;
