import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const AddBrandV = t.type({
  id: nullableNumberToZero,
  name: nullableString,
});

export type AddBrandFormValue = t.TypeOf<typeof AddBrandV>;

export function addBrand(brand: AddBrandFormValue) {
  return fetchWrapper({
    url: '/api/hardware/brand',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: brand,
    },
  });
}
