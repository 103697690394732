import { ColumnName, Filters } from '../../pages/HardwareList/HardwareListHelpers';

export const FreeHardwareFilters: Filters = {
  [ColumnName.JitTag]: [],
  [ColumnName.Category]: [],
  [ColumnName.Brand]: [],
  [ColumnName.Model]: [],
  [ColumnName.SerialNumber]: [],
  [ColumnName.PurchasePrice]: [],
  [ColumnName.Status]: ['free'],
  [ColumnName.Organization]: [],
  [ColumnName.AssignedTo]: [],
  [ColumnName.AtHome]: [],
  [ColumnName.PriceFrom]: [],
  [ColumnName.PriceTo]: [],
  [ColumnName.id]: [],
  [ColumnName.Hardening]: [],
  [ColumnName.Version]: [],
  [ColumnName.HasInstalledLinux]: [],
};
