import { createDataResponse, createErrorResponse } from '../fetchWrapper';

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

const tagsMap: Record<string, string> = {
  'tags-matched': 'Matched',
  'tags-unmatched': 'Unmatched',
  'tags-unchecked': 'Unchecked',
};

const inventoryMap: Record<string, string> = {
  'inventory-matched': 'Matched',
  'inventory-unmatched': 'Unmatched',
  'inventory-unchecked': 'Unchecked',
};

const columnNamesMap = (name: string) => {
  if (name === 'name') {
    return 'FirstName';
  }
  if (name === 'surname') {
    return 'LastName';
  }
  if (name === 'userID') {
    return 'Oid';
  }
  if (name === 'companyEmail') {
    return 'email';
  }
  return name;
};

type InventoryListQueryObject = {
  filters: {
    inventoryFilters: string[];
    tagsFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
  columns: string[];
};

export async function getInventoryReport(queryObject: InventoryListQueryObject) {
  const params: Record<string, string> = {
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: columnNamesMap(queryObject.sort.column) || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }

  queryObject.filters.inventoryFilters.forEach((inventory) =>
    searchParams.append('InventoryCompatibility', inventoryMap[inventory]),
  );

  queryObject.filters.tagsFilters.forEach((tag) =>
    searchParams.append('TagsCompatibility', tagsMap[tag]),
  );

  queryObject.columns.forEach((column) => {
    if (column) {
      searchParams.append('fields', columnNamesMap(column));
    }
  });

  try {
    const res = await fetch(`/api/report/user-inventory?${searchParams.toString()}`, {
      method: 'GET',
      headers: {
        accept: 'text/plain',
      },
    });
    if (!res.ok) {
      throw new Error("couldn't download report");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
