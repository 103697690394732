import { fetchWrapper } from '../../fetchWrapper';

export function removeHardwareDocument(hardwareId: string, documentId: number) {
  return fetchWrapper({
    url: `/api/hardware/${hardwareId}/document/${documentId}`,
    fetchArg: {
      method: 'DELETE',
    },
  });
}
