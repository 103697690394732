import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface Data {
  type: string;
  category: string;
  brand: string;
  model: string;
  jitTag: string;
  serialNumber: string;
  valueInUse: string;
  atHome: boolean;
  action: any;
}

interface Column {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const columns: Column[] = [
  { id: 'type', numeric: false, disablePadding: true, label: 'type' },
  { id: 'category', numeric: false, disablePadding: true, label: 'category' },
  { id: 'brand', numeric: false, disablePadding: true, label: 'brand' },
  { id: 'model', numeric: false, disablePadding: true, label: 'model' },
  { id: 'jitTag', numeric: false, disablePadding: true, label: 'JIT tag' },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: true,
    label: 'serial number',
  },
  { id: 'valueInUse', numeric: false, disablePadding: true, label: 'current value' },
  { id: 'atHome', numeric: false, disablePadding: true, label: 'at home' },
  { id: 'action', numeric: false, disablePadding: true, label: 'action' },
];

interface AssignHardwareTableHeaderProps {
  numSelected: number;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  rowCount: number;
  unassignMode?: boolean;
}

export const AssignHardwareTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  unassignMode = false,
}: AssignHardwareTableHeaderProps) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        {unassignMode ? (
          <Checkbox
            color="primary"
            checked={numSelected > 0 && rowCount > 0}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        ) : null}
      </TableCell>
      {columns.map((column) => (
        <TableCell key={column.id} align="left">
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
