import { toast } from 'react-toastify';
import { deleteAttribute } from './deleteAttribute';

export const useDeleteAttributeData = () => {
  async function submitDeleteAttribute(id: number) {
    const res = await deleteAttribute(id);
    if (res.type === 'success') {
      toast.success('Successfully deleted attribute');
    } else {
      toast.error(res.error.message);
    }
    return res.type;
  }

  return { submitDeleteAttribute };
};
