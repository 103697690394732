import React from 'react';
import PageHeader from '../../components/PageHeader';

const AddOfficeHeader: React.FC = () => (
  <PageHeader
    title="Add New Office"
    linkText="Return to offices list"
    linkAddress="/offices"
  />
);

export default AddOfficeHeader;
