import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PageHeader, { useStyles } from '../../components/PageHeader';

const HardwareListHeader = () => {
  const classes = useStyles();

  return (
    <PageHeader
      title="Hardware"
      actionButtons={
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          to="/add-hardware"
        >
          Add New Hardware
        </Button>
      }
    />
  );
};

export default HardwareListHeader;
