import React from 'react';
import { useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import AttributesInput from '../../components/AttributesInput/AttributesInput';
import DialogWithClose from '../../components/DialogWithClose';
import { useHardwareMetaData } from '../../hooks/useHardwareMetaData';
import { calendarTheme } from '../../themes/CalendarTheme';
import {
  AssignParentDialogContent,
  ParentHardware,
} from '../HardwareDetails/AssignParentDialogContent';
import AddHardwareHeader from './AddHardwareHeader';
import { validateAddHardwareData } from './AddHardwareHelpers';
import { useAddHardwareData } from './useAddHardwareData';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      jutifyContent: 'center',
      padding: theme.spacing(2),
      paddingTop: '2%',
      marginTop: '5%',
      color: theme.palette.text.secondary,
      minWidth: 1000,
      width: 1200,
    },
    attributesInputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 800,
      borderRadius: '4px',
      paddingRight: '1%',
      paddingBottom: '1%',
      marginTop: '20px',
      marginBottom: '16px',
    },
    progress: {
      marginLeft: 15,
      position: 'relative',
      marginTop: theme.spacing(3),
      top: 4,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
      textTransform: 'none',
    },
    inputField: {
      width: 383,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeNameField: {
      width: 350,
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeIdField: {
      width: 350,
      marginLeft: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeField: {
      width: 775,
      marginTop: '16px',
      marginBottom: '8px',
    },
    commentField: {
      width: 800,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    attributeTitleBox: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid rgb(186, 186, 186)',
      borderRadius: '4px',
      marginTop: '4%',
      marginBottom: '1%',
    },
    attributeTitle: {
      fontSize: '1.2em',
    },
    addIcon: {
      marginLeft: '4%',
      marginTop: '3.5%',
    },
    attributesField: {
      display: 'flex',
      flexDirection: 'row',
    },
    switch: {
      width: 800,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '25px',
      marginBottom: '-15px',
    },
    hiddenField: {
      visibility: 'hidden',
    },
  }),
);

const AddHardware = () => {
  const { id = '' } = useParams();

  const classes = useStyles();
  const { initialValues, submitAddHardware, setRedirectLocation } =
    useAddHardwareData(id);
  const hardwareMetadata = useHardwareMetaData();

  return (
    <div>
      <AddHardwareHeader />
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validateAddHardwareData}
            onSubmit={submitAddHardware}
            render={(formikProps) => {
              const { submitForm, isSubmitting, values, setFieldValue, handleChange } =
                formikProps;
              return (
                <Form>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Field
                      name="type"
                      label="type"
                      select
                      component={TextField}
                      id="input-typeId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{ shrink: Boolean(values.type) }}
                      margin="normal"
                    >
                      {hardwareMetadata.types.map((type) => (
                        <MenuItem value={type.id} key={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="category"
                      label="category"
                      select
                      component={TextField}
                      id="input-categoryId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{ shrink: Boolean(values.category) }}
                      margin="normal"
                    >
                      {hardwareMetadata.categories.map((category) => (
                        <MenuItem value={category.id} key={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="organization"
                      label="organization"
                      select
                      component={TextField}
                      id="input-organizationId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.organization),
                      }}
                      margin="normal"
                    >
                      {hardwareMetadata.organizations.map((org) => (
                        <MenuItem value={org.id} key={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="supplier"
                      label="supplier"
                      select
                      component={TextField}
                      id="input-supplierId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{ shrink: Boolean(values.supplier) }}
                      margin="normal"
                    >
                      {hardwareMetadata.suppliers.map((supplier) => (
                        <MenuItem value={supplier.id} key={supplier.id}>
                          {supplier.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="brand"
                      label="brand"
                      select
                      component={TextField}
                      id="input-brandId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{ shrink: Boolean(values.brand) }}
                      margin="normal"
                    >
                      {hardwareMetadata.brands.map((brand) => (
                        <MenuItem value={brand.id} key={brand.id}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <div className={classes.switch}>
                      <FormControlLabel
                        control={
                          <Field
                            name="expansibility"
                            checked={values.expansibility}
                            component={Switch}
                            color="primary"
                            id="input-expansibility"
                            margin="normal"
                            inputProps={{
                              onChange: (e: any) => {
                                if (e.target.checked) {
                                  setFieldValue('parentHardware', '');
                                }
                                handleChange(e);
                              },
                            }}
                          />
                        }
                        label="expansibility"
                      />
                    </div>
                    <Field
                      name="parentHardware"
                      label="parent hardware"
                      component={TextField}
                      id="input-parentHardware"
                      className={classes.commentField}
                      margin="normal"
                      disabled={values.expansibility}
                      InputProps={{
                        endAdornment: (
                          <>
                            <Tooltip title="assign parent">
                              <IconButton
                                size="small"
                                disabled={values.expansibility}
                                onClick={() => {
                                  setFieldValue('parentDialogOpen', true);
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>
                            <DialogWithClose
                              onClose={() => setFieldValue('parentDialogOpen', false)}
                              open={values.parentDialogOpen}
                              titleText="Select hardware parent"
                              fullWidth
                            >
                              <AssignParentDialogContent
                                assignParent={(parentHardware: ParentHardware) => {
                                  setFieldValue(
                                    'parentHardware',
                                    `${parentHardware.jitTag}, ${parentHardware.category}, ${parentHardware.brand}, ${parentHardware.model}`,
                                  );
                                  setFieldValue('parentHardwareId', parentHardware.id);
                                  setFieldValue('parentDialogOpen', false);
                                }}
                              />
                            </DialogWithClose>
                          </>
                        ),
                      }}
                    />
                    <Field
                      name="model"
                      label="model"
                      component={TextField}
                      id="input-model"
                      className={classes.commentField}
                      margin="normal"
                      required
                    />
                    <Grid
                      container
                      item
                      alignItems="center"
                      className={classes.attributesInputs}
                    >
                      <AttributesInput formikProps={formikProps} disabled={false} />
                    </Grid>
                    <Field
                      name="serialNumber"
                      label="serial number"
                      component={TextField}
                      id="input-serialNumber"
                      className={classes.commentField}
                      margin="normal"
                      required
                    />
                    <Field
                      name="jitTag"
                      label="JIT tag"
                      component={TextField}
                      id="input-jitTag"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="purchasePrice"
                      label="purchase price"
                      component={TextField}
                      id="input-purchasePrice"
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.purchasePrice),
                      }}
                      margin="normal"
                    />
                    <Field
                      name="vatRate"
                      label="VAT rate"
                      component={TextField}
                      id="input-vatRate"
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.vatRate),
                      }}
                      margin="normal"
                    />
                    <Field
                      name="usableDepreciation"
                      label="usable depreciation"
                      component={TextField}
                      id="input-vatRate"
                      className={classes.commentField}
                      margin="normal"
                      InputLabelProps={{
                        shrink: Boolean(values.usableDepreciation),
                      }}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={calendarTheme}>
                        <KeyboardDatePicker
                          disableToolbar
                          className={classes.commentField}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="purchaseDate"
                          label="purchase date"
                          value={values.purchaseDate}
                          onChange={(date) => setFieldValue('purchaseDate', date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={calendarTheme}>
                        <KeyboardDatePicker
                          disableToolbar
                          className={classes.commentField}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="warrantyPeriod"
                          label="warranty period"
                          value={values.warrantyPeriod}
                          onChange={(date) => setFieldValue('warrantyPeriod', date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={calendarTheme}>
                        <KeyboardDatePicker
                          disableToolbar
                          className={classes.commentField}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="inventoryDate"
                          label="inventory date"
                          value={values.inventoryDate}
                          onChange={(date) => setFieldValue('inventoryDate', date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={calendarTheme}>
                        <KeyboardDatePicker
                          disableToolbar
                          className={classes.commentField}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="salesDate"
                          label="sales date"
                          value={values.salesDate}
                          onChange={(date) => setFieldValue('salesDate', date)}
                          minDate={values.purchaseDate}
                          minDateMessage="the date of sale cannot be earlier than the date of purchase"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <Field
                      name="comment"
                      label="comment"
                      component={TextField}
                      id="input-comment"
                      className={classes.commentField}
                      margin="normal"
                      multiline
                    />
                    <Field
                      name="parentHardwareId"
                      id="input-parentHardwareId"
                      className={classes.hiddenField}
                      margin="none"
                    />
                    <Grid container item justifyContent="flex-end">
                      {isSubmitting ? (
                        <CircularProgress size={24} className={classes.progress} />
                      ) : null}
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setRedirectLocation(`/add-hardware`);
                          submitForm();
                        }}
                        disabled={isSubmitting}
                      >
                        Add Similar
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setRedirectLocation('/hardware');
                          submitForm();
                        }}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default AddHardware;
