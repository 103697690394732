import React from 'react';
import {
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

export const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      marginLeft: 15,
      position: 'relative',
      marginTop: theme.spacing(3),
      top: 4,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    icon: {
      margin: theme.spacing(1),
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'grey',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
  }),
);

type Props = {
  isEditMode: boolean;
  isSubmitting: boolean;
  submitForm: any;
  setIsEdit: any;
  resetForm: (initialValue?: any) => void;
};

const SaveDetailsIcons = ({
  isEditMode,
  isSubmitting,
  submitForm,
  setIsEdit,
  resetForm,
}: Props) => {
  const classes = useStyles();

  return isEditMode ? (
    <div className={classes.iconContainer}>
      {isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : null}
      <IconButton className={classes.icon} onClick={submitForm}>
        <SaveIcon />
      </IconButton>
      <IconButton
        className={classes.icon}
        onClick={() => {
          resetForm();
          setIsEdit(!isEditMode);
        }}
      >
        <CancelOutlinedIcon />
      </IconButton>
    </div>
  ) : (
    <div className={classes.iconContainer}>
      <IconButton
        className={classes.icon}
        onClick={() => {
          setIsEdit(!isEditMode);
        }}
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};

export default SaveDetailsIcons;
