import { createDataResponse, createErrorResponse } from '../fetchWrapper';
import { licensesTypeLabelsMap } from './fetchLicenses';

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

type LicensesQueryObject = {
  filters: {
    licensesTypeFilters: string[];
    organizationFilters: string[];
  };
  sort: {
    direction: string;
    column: string;
  };
  search: string;
  columns: string[];
};

export async function getLicensesReport(queryObject: LicensesQueryObject) {
  const params: Record<string, string> = {
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column || '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }

  queryObject.filters.licensesTypeFilters.forEach((licenseType) =>
    searchParams.append('LicenseTypes', licensesTypeLabelsMap(licenseType)),
  );

  queryObject.filters.organizationFilters.forEach((organization) =>
    searchParams.append('Organizations', organization),
  );
  queryObject.columns.forEach((column) => searchParams.append('fields', column));

  try {
    const res = await fetch(`/api/report/license?${searchParams.toString()}`, {
      method: 'POST',
      headers: {
        accept: 'text/plain',
      },
    });
    if (!res.ok) {
      throw new Error("couldn't download report");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download report");
  }
}
