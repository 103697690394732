import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { emptyOffice } from '../../services/domainServices/OfficesService';
import { getOffice } from '../../services/fetchServices/OfficesApi/getOffice';
import {
  emptyUpdateOfficeFormValues,
  mapOfficeToFormValues,
  updateOffice,
  UpdateOfficeFormValues,
} from '../../services/fetchServices/OfficesApi/updateOffice';
import { useOfficesMetaData } from '../OfficesList/useOfficesMetaData';

export const useOfficeData = (id: string) => {
  const [data, setData] = useState(emptyOffice);
  const [formData, setFormData] = useState(emptyUpdateOfficeFormValues);
  const metaData = useOfficesMetaData();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getOffice(id);
      if (res.type === 'success') {
        setData(res.data);
        setFormData(mapOfficeToFormValues(res.data));
      } else {
        toast.error(`Fetching office data failed: ${res.error.message}`);
      }
    };
    fetchData();
  }, [id]);

  async function submitUpdateOffice(
    values: UpdateOfficeFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await updateOffice(id, values);
    if (res.type === 'success') {
      setData(res.data);
      setIsEdit(false);
      toast.success('Succesfully updated office');
    } else {
      toast.error(`Error: ${res.error.message}`);
    }
    setSubmitting(false);
  }

  return {
    state: { data, metaData, formData, isEditMode: isEdit },
    setIsEdit,
    submitUpdateOffice,
  };
};
