import { toast } from 'react-toastify';
import { UpdateType, updateType } from './updateType';

export const useUpdateTypeData = () => {
  async function submitUpdateType(id: number, values: UpdateType) {
    const res = await updateType(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated type');
    } else {
      toast.error(res.error.message);
    }
    return res.type;
  }

  return { submitUpdateType };
};
