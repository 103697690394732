import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addOffice,
  AddOfficeFormValues,
} from '../../services/fetchServices/OfficesApi/addOffice';
import { addOfficeDtoInitialValues } from './AddOfficeHelpers';

export const useAddOfficeData = () => {
  const initialValues = addOfficeDtoInitialValues;
  const navigate = useNavigate();

  async function submitAddOffice(
    values: AddOfficeFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await addOffice(values);
    if (res.type === 'success') {
      setSubmitting(false);
      toast.success('Office added successfully');
      navigate('/offices');
    } else {
      setSubmitting(false);
      toast.error(`Adding office failed: ${res.error.message}`);
    }
  }

  return {
    submitAddOffice,
    initialValues,
  };
};
