import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToastProvider = () => (
  <div>
    <ToastContainer autoClose={5000} hideProgressBar />
  </div>
);

export default ToastProvider;
