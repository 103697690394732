import React from 'react';
import { Theme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerColumn: {
      width: 300,
    },
    authorColumn: {
      width: 200,
    },
    differencesColumn: {
      width: 1000,
      border: 'none',
    },
    columns: {
      minWidth: 200,
      maxWidth: 200,
      border: 'none',
    },
  }),
);
export const UserHistoryTableHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.authorColumn}>author</TableCell>
        <TableCell className={classes.headerColumn}>action type</TableCell>
        <TableCell>
          <TableCell className={classes.differencesColumn} align="center">
            differences
            <TableCell className={classes.columns}>name</TableCell>
            <TableCell className={classes.columns}>before</TableCell>
            <TableCell className={classes.columns}>after</TableCell>
          </TableCell>
        </TableCell>
        <TableCell className={classes.headerColumn}>date</TableCell>
      </TableRow>
    </TableHead>
  );
};
