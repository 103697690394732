import { createDataResponse, createErrorResponse } from './fetchWrapper';

export async function logout() {
  const response = await fetch('/api/logout', {
    method: 'POST',
    credentials: 'include',
  });
  if (response.ok) {
    return createDataResponse({});
  }
  return createErrorResponse('Logout failed');
}
