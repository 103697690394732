import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addUser, AddUserFormData } from '../../services/fetchServices/UserApi/addUser';

export const useAddUserData = () => {
  const navigate = useNavigate();

  async function submitAddUser(
    values: AddUserFormData,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await addUser(values);
    if (res.type === 'success') {
      setSubmitting(false);
      toast.success('Successfully saved user');
      navigate('/');
    } else {
      setSubmitting(false);
      toast.error(res.error.message);
    }
  }

  return {
    submitAddUser,
  };
};
