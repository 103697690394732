import { ColumnName, Filters } from '../../LicensesList/LicensesListHelpers';

export const AvailableLicensesFilters: Filters = {
  [ColumnName.Name]: [],
  [ColumnName.Login]: [],
  [ColumnName.LicenseType]: [],
  [ColumnName.ExpirationStatus]: [],
  [ColumnName.ExpirationDate]: [],
  [ColumnName.OrganizationName]: [],
  [ColumnName.Available]: [],
  [ColumnName.AssignedUsers]: [],
  [ColumnName.Id]: [],
};
