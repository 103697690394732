import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { guest, useUserDispatch, useUserState } from '../../context/UserDataProvider';
import { logout } from '../../services/fetchServices/logout';
import { adminTabs, drawerWidth, jiraAtlasLink } from './consts';
import jitLogo from './jit-atlas-logo.svg';

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any;
    _env_: any;
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'black',
      color: 'white',
      justifyContent: 'space-between',
    },
    drawerIcon: {
      color: 'white',
      textDecoration: 'none',
    },
    divider: {
      backgroundColor: 'grey',
      width: '90%',
      justifyContent: 'center',
      marginRight: '5%',
      marginLeft: '5%',
    },
    logo: {
      textAlign: 'center',
      paddingTop: '10%',
      paddingBottom: '5%',
    },
    userName: {
      textAlign: 'center',
    },
    logoutIcon: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
    },
    activeLink: {
      textDecoration: 'underline rgb(255, 210, 66) !important',
    },
    disabledLink: {
      textDecoration: 'none !important',
    },
    button: {
      margin: '1rem',
    },
    drawerLink: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      color: 'white',
      textDecoration: 'none',
      position: 'relative',
      width: '100%',
      textAlign: 'left',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);

const NavigationDrawer = () => {
  const { instance, accounts } = useMsal();
  const classes = useStyles();
  const user = useUserState();
  const setUser = useUserDispatch();

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('atlasToken');
      setUser(guest);
      const logoutRequest = {
        account: accounts[0],
      };
      instance.logoutRedirect(logoutRequest).catch((e) => {
        console.error(e);
      });
    } catch (e) {
      toast.error('Error logging out');
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div>
          <Typography className={classes.logo}>
            <img src={jitLogo} alt="jit-atlas-logo" />
          </Typography>
          <Divider className={classes.divider} />
          <h2 className={classes.userName}>{user.name}</h2>

          <Divider className={classes.divider} />
          <List>
            {adminTabs.map(({ text, icon, to, active }) => (
              <NavLink
                className={({ isActive }) =>
                  `${classes.drawerLink} ${isActive && classes.activeLink} ${
                    !active && classes.disabledLink
                  }`
                }
                to={active ? to : '#'}
                key={to}
              >
                <ListItem button key={text} disabled={!active}>
                  <ListItemIcon className={classes.drawerIcon}>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </NavLink>
            ))}
          </List>
        </div>
        <div className={classes.bottomContainer}>
          <NavLink
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.disabledLink
            }
            to="/"
            onClick={handleLogout}
          >
            <ListItem className={classes.logoutIcon} button key="Log out">
              <ListItemIcon className={classes.drawerIcon}>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </NavLink>
        </div>
      </Drawer>
    </div>
  );
};

export default NavigationDrawer;
