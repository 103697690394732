import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);
const nullableNumber = t.union([t.number, t.null]);
const nullableBoolean = fromNullable(t.boolean, false);

export const userV = t.type({
  id: nullableNumberToZero,
  firstName: nullableString,
  lastName: nullableString,
  active: t.boolean,
  comment: nullableString,
  email: nullableString,
  ldapLogin: nullableString,
  privateEmail: nullableString,
  privatePhone: nullableString,
  country: nullableString,
  phone: nullableString,
  oid: nullableString,
  totalAssignedHardwareValue: nullableNumber,
  totalCurrentHardwareValue: nullableNumber,
  inventoryStatus: nullableString,
  tagsStatus: nullableString,
  inventoryComment: nullableString,
  hasHardwareWithLinux: t.boolean,
  hasAdmin: t.union([t.boolean, t.undefined]),
  hasAdminChangeDate: t.union([DateFromISOString, t.undefined]),
});

export const inventoryUserV = t.type({
  id: nullableNumberToZero,
  firstName: nullableString,
  lastName: nullableString,
  email: nullableString,
  ldapLogin: nullableString,
  oid: nullableString,
  inventoryStatus: nullableString,
  tagsStatus: nullableString,
  inventoryComment: nullableString,
  inventoryReviewed: nullableBoolean,
});

export const extUserV = t.intersection([
  userV,
  t.type({
    location: nullableString,
    employmentFormName: nullableString,
  }),
]);

export type InventoryUser = t.TypeOf<typeof inventoryUserV>;
export type User = t.TypeOf<typeof userV>;
export type ExtUser = t.TypeOf<typeof extUserV>;

export const emptyUser: User = {
  id: 0,
  firstName: '',
  lastName: '',
  active: true,
  comment: '',
  email: '',
  ldapLogin: '',
  privateEmail: '',
  privatePhone: '',
  country: 'Poland (Polska)',
  phone: '',
  oid: '',
  totalAssignedHardwareValue: 0,
  totalCurrentHardwareValue: 0,
  inventoryStatus: '',
  tagsStatus: '',
  inventoryComment: '',
  hasHardwareWithLinux: false,
  hasAdmin: false,
  hasAdminChangeDate: undefined,
};

export const UpdateUserDtoV = t.type({
  active: t.boolean,
  firstName: nullableString,
  lastName: nullableString,
  email: t.union([t.string, t.null]),
  comment: nullableString,
  phone: nullableString,
  privatePhone: nullableString,
  privateEmail: t.union([t.string, t.null]),
  oid: nullableString,
});

export type UpdateUserDto = t.TypeOf<typeof UpdateUserDtoV>;
