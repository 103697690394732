import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PageHeader, { useStyles } from '../../components/PageHeader';

const OfficesListHeader = () => {
  const classes = useStyles();

  return (
    <PageHeader
      title="Offices"
      actionButtons={
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={Link}
          to="/add-office"
        >
          Add New Office
        </Button>
      }
    />
  );
};

export default OfficesListHeader;
