import React, { useState } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Field, Form, Formik, FormikState } from 'formik';
import { AddCommentFormProps, CommentFormInitialValues } from './types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: '100%',
    },
    flexForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    icon: {
      margin: theme.spacing(1),
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'grey',
      '&:hover': {
        color: 'rgb(255, 210, 66)',
      },
    },
  }),
);

const AddCommentForm = ({ comment, setComment, setOpenComment }: AddCommentFormProps) => {
  const classes = useStyles();
  const initialReadOnly = comment !== '';
  const [readOnly, setReadOnly] = useState(initialReadOnly);

  const initialValues: CommentFormInitialValues = {
    comment,
  };

  const handleSubmit = (values: CommentFormInitialValues) => {
    setReadOnly(!readOnly);
    setComment(values.comment);
  };

  const handleCancelClick = (
    resetForm: (
      nextValues?: Partial<FormikState<CommentFormInitialValues>> | undefined,
    ) => void,
  ) => {
    resetForm();
    setReadOnly(!readOnly);
  };

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      render={({ values, submitForm, resetForm, setFieldValue }) => (
        <Form className={classes.flexForm}>
          <Field
            disabled={readOnly}
            name="comment"
            label="comment"
            value={values.comment}
            inputProps={{
              onChange: (e: any) => {
                setFieldValue('comment', e.target.value);
              },
            }}
            component={TextField}
            className={classes.textField}
          />
          {readOnly ? (
            <div className={classes.iconContainer}>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  handleEditClick();
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
          ) : (
            <div className={classes.iconContainer}>
              <IconButton className={classes.icon} onClick={submitForm}>
                <SaveIcon />
              </IconButton>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  handleCancelClick(resetForm);
                }}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </div>
          )}
        </Form>
      )}
    />
  );
};

export default AddCommentForm;
