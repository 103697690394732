import React from 'react';
import PageHeader from '../../components/PageHeader';

const AddLicenseHeader: React.FC = () => (
  <PageHeader
    title="Add New License"
    linkText="Return to license list"
    linkAddress="/licenses"
  />
);

export default AddLicenseHeader;
