/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../../../components/LazyList/LazyListHelpers';
import LicensesListBody from '../../LicensesList/LicensesListBody';
import { ColumnName } from '../../LicensesList/LicensesListHelpers';
import { AvailableLicensesFilters } from './UserLicensesHelpers';

type AssignLicenseProps = {
  assignLicense: (id: number) => Promise<void>;
  setOpen: (value: boolean) => void;
};

export const UserLicenseDialogContent = ({
  assignLicense,
  setOpen,
}: AssignLicenseProps) => {
  const lazyListClasses = useStyles();

  return (
    <Grid container justifyContent="space-between">
      <LicensesListBody
        initialFilters={AvailableLicensesFilters}
        actionComponents={(rowData: string[]) => [
          <Tooltip title="assign license">
            <IconButton
              onClick={() => {
                assignLicense(Number(rowData[ColumnName.Id]));
                setOpen(false);
              }}
              className={lazyListClasses.showDetailsIconButton}
            >
              <AddIcon fontSize="inherit" className={lazyListClasses.showDetailsIcon} />
            </IconButton>
          </Tooltip>,
        ]}
      />
    </Grid>
  );
};
