import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AssignHardwareTableHeader } from '../../../components/AssignHardwareComponents/AssignHardwareTableHeader';
import { HardwareWithHardwareChildren } from '../../../services/domainServices/HardwareService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
      textAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    showDetailsIconButton: {
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: '#999999',
      },
    },
    showDetailsIcon: {
      color: 'grey',
      '&:hover': {
        color: 'white',
      },
    },
    button: {
      borderRadius: 0,
      textTransform: 'none',
      backgroundColor: '#f2f2f2',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

type Props = {
  data: HardwareWithHardwareChildren;
};

const RelatedHardwareList = ({ data }: Props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<number[]>([]);

  const navigate = useNavigate();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.hardwareChildren.map((hc) => hc.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" id="tableTitle" />
          <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
            <Grid item>{null}</Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled
                size="small"
              >
                Assign hardware
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled
                size="small"
              >
                Unassign hardware
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <div className={classes.tableWrapper}>
          {data.hardwareChildren.length === 0 ? (
            <Typography paragraph>No related hardware</Typography>
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <AssignHardwareTableHeader
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={data.hardwareChildren.length}
              />
              <TableBody>
                {data.hardwareChildren.map((hc: any) => {
                  const isItemSelected = isSelected(hc.id);
                  const labelId = `enhanced-table-checkbox-${hc.id}`;

                  return (
                    <TableRow
                      hover={false}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={hc.id}
                    >
                      <TableCell padding="checkbox" />
                      <TableCell id={labelId} align="left">
                        {hc.type.name}
                      </TableCell>
                      <TableCell align="left">{hc.category.name}</TableCell>
                      <TableCell align="left">{hc.brand.name}</TableCell>
                      <TableCell align="left">{hc.model}</TableCell>
                      <TableCell align="left">{hc.jitTag}</TableCell>
                      <TableCell align="left">{hc.serialNumber}</TableCell>
                      <TableCell align="left">{hc.valueInUse}</TableCell>
                      <TableCell align="left">
                        <Tooltip title="show details">
                          <Link to={`/hardware/${hc.id}`}>
                            <IconButton
                              onClick={() => navigate(`/hardware/${hc.id}`)}
                              className={classes.showDetailsIconButton}
                            >
                              <MoreHorizIcon
                                fontSize="inherit"
                                className={classes.showDetailsIcon}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default RelatedHardwareList;
