import React from 'react';
import { useDropzone } from 'react-dropzone';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    containerText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgb(200, 200, 200)',
      borderRadius: '4px',
      width: '100%',
      height: '3rem',
      backgroundColor: 'rgb(200, 200, 200)',
      textAlign: 'center',
    },
  }),
);

export type Props = {
  setTempDocument: (document: File) => void;
  tempDocument: File | undefined;
};

const MAX_FILE_SIZE = 5242880;

export const DropZone = ({ setTempDocument, tempDocument }: Props) => {
  const classes = useStyles();
  const onDrop = (acceptedFiles: File[]) => {
    setTempDocument(acceptedFiles[0]);
  };

  const { isDragActive, getRootProps, getInputProps, isDragReject, fileRejections } =
    useDropzone({
      onDrop,
      accept: '.jpeg,.jpg,.pdf',
      minSize: 0,
      maxSize: MAX_FILE_SIZE,
      multiple: false,
    });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > MAX_FILE_SIZE;

  return (
    <Grid container item direction="column" spacing={1}>
      <Grid item xs>
        <div {...getRootProps()} className={classes.containerText}>
          <input {...getInputProps()} />
          {!isDragActive && !isFileTooLarge && !isDragReject && (
            <p>Click here or drop a file</p>
          )}
          {isDragActive && !isFileTooLarge && <p>Drop it here!</p>}
          {isDragReject && !isDragActive && <p>Only pdf, jpg, jpeg accepted, sorry!</p>}
          {isFileTooLarge && !isDragActive && <p>File is too large</p>}
        </div>
      </Grid>
      <Grid item xs>
        {tempDocument ? <Typography noWrap>{tempDocument.name}</Typography> : null}
      </Grid>
    </Grid>
  );
};
