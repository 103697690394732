import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { UpdateUserDtoV } from './UserService';

export const notificationStatusLabels = {
  Pending: 'pending',
  Realized: 'completed',
  Cancelled: 'cancelled',
};

export const NotificationStatusV = t.union([
  t.literal('Pending'),
  t.literal('Realized'),
  t.literal('Cancelled'),
]);

export type NotificationStatus = t.TypeOf<typeof NotificationStatusV>;

const nullableArrayOfString = fromNullable(t.array(t.string), []);
const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const NotificationV = t.type({
  id: nullableNumberToZero,
  firstName: nullableString,
  lastName: nullableString,
  email: nullableString,
  privatePhone: nullableString,
  location: nullableString,
  technology: nullableString,
  assignment: nullableString,
  assignHardware: t.boolean,
  assignBoardingPass: t.boolean,
  additionalInfo: nullableString,
  mailingList: nullableArrayOfString,
  status: NotificationStatusV,
  startDate: DateFromISOString,
  pendingLdapLogin: nullableString,
  ldapLogin: nullableString,
  oid: nullableString,
});

export type Notification = t.TypeOf<typeof NotificationV>;

export const NotificationWithUserDataV = t.intersection([
  NotificationV,
  t.type({
    active: t.boolean,
    comment: nullableString,
    phone: nullableString,
    countryPrivatePhone: nullableString,
    countryCompanyPhone: nullableString,
    privateEmail: nullableString,
    privatePhone: nullableString,
    wifiPassword: nullableString,
    officePassword: nullableString,
  }),
]);

export type NotificationWithUserData = t.TypeOf<typeof NotificationWithUserDataV>;

export type notificationFormData = Omit<NotificationWithUserData, 'status'> & {
  status: NotificationStatus;
};

export const emptyNotification: Notification = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  privatePhone: '+48 ',
  oid: '',
  location: '',
  technology: '',
  assignment: '',
  assignHardware: true,
  assignBoardingPass: true,
  additionalInfo: '',
  mailingList: [],
  status: 'Pending',
  startDate: new Date(),
  pendingLdapLogin: '',
  ldapLogin: '',
};

export const emptyExtendedNotification: notificationFormData = {
  ...emptyNotification,
  active: true,
  comment: '',
  phone: '+48 ',
  countryPrivatePhone: 'Poland (Polska)',
  countryCompanyPhone: 'Poland (Polska)',
  privatePhone: '+48 ',
  privateEmail: '',
  wifiPassword: '',
  officePassword: '',
};

export const NotificationForUpdateDtoV = t.type({
  user: UpdateUserDtoV,
  ldapLogin: t.string,
  status: NotificationStatusV,
  additionalInfo: t.string,
  startDate: DateFromISOString,
  technology: t.string,
  location: t.string,
  assignment: t.string,
  assignHardware: t.boolean,
  assignBoardingPass: t.boolean,
  wifiPassword: nullableString,
  officePassword: nullableString,
});
export type NotificationForUpdateDto = t.TypeOf<typeof NotificationForUpdateDtoV>;
