import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const AddSupplierV = t.type({
  name: nullableString,
});

export type AddSupplierFormValue = t.TypeOf<typeof AddSupplierV>;

export function addSupplier(supplier: AddSupplierFormValue) {
  return fetchWrapper({
    url: '/api/supplier',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: supplier,
    },
  });
}
