import { toast } from 'react-toastify';
import { deleteSupplier } from './deleteSupplier';

export const useDeleteSupplierData = () => {
  async function submitDeleteSupplier(id: number) {
    const res = await deleteSupplier(id);
    if (res.type === 'success') {
      toast.success('Successfully deleted supplier');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitDeleteSupplier };
};
