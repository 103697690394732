import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const { AZURE_CLIENT_ID, AZURE_TENANT_ID, REDIRECT_URI } = window._env_;

export const msalConfig: Configuration = {
  auth: {
    clientId: `${AZURE_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT_ID}`,
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`api://${AZURE_CLIENT_ID}/app`],
};

export const msalInstance = new PublicClientApplication(msalConfig);
