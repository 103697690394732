import { fetchWrapper } from '../fetchWrapper';

export interface AddUserFormData {
  firstName: string;
  lastName: string;
  active: boolean;
  comment: string;
  email: string;
  ldapLogin: string;
  phone: string;
  privatePhone: string;
  countryCompanyPhone: string;
  countryPrivatePhone: string;
  privateEmail: string;
  oid: string;
}

export function addUser(user: AddUserFormData) {
  const userWithNullPrivateEmail = {
    ...user,
    privateEmail: user.privateEmail === '' ? null : user.privateEmail,
  };
  return fetchWrapper({
    url: '/api/user',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: userWithNullPrivateEmail,
    },
  });
}
