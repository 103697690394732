import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Footer from '../Footer';
import Drawers from '../Navigation/NavigationDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.background.default,
      padding: '24px',
      margin: 0,
      paddingTop: 0,
      minHeight: '98vh',
      boxSizing: 'content-box',
    },
  }),
);

type Props = { children: React.ReactNode };

const DrawerWrapper = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Drawers />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </>
  );
};

export default DrawerWrapper;
