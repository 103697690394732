import { useEffect, useRef } from 'react';

const useUpdateEffect = (func: Function, deps?: readonly any[]) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line
  }, deps);
};

export default useUpdateEffect;
