import React from 'react';
import PageHeader from '../../components/PageHeader';

const OfficeDetailsHeader = () => (
  <PageHeader
    title="Office Details"
    linkText="Return to offices list"
    linkAddress="/offices"
  />
);

export default OfficeDetailsHeader;
