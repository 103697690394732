import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const AddTypeV = t.type({
  name: nullableString,
});

export type AddTypeFormValue = t.TypeOf<typeof AddTypeV>;

export function addType(type: AddTypeFormValue) {
  return fetchWrapper({
    url: '/api/hardware/type',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: type,
    },
  });
}
