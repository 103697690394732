import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from './fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const OrganizationsV = t.type({
  userOrganizations: t.array(nullableString),
});

export function fetchOrganizations() {
  return fetchWrapper({
    url: '/api/user-organization',
    dataType: OrganizationsV,
  });
}
