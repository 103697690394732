import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  LicensesKind,
  licensesKindLabels,
  LicensesType,
  licensesTypeLabels,
} from '../../services/domainServices/LicensesService';
import { calendarTheme } from '../../themes/CalendarTheme';
import { useLicensesMetaData } from '../LicensesList/useLicensesMetaData';
import AddLicenseHeader from './AddLicenseHeader';
import { validateAddLicenseData } from './AddLicenseHelpers';
import { useAddLicenseData } from './useAddLicenseData';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      jutifyContent: 'center',
      padding: theme.spacing(2),
      paddingTop: '2%',
      marginTop: '5%',
      color: theme.palette.text.secondary,
      minWidth: 1000,
      width: 1200,
    },
    progress: {
      marginLeft: 15,
      position: 'relative',
      marginTop: theme.spacing(3),
      top: 4,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontSize: '16px',
      textTransform: 'none',
    },
    commentField: {
      width: 800,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
    halfOfCommentField: {
      width: 390,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '8px',
    },
  }),
);

const expirationStatus = ['active', 'expired', 'lifetime'];

const AddLicense = () => {
  const classes = useStyles();
  const { initialValues, submitAddLicense } = useAddLicenseData();
  const licensesMetaData = useLicensesMetaData();
  return (
    <div>
      <AddLicenseHeader />
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validateAddLicenseData}
            onSubmit={submitAddLicense}
            render={(formikProps) => {
              const { submitForm, isSubmitting, values, setFieldValue } = formikProps;
              return (
                <Form>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Field
                      name="name"
                      label="name"
                      component={TextField}
                      id="input-name"
                      className={classes.commentField}
                      margin="normal"
                      required
                    />
                    <Field
                      name="licenseType"
                      label="type of license"
                      select
                      component={TextField}
                      className={classes.commentField}
                      id="input-licenseType"
                      InputLabelProps={{
                        shrink: Boolean(values.licenseType),
                      }}
                      margin="normal"
                    >
                      {Object.keys(licensesTypeLabels).map((type) => (
                        <MenuItem value={type} key={type}>
                          {licensesTypeLabels[type as LicensesType]}
                        </MenuItem>
                      ))}
                    </Field>
                    <Grid item direction="row">
                      <Field
                        name="licenseKind"
                        label="kind of license"
                        select
                        component={TextField}
                        className={
                          values.licenseKind === 'Collective'
                            ? classes.halfOfCommentField
                            : classes.commentField
                        }
                        id="input-licenseType"
                        InputLabelProps={{
                          shrink: Boolean(values.licenseKind),
                        }}
                        margin="normal"
                      >
                        {Object.keys(licensesKindLabels).map((kind) => (
                          <MenuItem value={kind} key={kind}>
                            {licensesKindLabels[kind as LicensesKind]}
                          </MenuItem>
                        ))}
                      </Field>
                      {values.licenseKind === 'Collective' ? (
                        <Field
                          name="capacity"
                          label="number"
                          type="number"
                          component={TextField}
                          id="input-number"
                          className={classes.halfOfCommentField}
                          margin="normal"
                        />
                      ) : null}
                    </Grid>
                    <Field
                      name="organization"
                      label="organization"
                      select
                      component={TextField}
                      id="input-organizationId"
                      required
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.organization),
                      }}
                      margin="normal"
                    >
                      {licensesMetaData.organizations.map((org) => (
                        <MenuItem value={org.id} key={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="firstKey"
                      label="first key"
                      component={TextField}
                      id="input-firstKey"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="secondKey"
                      label="second key"
                      component={TextField}
                      id="input-secondKey"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="login"
                      label="login"
                      component={TextField}
                      id="input-login"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="password"
                      label="password"
                      component={TextField}
                      id="input-password"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="expirationStatus"
                      label="status"
                      select
                      component={TextField}
                      id="input-status"
                      className={classes.commentField}
                      margin="normal"
                    >
                      {expirationStatus.map((status) => (
                        <MenuItem value={status} key={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Field>
                    {values.expirationStatus === 'lifetime' ? null : (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={calendarTheme}>
                          <KeyboardDatePicker
                            disableToolbar
                            className={classes.commentField}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="expirationDate"
                            label="expiration date"
                            value={values.expirationDate}
                            onChange={(date) => setFieldValue('expirationDate', date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    )}
                    <Field
                      name="purchasePrice"
                      label="purchase price"
                      component={TextField}
                      id="input-purchasePrice"
                      className={classes.commentField}
                      InputLabelProps={{
                        shrink: Boolean(values.purchasePrice),
                      }}
                      margin="normal"
                    />
                    <Field
                      name="invoice"
                      label="invoice"
                      component={TextField}
                      id="input-invoice"
                      className={classes.commentField}
                      margin="normal"
                    />
                    <Field
                      name="comment"
                      label="comment"
                      component={TextField}
                      id="input-comment"
                      className={classes.commentField}
                      margin="normal"
                      multiline
                    />
                    <Grid container item justifyContent="flex-end">
                      {isSubmitting ? (
                        <CircularProgress size={24} className={classes.progress} />
                      ) : null}
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default AddLicense;
