import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellWithIcon: {
      minWidth: '170px',
    },
  }),
);
interface Data {
  type: string;
  category: string;
  brand: string;
  model: string;
  jitTag: string;
  serialNumber: string;
  itemIsCorrect: string;
  comment: string;
}

interface Column {
  id: keyof Data;
  label: string;
  icon?: React.ReactElement;
}

const iconStyle = { width: '15px', height: '15px', marginLeft: '3px' };

const columns: Column[] = [
  { id: 'category', label: 'category' },
  { id: 'brand', label: 'brand' },
  { id: 'model', label: 'model' },
  { id: 'serialNumber', label: 'serial number' },
  {
    id: 'itemIsCorrect',
    label: 'item is correct',
    icon: (
      <Tooltip title="Correct means category, brand, model and serial number are compatible with your device">
        <InfoIcon style={iconStyle} />
      </Tooltip>
    ),
  },
  { id: 'jitTag', label: 'JIT tag' },
  { id: 'comment', label: 'comment' },
];

export const InventoryDetailsHeader = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="center"
            className={column.icon ? classes.cellWithIcon : undefined}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <div>{column.label}</div>
              {column.icon}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
