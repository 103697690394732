import { AddOfficeFormValues } from '../../services/fetchServices/OfficesApi/addOffice';

export const addOfficeDtoInitialValues: AddOfficeFormValues = {
  name: '',
  location: 0,
  organization: 0,
  comment: '',
};

export type FormikValues = typeof addOfficeDtoInitialValues;

export function validateAddOfficeData(values: AddOfficeFormValues) {
  const errors: Partial<Record<keyof AddOfficeFormValues, string>> = {};
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.location) {
    errors.location = 'required';
  }
  if (!values.organization) {
    errors.organization = 'required';
  }

  return errors;
}
