import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  createDataResponse,
  createErrorResponse,
} from '../../../services/fetchServices/fetchWrapper';
import { assignLicenseToUser } from '../../../services/fetchServices/UserApi/assignLicense';
import {
  getUserLicenses,
  LicensesArrayType,
} from '../../../services/fetchServices/UserApi/getUserLicenses';
import { unassignLicenseFromUser } from '../../../services/fetchServices/UserApi/unassignLicenses';

export const useUserLicensesData = (ldapLogin: string) => {
  const [licensesData, setLicensesData] = useState<LicensesArrayType>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refetchAssignedLicenses = useCallback(async () => {
    setIsLoading(true);
    const userLicenses = await getUserLicenses(ldapLogin);
    if (userLicenses.type === 'success') {
      setLicensesData(userLicenses.data);
    } else {
      toast.error(userLicenses.error.message);
    }
    setIsLoading(false);
  }, [ldapLogin]);

  useEffect(() => {
    refetchAssignedLicenses();
  }, [refetchAssignedLicenses]);

  async function submitUnassignLicense(licenseId: number) {
    const res = await unassignLicenseFromUser(ldapLogin, licenseId);
    if (res.type === 'success') {
      await refetchAssignedLicenses();
      toast.success('Successfully unassigned license');
      return createDataResponse({});
    }
    toast.error(`Failed to unassign license: ${res.error.message}`);
    return createErrorResponse("Couldn't unassign license");
  }

  async function submitAssignLicense(licenseId: number) {
    const res = await assignLicenseToUser(ldapLogin, licenseId);
    if (res.type === 'success') {
      await refetchAssignedLicenses();
      return createDataResponse({});
    }
    toast.error(`Failed to assign license: ${res.error.message}`);
    return createErrorResponse("Couldn't assign license");
  }

  return {
    licensesData,
    isLoading,
    setIsLoading,
    submitAssignLicense,
    submitUnassignLicense,
    refetchAssignedLicenses,
  };
};
