import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addLicense,
  AddLicenseFormValues,
} from '../../services/fetchServices/LicensesApi/addLicense';
import { addLicenseDtoInitialValues } from './AddLicenseHelpers';

export const useAddLicenseData = () => {
  const initialValues = addLicenseDtoInitialValues;
  const navigate = useNavigate();

  async function submitAddLicense(
    values: AddLicenseFormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await addLicense(values);
    if (res.type === 'success') {
      setSubmitting(false);
      toast.success('License added successfully');
      navigate('/licenses');
    } else {
      setSubmitting(false);
      toast.error(`Adding license failed: ${res.error.message}`);
    }
  }

  return {
    submitAddLicense,
    initialValues,
  };
};
