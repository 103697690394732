import { toast } from 'react-toastify';
import { UpdateBrand, updateBrand } from './updateBrand';

export const useUpdateBrandData = () => {
  async function submitUpdateBrand(id: number, values: UpdateBrand) {
    const res = await updateBrand(id, values);
    if (res.type === 'success') {
      toast.success('Successfully updated brand');
    } else {
      toast.error(res.error.message);
    }
    return res.type;
  }

  return { submitUpdateBrand };
};
