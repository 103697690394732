import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCountryNameByPhone } from '../../components/AddUserForm/utils';
import { emptyUser, UpdateUserDto } from '../../services/domainServices/UserService';
import { getUser } from '../../services/fetchServices/UserApi/getUser';
import {
  getUserHistory,
  parseHistoryData,
} from '../../services/fetchServices/UserApi/getUserHistory';
import { updateUser } from '../../services/fetchServices/UserApi/updateUser';
import { useUserHistoryData } from './UserHistory/useUserHistoryData';

export const useUserData = (ldapUid: string) => {
  const [data, setData] = useState(emptyUser);
  const [readOnly, setReadOnly] = useState(true);
  const { historyData, setHistoryData, setIsLoading } = useUserHistoryData(data.id);

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser(ldapUid);
      if (user.type === 'success') {
        const anyPhone = user.data.privatePhone || user.data.phone;
        const countryName =
          user.data.privatePhone === '' && user.data.phone === ''
            ? data.country
            : getCountryNameByPhone(anyPhone);
        const userWithCountry = {
          ...user.data,
          country: countryName,
          phone: !user.data.phone.includes('+')
            ? `+48 ${user.data.phone}`
            : user.data.phone,
          privatePhone: !user.data.privatePhone.includes('+')
            ? `+48 ${user.data.privatePhone}`
            : user.data.privatePhone,
        };
        setData((d) => ({ ...d, ...userWithCountry }));
      } else {
        toast.error(user.error.message);
      }
    };
    fetchData();
  }, [ldapUid, data.country]);

  async function submitEditUser(
    values: UpdateUserDto,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) {
    const res = await updateUser(ldapUid, values);
    setSubmitting(false);
    if (res.type === 'success') {
      await refetchUserHistory();
      toast.success('Successfully saved user');
      setReadOnly(true);
    } else {
      toast.error(res.error.message);
    }
  }

  async function refetchUserHistory() {
    setIsLoading(true);
    const userHistory = await getUserHistory(data.id);
    if (userHistory.type === 'success') {
      const parsedHistory = parseHistoryData(userHistory.data);
      setHistoryData(parsedHistory);
    } else {
      toast.error(userHistory.error.message);
    }
    setIsLoading(false);
  }

  return {
    data,
    readOnly,
    setReadOnly,
    submitEditUser,
    historyData,
  };
};
