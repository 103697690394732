import React from 'react';
import PageHeader from '../../components/PageHeader';

const AddUserFromNotificationHeader: React.FC = () => (
  <PageHeader
    title="Add New User"
    linkText="Return to notifications list"
    linkAddress="/notifications"
  />
);

export default AddUserFromNotificationHeader;
