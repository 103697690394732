import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../fetchWrapper';

export const DocumentTypeDtoV = t.union([
  t.literal('Purchase'),
  t.literal('Sale'),
  t.literal('Other'),
]);

export type DocumentTypeEnum = t.TypeOf<typeof DocumentTypeDtoV>;

const nullableString = fromNullable(t.string, '');
const nullableNumberToZero = fromNullable(t.number, 0);

export const HardwareDocumentV = t.type({
  id: nullableNumberToZero,
  documentType: DocumentTypeDtoV,
  documentName: nullableString,
  comment: t.union([nullableString, t.undefined]),
});

export type HardwareDocument = t.TypeOf<typeof HardwareDocumentV>;

export const GetHardwareDocumentDtoV = t.intersection([
  HardwareDocumentV,
  t.type({
    hardwareId: nullableNumberToZero,
  }),
]);
export const GetHardwareDocumentsDtoV = t.array(GetHardwareDocumentDtoV);

export type GetHardwareDocumentDto = t.TypeOf<typeof GetHardwareDocumentDtoV>;
export type HardwareDocumentsDto = t.TypeOf<typeof GetHardwareDocumentsDtoV>;

export function getHardwareDocuments(hardwareId: string) {
  return fetchWrapper({
    url: `/api/hardware/${hardwareId}/document`,
    dataType: GetHardwareDocumentsDtoV,
  });
}
