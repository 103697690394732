import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 0,
      textTransform: 'none',
      color: 'black',
      '&:hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
      fontSize: '16px',
    },
  }),
);

export const HardwareHistoryTableToolbar = () => {
  const classes = useToolbarStyles();
  return (
    <Toolbar>
      <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            disabled // TODO: implement in future
          >
            Generate Report
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};
