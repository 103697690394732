import { createDataResponse, createErrorResponse } from '../../fetchWrapper';

export async function downloadHardwareDocument(hardwareId: number, documentId: number) {
  try {
    const res = await fetch(`/api/hardware/${hardwareId}/document/${documentId}`);
    if (!res.ok) {
      throw new Error("couldn't download document");
    }
    const blob = await res.blob();
    return createDataResponse(blob);
  } catch (err) {
    return createErrorResponse("couldn't download document");
  }
}
