import { fetchWrapper } from '../fetchWrapper';

export function updateInventoryReview(ldapLogin: string, inventoryReviewed: boolean) {
  const url = `/api/user-inventory/${ldapLogin}/review`;
  return fetchWrapper({
    url,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { inventoryReviewed },
    },
  });
}
