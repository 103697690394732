import { toast } from 'react-toastify';
import { deleteOrganization } from './deleteOrganization';

export const useDeleteOrganizationData = () => {
  async function submitDeleteOrganization(id: number) {
    const res = await deleteOrganization(id);
    if (res.type === 'success') {
      toast.success('Successfully deleted organization');
    } else {
      toast.error(res.error.message);
    }

    return res.type;
  }

  return { submitDeleteOrganization };
};
