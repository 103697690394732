import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useStylesForSettings } from '../../../components/SettingsComponents/settingsHelpers';
import SettingsTableHeader from '../../../components/SettingsComponents/SettingsTableHeader';
import { useHardwareAttributes } from '../../../hooks/useHardwareAttributes';
import { AddAttributeFormValue } from './addAttribute';
import { emptyAttribute, UpdateAttribute } from './updateAttribute';
import { addAttributeDtoInitialValues, useAddAttributeData } from './useAddAttributeData';
import { useDeleteAttributeData } from './useDeleteAttributeData';
import { useUpdateAttributeData } from './useUpdateAttributeData';

const AttributesList = () => {
  const classes = useStylesForSettings();
  const data = useHardwareAttributes();
  const { submitDeleteAttribute } = useDeleteAttributeData();
  const { submitAddAttribute } = useAddAttributeData();
  const { submitUpdateAttribute } = useUpdateAttributeData();
  const [selected, setSelected] = React.useState(-1);
  const [values, setValues] = React.useState<AddAttributeFormValue>(
    addAttributeDtoInitialValues,
  );
  const [attributes, setAttributes] = useState(data);
  const [updatedValues, setUpdatedValues] =
    React.useState<UpdateAttribute>(emptyAttribute);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    try {
      const res = await submitAddAttribute(values);
      if (res) {
        setAttributes([
          ...attributes,
          res as {
            id: number;
            name: string;
            hardwareId: number[];
            values: string[];
          },
        ]);
        setValues(addAttributeDtoInitialValues);
      }
    } catch (e: any) {
      toast.error(e.message);
    }

    event.preventDefault();
  };

  const handleUpdate = async (id: number) => {
    try {
      const resType = await submitUpdateAttribute(id, updatedValues);
      if (resType === 'success') {
        setAttributes((prevAttributes) =>
          prevAttributes.map((a) =>
            a.id === id ? { ...a, name: updatedValues.name } : a,
          ),
        );
        deactivateEditMode();
      }
    } catch (e: any) {
      toast.error('Error updating attribute');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const resType = await submitDeleteAttribute(id);
      if (resType === 'success') {
        setAttributes((prevAttributes) => prevAttributes.filter((a) => a.id !== id));
      }
    } catch {
      toast.error('Error deleting attribute');
    }
  };

  const handleUpdateChange =
    (name: keyof UpdateAttribute) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdatedValues({ ...updatedValues, [name]: event.target.value });
    };

  const handleClick = (id: number) => {
    setSelected(id);
    setUpdatedValues({ name: attributes.find((o) => o.id === id)?.name || '' });
  };

  function deactivateEditMode() {
    setSelected(-1);
  }

  useEffect(() => {
    setAttributes(data);
  }, [data]);

  const isSelected = (id: number) => selected === id;

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <SettingsTableHeader />
          {attributes.length === 0 ? (
            <TableRow>
              <TableCell align="center">
                <TextField
                  id="standard-basic"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  style={{ width: '20vw' }}
                  multiline
                />
              </TableCell>
              <TableCell align="center">
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                >
                  Add New
                </Button>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <TextField
                    id="standard-basic"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    style={{ width: '20vw' }}
                    multiline
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Add New
                  </Button>
                </TableCell>
              </TableRow>
              {attributes.map((d) => {
                const isItemSelected = isSelected(d.id);

                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={d.id}
                  >
                    {isItemSelected ? (
                      <TableCell align="center">
                        <TextField
                          id="standard-basic"
                          name="name"
                          defaultValue={d.name}
                          style={{ width: '20vw' }}
                          onChange={handleUpdateChange('name')}
                          multiline
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">{d.name}</TableCell>
                    )}
                    {isItemSelected ? (
                      <TableCell align="right">
                        <Tooltip title="save changes">
                          <IconButton
                            onClick={() => handleUpdate(d.id)}
                            className={classes.showDetailsIconButton}
                          >
                            <CheckIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="cancel">
                          <IconButton
                            onClick={() => deactivateEditMode()}
                            className={classes.showDetailsIconButton}
                          >
                            <ClearIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        <Tooltip title="edit">
                          <IconButton
                            onClick={(event) => handleClick(d.id)}
                            className={classes.showDetailsIconButton}
                            disabled={selected !== -1}
                          >
                            <EditIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="delete">
                          <IconButton
                            className={classes.showDetailsIconButton}
                            onClick={() => handleDelete(d.id)}
                          >
                            <DeleteIcon
                              fontSize="small"
                              className={classes.showDetailsIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </Paper>
  );
};

export default AttributesList;
