import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

export type UpdateAttribute = {
  name: string;
};

export const emptyAttribute: UpdateAttribute = {
  name: '',
};

export function updateAttribute(id: number, attribute: UpdateAttribute) {
  return fetchWrapper({
    url: `/api/hardware/attribute/${id}`,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: attribute,
    },
  });
}
