import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { userV } from '../../domainServices/UserService';
import { fetchWrapper } from '../fetchWrapper';

const nullableNumberToZero = fromNullable(t.number, 0);

export const GetUsersDtoV = t.type({
  totalRecordsAmount: nullableNumberToZero,
  filteredRecordsAmount: nullableNumberToZero,
  users: t.array(userV),
});

export type GetUsersDto = t.TypeOf<typeof GetUsersDtoV>;

const statusMap: Record<string, string> = {
  active: 'true',
  inactive: 'false',
};

const sortDirectionMap: Record<string, string> = {
  desc: 'Desc',
  asc: 'Asc',
};

const columnNamesMap: Record<string, string> = {
  name: 'FirstName',
  surname: 'LastName',
  userID: 'Id',
  totalAssignedHardwareValue: 'TotalAssignedHardwareValue',
  totalCurrentHardwareValue: 'TotalCurrentHardwareValue',
};

export function getUsers(
  startingIndex: number,
  usersAmount: number,
  queryObject: {
    filters: {
      statusFilters: string[];
    };
    sort: {
      direction: string;
      column: string;
    };
    search: string;
  },
) {
  const params: Record<string, string> = {
    startingRecord: (startingIndex + 1).toString(), // request have user indexing starting from 1
    numberOfRecords: usersAmount.toString(),
    OrderType: queryObject.sort.direction
      ? sortDirectionMap[queryObject.sort.direction]
      : '',
    OrderBy: queryObject.sort.column ? columnNamesMap[queryObject.sort.column] : '',
  };
  const searchParams = new URLSearchParams(params);

  if (queryObject.search) {
    searchParams.append('TextSearch', queryObject.search);
  }
  queryObject.filters.statusFilters.forEach((active) =>
    searchParams.append('Active', statusMap[active]),
  );

  return fetchWrapper({
    url: `/api/user?${searchParams.toString()}`,
    dataType: GetUsersDtoV,
  });
}
