import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getHardwareHistory,
  HardwareHistory,
  parseHistoryData,
} from '../../../services/fetchServices/HardwareApi/getHardwareHistory';

export const useHardwareHistoryData = (id: number) => {
  const [historyData, setHistoryData] = useState<HardwareHistory>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const hardwareHistory = await getHardwareHistory(id);
      if (hardwareHistory.type === 'success') {
        const parsedHistory = parseHistoryData(hardwareHistory.data);
        setHistoryData(parsedHistory);
      } else {
        toast.error(hardwareHistory.error.message);
      }
      setIsLoading(false);
    };
    if (id !== 0) {
      fetchData();
    }
  }, [id]);

  return {
    historyData,
    setHistoryData,
    isLoading,
    setIsLoading,
  };
};
