import React, { useCallback, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import {
  createStyles,
  createTheme,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import useDimensions from '../../hooks/useDimensions';
import BackToTop from './BackToTop';
import {
  AddActionsToColumns,
  fechingIndicator,
  setColumnsStyle,
  TableStateColumn,
} from './LazyListHelpers';
import useElementBottomDetection from './useElementBottomDetection';

const useStyles = makeStyles(() =>
  createStyles({
    counter: {
      display: 'flex',
      margin: '0',
    },
    filteredText: {
      marginBottom: '1%',
    },
    circularProgress: {
      marginLeft: 15,
      position: 'relative',
      top: 4,
    },
  }),
);

const materialTheme = () =>
  createTheme({
    palette: {
      primary: { main: '#ffd242' },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['ModeratJIT', 'sans-serif'].join(','),
    },
    overrides: {
      MuiPaper: {
        elevation2: {
          width: '30%',
          minWidth: '30%',
          maxWidth: '30%',
        },
      },
      ...(window.location.pathname === '/hardware' && {
        MUIDataTableToolbar: {
          actions: {
            '& > span': {
              position: 'relative',
              right: '-50px',
            },
          },
        },
      }),
    },
  });

interface TableState {
  filterList: string[][];
  columns: TableStateColumn[];
  searchText: string;
}

export type TableChangeHandler = (tableState: TableState) => void;

interface Props {
  data: any;
  columns: any;
  options: any;
  onFilterChange?: (filters: any) => void;
  onSortChange?: (selectedColumn: TableStateColumn) => void;
  onSearchChange?: (search: string) => void;
  onColumnsChange?: (columns: { name: string; display: 'true' | 'false' }[]) => void;
  totalItems: number;
  filteredItems: number;
  onInfiniteScrollCallback: () => void;
  isFetching: boolean;
  actionComponents?: (rowData: string[]) => JSX.Element[];
  muiTheme?: () => Theme;
}

const LazyList = ({
  data,
  columns,
  options,
  onFilterChange,
  onSortChange,
  onSearchChange,
  onColumnsChange,
  totalItems,
  filteredItems,
  onInfiniteScrollCallback,
  isFetching,
  actionComponents,
  muiTheme,
}: Props) => {
  const classes = useStyles();
  // "initializing" allows first fetch at the beginning even if total records equals list length
  const [initializing, setInitializing] = useState(true);
  const allItemsAreLoaded = data.length >= filteredItems;
  const callbackWithInitializingSetting = useCallback(() => {
    if (initializing) {
      setInitializing(false);
    }
    onInfiniteScrollCallback();
  }, [onInfiniteScrollCallback, initializing]);
  const tableVisibilityRef = useElementBottomDetection({
    callback: callbackWithInitializingSetting,
    disableFetching: (allItemsAreLoaded && !initializing) || isFetching,
  });
  const [tableRef, { width: tableWidth }] = useDimensions();

  const onTableChange = (action: string, tableState: TableState) => {
    switch (action) {
      case 'filterChange':
      case 'resetFilters':
        if (onFilterChange) {
          onFilterChange({ ...tableState.filterList });
        }
        break;
      case 'search':
        if (onSearchChange) {
          onSearchChange(tableState.searchText);
        }

        break;
      case 'viewColumnsChange':
        if (onColumnsChange) {
          onColumnsChange(
            tableState.columns.map((c) => ({
              name: c.name,
              display: c.display,
            })),
          );
        }
        break;
      default:
        break;
    }
  };

  const dataWithMessage = isFetching || initializing ? [...data, fechingIndicator] : data;

  const columnsWithActions = AddActionsToColumns({ actionComponents, columns });
  const styledColumns = setColumnsStyle(columnsWithActions, tableWidth);

  const onColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
    const sortedColumn: TableStateColumn = {
      name: changedColumn,
      sortDirection: direction,
      display: 'true',
    };
    if (onSortChange) {
      onSortChange(sortedColumn);
    }
  };

  return (
    <>
      <div id="back-to-top-anchor" />
      <Grid ref={tableVisibilityRef} container direction="column" spacing={1}>
        <Grid item ref={tableRef}>
          <ThemeProvider theme={muiTheme || materialTheme}>
            <div className={classes.counter}>
              <Typography color="textSecondary" className={classes.filteredText}>
                Records: {filteredItems} of {totalItems}
              </Typography>
              {isFetching ? (
                <CircularProgress size={24} className={classes.circularProgress} />
              ) : null}
            </div>
            <MUIDataTable
              title=""
              data={dataWithMessage}
              columns={styledColumns}
              options={{
                ...options,
                onTableChange,
                onColumnSortChange,
                searchProps: { autoFocus: false },
                searchAlwaysOpen: true,
              }}
            />
          </ThemeProvider>
        </Grid>
        {(allItemsAreLoaded && !initializing) || isFetching ? null : (
          <Grid item>
            <Typography onClick={onInfiniteScrollCallback} color="textSecondary">
              {data.length} out of {filteredItems} records loaded...
            </Typography>
          </Grid>
        )}
      </Grid>
      <BackToTop />
    </>
  );
};

export default LazyList;
