import { FormikErrors } from 'formik';

export const validateAttributes = <
  T extends {
    attributes: any[];
    attributeName: number | '';
    attributeValue: string;
  },
>(
  values: T,
) => {
  const errors: Partial<Record<keyof T, string>> = {};
  if (values.attributeName === undefined) {
    errors.attributeName = 'Attribute name cannot be empty';
  }
  if (['', undefined, null].includes(values.attributeValue)) {
    errors.attributeValue = 'Attribute value cannot be empty';
  }
  if (
    values.attributes.filter((a) => a.attributeId === values.attributeName).length > 0
  ) {
    errors.attributeName = 'Attributes cannot duplicate';
  }
  return errors as FormikErrors<T>;
};
