import { attributeFormValue } from '../../../components/AttributesInput/AttributesInput';
import {
  Hardware,
  HardwareStatus,
  HardwareWithHardwareChildren,
  HardwareWithHardwareChildrenV,
} from '../../domainServices/HardwareService';
import { fetchWrapper } from '../fetchWrapper';

export type UpdateHardwareFormValues = {
  type: number | '';
  category: number | '';
  attributeName: number | '';
  attributeValue: string;
  attributes: attributeFormValue[];
  supplier: number | '';
  brand: number | '';
  organization: number | '';
  assigneeType: 'user' | 'office' | 'none';
  assignee: string;
  parentHardware: string;
  parentHardwareId: string;
  status: HardwareStatus;
  model: string;
  serialNumber: string;
  jitTag: string;
  warrantyPeriod: Date | null;
  inventoryDate: Date | null;
  purchasePrice?: string;
  vatRate?: string;
  usableDepreciation?: string;
  userId?: number;
  officeId?: number;
  userLdap?: string | '';
  salesDate: Date | null;
  purchaseDate: Date | null;
  expansibility: boolean;
  comment: string;
  parentDialogOpen: boolean;
  hardwareChildren: Hardware[];
  atHome: boolean;
  hardening: boolean;
  version: string;
  hasInstalledLinux: boolean;
};

export const emptyUpdateHardwareFormValues: UpdateHardwareFormValues = {
  type: '',
  category: '',
  supplier: '',
  brand: '',
  organization: '',
  attributeName: '',
  attributeValue: '',
  attributes: [],
  assigneeType: 'user',
  assignee: '',
  parentHardware: '',
  parentHardwareId: '',
  status: 'Free',
  model: '',
  serialNumber: '',
  jitTag: '',
  warrantyPeriod: null,
  inventoryDate: null,
  salesDate: null,
  userLdap: '',
  purchaseDate: null,
  expansibility: true,
  comment: '',
  parentDialogOpen: false,
  hardwareChildren: [],
  atHome: false,
  hardening: false,
  version: '',
  hasInstalledLinux: false,
};

export const mapHardwareToFormValues: (
  hardware: HardwareWithHardwareChildren,
) => UpdateHardwareFormValues = (hardware) => {
  let assigneeType: 'user' | 'office' | 'none';
  let assignee: string;

  if (hardware.user) {
    assigneeType = 'user';
    assignee = hardware.user.ldapLogin;
  } else if (hardware.office) {
    assigneeType = 'office';
    assignee = hardware.office.name;
  } else {
    assigneeType = 'none';
    assignee = '';
  }

  return {
    type: hardware.type.id,
    category: hardware.category.id,
    attributeName: '',
    attributeValue: '',
    attributes: hardware.attributes,
    supplier: hardware.supplier.id,
    brand: hardware.brand.id,
    organization: hardware.organization.id,
    assigneeType,
    assignee,
    parentHardware: '',
    parentHardwareId:
      hardware.parentHardwareId !== 0 ? hardware.parentHardwareId.toString() : '',
    status: hardware.status,
    model: hardware.model,
    serialNumber: hardware.serialNumber,
    jitTag: hardware.jitTag,
    warrantyPeriod: hardware.warrantyPeriod,
    inventoryDate: hardware.inventoryDate,
    purchasePrice: hardware.purchasePrice ? hardware.purchasePrice.toString() : '',
    vatRate: hardware.vatRate ? hardware.vatRate.toString() : '',
    usableDepreciation: hardware.usableDepreciation
      ? hardware.usableDepreciation.toString()
      : '',
    userId: hardware.user ? hardware.user.id : undefined,
    officeId: hardware.office ? hardware.office.id : undefined,
    userLdap: hardware.user ? hardware.user.ldapLogin : undefined,
    salesDate: hardware.salesDate,
    purchaseDate: hardware.purchaseDate,
    expansibility: hardware.expansibility,
    comment: hardware.comment,
    parentDialogOpen: false,
    hardwareChildren: hardware.hardwareChildren,
    atHome: hardware.atHome,
    hardening: hardware.hardening,
    version: hardware.version,
    hasInstalledLinux: hardware.hasInstalledLinux,
  };
};

export interface UpdateHardwareDto {
  typeId: number;
  categoryId: number;
  attributes: attributeFormValue[];
  supplierId: number;
  brandId: number;
  organizationId: number;
  userId?: number;
  officeId?: number;
  parentHardwareId?: number;
  status?: HardwareStatus;
  model: string;
  serialNumber: string;
  jitTag?: string;
  warrantyPeriod?: string;
  inventoryDate?: string;
  purchasePrice?: number;
  vatRate?: number;
  usableDepreciation?: string;
  salesDate?: string;
  purchaseDate?: string;
  expansibility?: boolean;
  comment?: string;
  hardwareChildren?: Hardware[];
  atHome: boolean;
}

function getParentHardwareId(values: UpdateHardwareFormValues) {
  if (values.parentHardware !== '') return Number(values.parentHardwareId);
}

export const mapFormValuesToDto: (
  values: UpdateHardwareFormValues,
) => UpdateHardwareDto = (values) => ({
  typeId: values.type || 0,
  categoryId: values.category || 0,
  attributes: values.attributes,
  supplierId: values.supplier || 0,
  brandId: values.brand || 0,
  organizationId: values.organization || 0,
  parentHardwareId: getParentHardwareId(values) || undefined,
  status: values.status,
  model: values.model,
  serialNumber: values.serialNumber,
  jitTag: values.jitTag,
  warrantyPeriod: values.warrantyPeriod ? values.warrantyPeriod.toISOString() : undefined,
  inventoryDate: values.inventoryDate ? values.inventoryDate.toISOString() : undefined,
  purchasePrice: values.purchasePrice ? Number(values.purchasePrice) : undefined,
  vatRate: values.vatRate ? Number(values.vatRate) : undefined,
  usableDepreciation: values.usableDepreciation ? values.usableDepreciation : undefined,
  userId: values.userId,
  officeId: values.officeId,
  userLdap: values.userLdap,
  salesDate: values.salesDate ? values.salesDate.toISOString() : undefined,
  purchaseDate: values.purchaseDate ? values.purchaseDate.toISOString() : undefined,
  expansibility: values.expansibility,
  comment: values.comment,
  hardwareChildren: values.hardwareChildren,
  atHome: values.atHome,
  hardening: values.hardening,
  version: values.version || undefined,
  hasInstalledLinux: values.hasInstalledLinux,
});

export function updateHardware(id: string, hardware: any) {
  const hardwareDto = mapFormValuesToDto(hardware);
  const url = `/api/hardware/${id}`;
  return fetchWrapper({
    url,
    dataType: HardwareWithHardwareChildrenV,
    fetchArg: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: hardwareDto,
    },
  });
}
