import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types/lib/fromNullable';
import { fetchWrapper } from '../../../services/fetchServices/fetchWrapper';

const nullableString = fromNullable(t.string, '');

export const AddCategoryV = t.type({
  name: nullableString,
});

export type AddCategoryFormValue = t.TypeOf<typeof AddCategoryV>;

export function addCategory(category: AddCategoryFormValue) {
  return fetchWrapper({
    url: '/api/hardware/category',
    fetchArg: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: category,
    },
  });
}
